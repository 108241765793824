const initialState = {
  locations: [],
}

const location = (state = initialState, action) => {
  switch (action.type) {
    case 'LOCATION':
      return {
        ...state,
        locations: action.payload,
      };
    default:
      return state;
  }
};

export default location
