import { axiosInstance } from "./config";

export const fetchData = async (toggleLoading, apiName, token) => {
    try {
        toggleLoading(true);
        const res = await axiosInstance.get(`/${apiName}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        toggleLoading(false);
        return res.data
    } catch (error) {
        console.error(error);
        toggleLoading(false);
    }
}

export const FetchPostData = async (toggleLoading, apiName, token, formDataEntries) => {
    try {
        toggleLoading(true);
        const formData = new FormData();
        for (const [key, value] of Object.entries(formDataEntries)) {
            formData.append(key, value);
        }
        const res = await axiosInstance.post(`${apiName}`, formDataEntries, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        toggleLoading(false);
        return res
    } catch (error) {
        console.error(error);
        toggleLoading(false);
    }
}