import React, { useMemo } from 'react';
import { useTable } from 'react-table';

export const CommonTableContainer = ({ columns, data, status, noDataComponent, ...rest }) => {
    const tableColumns = useMemo(() => columns, [columns]);
    const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } = useTable({ columns: tableColumns, data });

    if (!rows.length) {
        if (noDataComponent) return noDataComponent;
        return <>No data</>;
    }

    return (
        <table className="table table-bordered" {...getTableProps()} {...rest}>
            <thead>
                {headerGroups.map((headerGroup) => {
                    return (
                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()} >
                            {headerGroup.headers.map((column, index) => (
                                <th
                                    key={index}
                                    className={`t-col-${index + 1} ${column.className}`}
                                    {...column.getHeaderProps()}
                                >
                                    {column.render("Header")}
                                    {column.isInfoIcon ? (
                                        <a
                                            onClick={() => {
                                                setNameModal(true)
                                                setTitle(column.Header)
                                            }}
                                        >
                                            <i
                                                title="info"
                                                className="bx bx-info-circle ms-1 info-icon"
                                            ></i>
                                        </a>
                                    ) : null}
                                </th>
                            ))}
                        </tr>
                    )
                }
                )}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr key={row.id} {...row.getRowProps()}
                            className={`${status == true && (row?.original?.status == "complete" || row?.original?.status == 1) ? "status-complete" : ""}`}
                        >
                            {row.cells.map((cell) => {
                                const { className, style } = cell.column;
                                return (
                                    <td key={cell.id} {...cell.getCellProps({ className, style })}>
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
