import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  priorityOption,
  RelatedToOption,
  TimeOption,
} from "utils/common-options";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { FetchPostData } from "utils/common-fetchData";
import secureLocalStorage from "react-secure-storage";

const EditTasks = ({
  generalData,
  tasks,
  alldependency,
  onCloseEditModal,
  toggleLoading,
  updateData,
  modalType,
}) => {
  const [verticalActiveTab, setverticalActiveTab] = useState(
    RelatedToOption?.find((obj) => obj.value == generalData?.related)?.id
  );
  const getLocations = useSelector((state) => state?.location?.locations);
  const [relatedvalue, setRelatedValue] = useState({});
  return (
    <div className="bg-white">
      <div className="modal-header mb-0">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ width: "100%" }}
        >
          <h5 className="col-6 modal-title mt-0">
            {modalType == "view" ? "View Activity" : "Edit Activity"}
          </h5>
        </div>
      </div>

      <div className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0">
        <Row className="gx-4 justify-content-between">
          <div className="side-tab h-100 py-3 bd-fixed-sidetab custom-Edit-Side">
            <Nav pills className="flex-column">
              {RelatedToOption?.map((obj, i) => {
                return (
                  <NavItem key={i}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({
                        "mb-2": true,
                        active: verticalActiveTab === obj?.id,
                      })}
                      onClick={() => {
                        setverticalActiveTab(obj?.id);
                        setRelatedValue(obj);
                      }}
                      disabled={modalType == "view"}
                    >
                      {obj.label}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </div>
          <Col className="bd-right-side-tab custom-right-side">
            <div className="px-0 h-100 pl-0">
              <TabContent
                activeTab={verticalActiveTab}
                className="text-muted mt-4 mt-md-0"
              >
                {RelatedToOption?.map((obj, i) => {
                  return (
                    <TabPane tabId={obj.id} key={i}>
                      <GeneralEditTaskTab
                        getLocations={getLocations}
                        generalData={generalData}
                        tasks={tasks}
                        alldependency={alldependency}
                        verticalActiveTab={verticalActiveTab}
                        onCloseEditModal={onCloseEditModal}
                        toggleLoading={toggleLoading}
                        updateData={updateData}
                        modalType={modalType}
                        relatedvalue={relatedvalue}
                      />
                    </TabPane>
                  );
                })}
              </TabContent>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const GeneralEditTaskTab = ({
  getLocations,
  generalData,
  tasks,
  alldependency,
  verticalActiveTab,
  onCloseEditModal,
  toggleLoading,
  updateData,
  modalType,
  relatedvalue,
}) => {
  const [formRows, setFormRows] = useState([{}]);
  const [title, settitle] = useState(null);
  const [taskOption, setTaskOption] = useState([]);
  const [location, setLocation] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [assigned, setAssigned] = useState([]);
  const [est_time, setEstimatedTime] = useState([]);
  const [priority, setPriority] = useState([]);
  const [description, setDescription] = useState("");
  const [related_to, setRelatedTo] = useState([]);
  const [relatedId, setRelatedId] = useState([]);
  const [relatedOption, setRelatedOption] = useState([]);
  const [relatedSelected, setRelatedSelected] = useState([]);
  const [relatedIdOption, setRelatedIdOption] = useState([]);

  const onDeleteFormRow = (index) => {
    const newInputs = [...formRows];
    newInputs.splice(index, 1);
    setFormRows(newInputs);
  };

  useEffect(() => {
    setLocation(
      getLocations?.find((loc) => loc.value == generalData?.location)
    );
    setStartDate(generalData?.startdate);
    setEndDate(generalData?.enddate);
    setDescription(generalData?.description || "");
    setPriority(
      priorityOption?.find((pro) => pro.value == generalData?.priorty)
    );
    const task = tasks?.find((obj) => obj.value == generalData?.title);
    settitle(generalData?.title);
    setTaskOption(tasks?.filter((obj) => obj?.related == generalData?.related));
    const ids = generalData?.assigned?.split(",").map(Number);
    const assignee = alldependency?.users?.filter((item) =>
      ids?.includes(item.value)
    );
    setAssigned(assignee);
    const relatedOption = RelatedToOption?.find(
      (rel) => rel.value == generalData?.related
    );
    setRelatedSelected(relatedOption);
    setRelatedOption(relatedOption?.related);
    const realtedTo = relatedOption?.related?.find(
      (obj) => obj.value == generalData?.related_to
    );
    setRelatedTo(realtedTo);
    setEstimatedTime(
      TimeOption?.find((obj) => obj.value == generalData?.estimate_time)
    );
    const relatedOptions =
      realtedTo?.value == "lead"
        ? alldependency?.leads
        : realtedTo?.value == "proposal"
        ? alldependency?.proposal
        : realtedTo?.value == "digital-project"
        ? alldependency?.digital_projects
        : realtedTo?.value == "development-project"
        ? alldependency?.projects
        : realtedTo?.value == "introducer"
        ? alldependency.introducer
        : realtedTo?.value == "staff"
        ? alldependency.staff
        : [];
    setRelatedIdOption(relatedOptions);
    setRelatedId(
      relatedOptions?.find((obj) => obj.value == generalData?.related_id)
    );
    if (generalData?.subtasks?.length == 0) {
      if (task != undefined) {
        const sub = task?.subtasks?.split("#@");
        setFormRows(
          sub?.map((obj) => ({
            task_name: obj,
            task_assign: "",
            task_status: 0,
          }))
        );
      } else {
        setFormRows([]);
      }
    } else {
      setFormRows(
        generalData?.subtasks?.map((obj) => ({
          task_name: obj?.title,
          task_id: obj?.id,
          task_assign: assignee?.find((user) => user.value == obj?.assign),
          task_status: obj?.status,
        }))
      );
    }
  }, [generalData]);

  useEffect(() => {
    if (Object.keys(relatedvalue).length != 0) {
      const related = RelatedToOption?.find(
        (obj) => obj.id == verticalActiveTab
      );
      setRelatedOption(related?.related);
      setRelatedSelected(related);
      setRelatedId([]);
      setRelatedIdOption([]);
      setRelatedTo([]);
      setTaskOption(
        tasks?.filter((obj) => obj?.related == relatedvalue?.value)
      );
      settitle([]);
      setFormRows([]);
    }
  }, [relatedvalue]);

  const onAddFormRow = () => {
    setFormRows([...formRows, {}]);
  };

  const handleInputChange = (key, event) => {
    const { name, value } = event?.target;
    const updatedFormRows = formRows.map((row, index) =>
      index === key ? { ...row, [name]: value } : row
    );
    setFormRows(updatedFormRows);
  };

  const handleSelectChange = (key, selectedOption) => {
    const updatedFormRows = formRows.map((row, index) =>
      index === key ? { ...row, task_assign: selectedOption } : row
    );
    setFormRows(updatedFormRows);
  };

  const handleCheckboxChange = (key) => {
    const updatedRows = formRows.map((person, index) => {
      if (index === key) {
        return {
          ...person,
          task_status: person.task_status === 1 ? 0 : 1, // Toggle between 0 and 1
        };
      }
      return person;
    });
    setFormRows(updatedRows);
  };

  const onHandleEdit = async (e) => {
    e.preventDefault();
    const subtask_id = formRows?.some((item) => item.hasOwnProperty("task_id"));
    const formDatas = {
      general_id: generalData?.id,
      user_id: localStorage.getItem("auth_id"),
      location: location?.value,
      title: title,
      startdate: startDate,
      enddate: endDate,
      assigned: assigned?.map((obj) => obj.value)?.join(","),
      priority: priority?.value,
      related: relatedSelected?.value,
      related_to: related_to?.value,
      related_id: relatedId?.value,
      est_time: est_time?.value,
      description: description,
      subtask: formRows?.map((obj) => obj?.task_name)?.join("#@"),
      subtask_assign: formRows
        ?.map((obj) => obj?.task_assign?.value)
        ?.join(","),
      subtask_status: formRows?.map((obj) => obj?.task_status)?.join(","),
      edit_status: subtask_id ? 1 : 0,
      subtask_id: subtask_id
        ? formRows?.map((obj) => obj?.task_id)?.join(",")
        : "",
    };
    const response = await FetchPostData(
      toggleLoading,
      "editGeneralTasks",
      secureLocalStorage.getItem("auth_token"),
      formDatas
    );
    if (response?.data?.success) {
      Swal.fire({
        icon: "success",
        text: "Task edited successfully.",
      }).then(() => {
        onCloseEditModal();
        updateData(response?.data?.data);
      });
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="text-end add-task-button d-flex gap-2 align-items-center">
        <h5 className="text-purple mb-0">
          Created by :{" "}
          <span className="text-primary">
            <strong>{generalData?.added_by_name}</strong>
          </span>
        </h5>
        {modalType != "view" && (
          <button
            type="submit"
            className=" btn ms-md-2 btn-save btn-label"
            onClick={onHandleEdit}
          >
            <i className="fas fa-save me-1 label-icon"></i> Save
          </button>
        )}
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            onCloseEditModal();
          }}
          className=" btn ms-md-2 btn-red btn-label"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          CANCEL
        </button>
      </div>
      <Row>
        <div className="col-md-12 d-flex flex-column new-dashboard-task-modal">
          <div className="col-12 form-container mt-2">
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <Form className="row d-flex justify-content-center align-items-center">
                <div className="col-md-12 d-flex flex-column new-task-modal ">
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-sm-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Location <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Location"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={getLocations}
                        value={location}
                        onChange={(e) => setLocation(e)}
                        isDisabled={modalType == "view"}
                      />
                    </div>
                    <div className="col-md-9 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Task Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Enter Task Name"
                        value={title}
                        onChange={(e) => settitle(e.target.value)}
                        disabled={modalType == "view"}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-md-2 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Start Date
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        placeholder="Select Date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        disabled={modalType == "view"}
                      />
                    </div>
                    <div className="col-md-2 ps-2">
                      <Label htmlFor="formrow-firstname-Input">End Date</Label>
                      <Input
                        type="date"
                        className="form-control"
                        placeholder="Select Date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        disabled={modalType == "view"}
                      />
                    </div>
                    <div className="col-md-2 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Estimated Time <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Time"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={TimeOption}
                        value={est_time}
                        onChange={(e) => setEstimatedTime(e)}
                        isDisabled={modalType == "view"}
                      />
                    </div>

                    <div className="col-md-6 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Assignee <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Member"
                        className="basic-multi-select "
                        classNamePrefix="select"
                        isMulti
                        options={alldependency?.users}
                        value={assigned}
                        onChange={(e) => setAssigned(e)}
                        closeMenuOnSelect={false}
                        isDisabled={modalType == "view"}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Priority"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        value={priority}
                        options={priorityOption}
                        onChange={(e) => setPriority(e)}
                        isDisabled={modalType == "view"}
                      />
                    </div>

                    {relatedOption?.length != 0 && (
                      <div className="col-md-2 ps-2">
                        <label htmlFor="sales-dropdown">
                          {relatedSelected?.label}
                        </label>
                        <Select
                          placeholder={`Select ${relatedSelected?.label}`}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={relatedOption}
                          value={related_to}
                          onChange={(selectedOption) => {
                            setRelatedTo(selectedOption);
                            const relatedOptions =
                              selectedOption?.value == "lead"
                                ? alldependency?.leads
                                : selectedOption?.value == "proposal"
                                ? alldependency?.proposal
                                : selectedOption?.value == "digital-project"
                                ? alldependency?.digital_projects
                                : selectedOption?.value == "development-project"
                                ? alldependency?.projects
                                : selectedOption?.value == "introducer"
                                ? alldependency.introducer
                                : selectedOption?.value == "staff"
                                ? alldependency.staff
                                : [];
                            setRelatedIdOption(relatedOptions);
                          }}
                          isDisabled={modalType == "view"}
                        />
                      </div>
                    )}

                    {relatedIdOption?.length != 0 && (
                      <div className="col-md-2 ps-2">
                        <label htmlFor="lead-dropdown">
                          {related_to?.label}
                        </label>
                        <Select
                          placeholder={`Select ${related_to?.label} `}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={relatedIdOption}
                          value={relatedId}
                          onChange={(e) => {
                            setRelatedId(e);
                          }}
                          isDisabled={modalType == "view"}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 d-flex mb-0 justify-content-start align-items-end flex-column gap-4">
                    <div className="col-12 ps-2">
                      <label htmlFor="">Description</label>
                      <div className="no-drag">
                        <CKEditor
                          editor={ClassicEditor}
                          disabled={modalType == "view"}
                          onReady={(editor) => {}}
                          data={description}
                          onChange={(event, editor) => {
                            if (modalType != "view") {
                              const data = editor.getData();
                              setDescription(data);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Row>
          </div>
          <div className="col-12 pe-2 form-container mt-2 ms-2">
            <Row className="bg-white px-2 py-2 mt-0 shadow-md mb-3 me-5">
              <div className="d-flex justify-content-end align-items-end px-0 ms-3">
                {modalType != "view" ? (
                  <div className="text-end mb-0">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        onAddFormRow();
                      }}
                      className="btn btn-purple  "
                    >
                      <i className="fas fa-plus me-2"></i>
                      Add Subtask
                    </button>
                  </div>
                ) : null}
              </div>
              {formRows?.map((person, key) => {
                return (
                  <div key={key} className="">
                    <div className="d-flex">
                      <div className="contact-form d-flex col-md-11">
                        <div className="custim-width contact-btns ml-10 d-flex justify-content-center align-items-center mb-md-0 mt-3">
                          <div className="text-end" id="customCheckbox">
                            <input
                              type="checkbox"
                              className="form-check-input custom-checkbox mt-2"
                              id="customCheck-outlinecolor2"
                              checked={person?.task_status == 1}
                              onClick={(e) => {
                                handleCheckboxChange(key);
                              }}
                            />
                          </div>
                        </div>
                        <div className="contact-column edit-contact-column mb-md-0 col-8">
                          <label htmlFor="">Subtask Name</label>
                          <input
                            type="text"
                            name="task_name"
                            placeholder="Enter Subtask Name"
                            // className={`form-control ${modalType == "view" ? customInputBox : ""}`}
                            className="form-control"
                            value={person?.task_name}
                            onChange={(e) => handleInputChange(key, e)}
                            disabled={modalType == "view"}
                          />
                        </div>
                        <div className="contact-column edit-contact-column mb-md-0 col-4">
                          <label htmlFor="">Assignee</label>
                          <Select
                            placeholder="Select Member"
                            className="basic-multi-select "
                            classNamePrefix="select"
                            name="task_assign"
                            options={assigned}
                            value={person?.task_assign}
                            onChange={(selectedOption) =>
                              handleSelectChange(key, selectedOption)
                            }
                            isDisabled={modalType == "view"}
                          />
                        </div>
                      </div>
                      <div className="col-md-1 contact-btns ml-10 d-flex justify-content-end align-items-end mb-md-0 ms-4">
                        <div className="text-end">
                          {modalType != "view" && (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                onDeleteFormRow(key);
                              }}
                              className="btn btn-red"
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    {key !== formRows.length - 1 ? (
                      <div className="border-bottom my-3"></div>
                    ) : null}
                  </div>
                );
              })}
            </Row>
          </div>
        </div>
      </Row>

      {/* Subtask Timer  */}
      {/* <div className={`note-sticky-form ${openSubTaskTimer ? "d-block" : "d-none"}`}>
          <Draggabl>
            <ResizableBox   
              width="700"
              style={{
                position: "fixed",
                top: "16%",
                left: "18%",
                zIndex: "1055",
                backgroundColor: "#fff",
              }}
            >
              <div className="modal-header pe-4">
                <h5 className="modal-title mt-0">Time Taken</h5>
              </div>

              <div className="modal-body pe-4">
                <Form
                  className="row d-flex justify-content-center align-items-center"
                >
                  <div className="col-md-6 d-flex justify-content-end align-items-center ps-3">
                    <div className="text-center">
                      <img src={AddTime} alt="Addepe" style={{ width: "100%" }} />
                    </div>
                  </div>

                  <div className="col-md-6 d-flex flex-column justify-space-between">
                    <div className="col-10 d-flex flex-column gap-3 flex-wrap">
                      <h5>Total Time Taken For ThisTask</h5>
                      <div className="col-8">
                        <label htmlFor="">
                          Select Time <span className="text-danger">*</span>
                        </label>

                        <ReactSelect
                          placeholder="Select Time"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={time_options}
                          
                        />

                      </div>
                    </div> 
                  </div>

                  <div className="text-end">
                      <button className=" btn btn-save btn-label">
                        <i className="fas fa-save me-2 label-icon"></i>
                        Save
                      </button>
                      <a
                        onClick={()=> setOpenSubTaskTimer(false)}
                        className=" btn ms-md-2 btn-red btn-label"
                      >
                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                        CANCEL
                      </a>
                    </div>
                </Form>
              </div>
            </ResizableBox>
          </Draggabl>
        </div> */}
    </div>
  );
};

export default EditTasks;
