import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Row, Form, Modal, Card, Col } from "reactstrap"
import Select from "react-select"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

// assets
import "../../../../assets/css/Sales/Sales.css"
import userImg from "../../../../assets/images/users/avatar-1.jpg"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import configure from "configure"

const Notes = ({ verticalActiveTab }) => {
  const [fixButtons, setFixButtons] = useState(false)
  const [followUp, setFollowUp] = useState(false)
  const [notesSidebar, setNotesSidebar] = useState(false)
  const [viewFollowUp, setViewFollowUp] = useState(false)
  const [editNotesSidebar, setEditNotesSidebar] = useState(false)
  const [selectedFiles1, setselectedFiles1] = useState([])
  const [selectedFiles2, setselectedFiles2] = useState([])
  const [addFollowUp, setAddFollowUp] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function handleAcceptedFiles2(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles2(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const params = useParams()
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [assign_option, setAssignOption] = useState([])
  const [description, setDescription] = useState("")
  const [date, setDate] = useState("")

  useEffect(() => {
    if (verticalActiveTab == "2") {
      fetchNotes()
    }
  }, [verticalActiveTab])

  const fetchNotes = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("introducer_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/introducer_notes`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setData(response.data.data)
        setAssignOption(
          response.data.assinged.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const addNotes = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("introducer_id", params.id)
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("date", date)
    formData.append("description", description)
    formData.append("attachment", selectedFiles1[0])

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/add_introducer_note`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Introducer Added Successfully",
        }).then(data => {
          setData(response.data.data)
          setNotesSidebar(false)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const deleteNote = async (e, note_id) => {
    swal({
      title: "Are you sure?",
      text: "You want to Delete this note !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async willdelete => {
      if (willdelete) {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        formData.append("user_id", localStorage.getItem("auth_id"))
        formData.append("introducer_id", params.id)
        formData.append("note_id", note_id)
        try {
          await axios({
            method: "post",
            url: `${configure.apiUrl}/delete_introducer_note`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(res => {
            swal({
              icon: "success",
              text: "Note Deleted Successfully",
            }).then(data => {
              setLoading(false)
              setData(res.data.data)
            })
          })
        } catch (error) {
          console.log(error)
          setLoading(false)
        }
      }
    })
  }

  const [edit_description, setEditDescription] = useState("")
  const [edit_date, setEditDate] = useState("")
  const [note_id, setNoteId] = useState("")

  const EditNotes = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("introducer_id", params.id)
    formData.append("note_id", note_id)
    formData.append("date", edit_date)
    formData.append("description", edit_description)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/edit_introducer_note`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Note Edited Successfully",
        }).then(data => {
          setData(response.data.data)
          setEditNotesSidebar(false)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const [follow_date, setFollowUpDate] = useState("")
  const [purpose, setPurpose] = useState("")
  const [assign, setAssign] = useState("")
  const [note_id_follow, setNoteIdFollow] = useState("")

  const onAddFollowUp = async e => {
    e.preventDefault()
    const result = assign.map(obj => obj.value).join(",")
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("note_id", note_id_follow)
    formData.append("introducer_id", params.id)
    formData.append("description", purpose)
    formData.append("assign_user", result)
    formData.append("date", follow_date)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/add_introducer_followup`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Follow Up Added Successfully",
        }).then(data => {
          setAddFollowUp(false)
          setData(res.data.data)
        })
      })
    } catch (error) {
      setLoading(false)
    }
  }

  const [edit_follow_date, setEditFollowUpDate] = useState("")
  const [edit_purpose, setEditPurpose] = useState("")
  const [edit_assign, setEditAssign] = useState([])
  const [follow_id, setFollowId] = useState("")

  const onEditFollowUp = async e => {
    e.preventDefault()
    const result = edit_assign.map(obj => obj.value).join(",")
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("followup_id", follow_id)
    formData.append("introducer_id", params.id)
    formData.append("description", edit_purpose)
    formData.append("assign_user", result)
    formData.append("date", edit_follow_date)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/edit_introducer_followup`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Follow Up Edited Successfully",
        }).then(data => {
          setViewFollowUp(false)
          setData(res.data.data)
        })
      })
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="mb-3">
        <div className="px-3 py-4 bg-white">
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <a
              onClick={() => setNotesSidebar(true)}
              className="btn btn-purple fix-btn btn-label pe-0"
            >
              <i className="fas fa-plus me-2 label-icon"></i>
              <span>Note</span>
            </a>
            <a
              onClick={() => navigate(-1)}
              className="btn btn-red  fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </a>
          </div>
          <div className="d-flex justify-content-between mb-4">
            <h5 className="text-dark">Notes</h5>
            <div className="text-end">
              <a
                onClick={() => setNotesSidebar(true)}
                className=" btn btn-save btn-label"
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
                Note
              </a>
              <a
                onClick={() => navigate(-1)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>
          </div>
          {/* Add Notes */}
          <Form onSubmit={addNotes}>
            <div
              className={`notes-sidebar ${
                notesSidebar ? "show-notes-sidebar" : ""
              }`}
            >
              <Row className="align-items-center ">
                <div className="col-12 d-flex justify-content-between">
                  <h5>Add New Note</h5>
                  <div className="text-end">
                    <button className="btn btn-purple  me-2">
                      <i className="fas fa-plus me-2"></i>Add Note
                    </button>
                    <a
                      onClick={() => setNotesSidebar(false)}
                      className="btn btn-red text-uppercase "
                    >
                      <i className="dripicons-cross me-1 icon-center"></i>Close
                    </a>
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data=""
                    onReady={editor => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setDescription(data)
                    }}
                  />
                </div>
                <div className="col-md-4 mt-3 notes">
                  <label htmlFor="">Got in touch with this lead</label>
                  <input
                    type="date"
                    className="form-control "
                    onChange={e => setDate(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mt-3">
                  <label htmlFor="">Attachment</label>
                  {/* custom file upload */}
                  <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles1(acceptedFiles)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="dz-message needsclick p-0"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="d-flex px-2 custom-file-upload align-items-center">
                          <div className="">
                            <i className="display-6 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h5 className="mb-0 ms-2">Upload Files</h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                {/* file display here */}
                <div
                  className={`col-md-3 mt-3 dropzone-previews ${
                    selectedFiles1.length > 0 ? "d-block" : "d-none"
                  }`}
                  id="file-previews"
                >
                  <label className="mb-1">Uploaded Files</label>
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-1">
                      <Row className="align-items-center justify-content-evenly">
                        {selectedFiles1.map((f, i) => {
                          return (
                            <Col key={i} className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                          )
                        })}
                      </Row>
                    </div>
                  </Card>
                </div>
              </Row>
            </div>
          </Form>

          <div>
            <ul className="verti-timeline list-unstyled notes-box">
              {/* Activities */}
              {data.map((note, key) => {
                return (
                  <li key={key} className="event-list position-relative">
                    <div className="event-timeline-dot">
                      <img
                        src={`https://mars.zithas.com/application/public/${note.avatar}`}
                        className="userImg me-3 "
                        alt=""
                      />
                    </div>
                    <div className="d-flex">
                      <div className="note flex-grow-1 ms-4 py-2 rounded ps-4">
                        <div className=" d-flex justify-content-between ">
                          <div>
                            <p className="text-muted mb-4">
                              <h6 className="d-inline me-2">
                                By {note.added_by}
                              </h6>{" "}
                              {moment(note.created_at).format(
                                "DD-MM-YYYY hh:mm:ss"
                              )}
                              <span className="pl-15">
                                Got in touch with this lead at{" "}
                                {moment(note.date).format("DD-MM-YYYY")}
                              </span>
                            </p>
                            <i className="bi bi-triangle-fill note-icon"></i>
                          </div>
                          <div className="me-4">
                            {note.introducer_followup?.length == 0 ? (
                              <span
                                onClick={() => {
                                  setAddFollowUp(true)
                                  setNoteIdFollow(note.id)
                                }}
                                className="me-2 cursor-pointer badge-soft-success badge"
                              >
                                Add FollowUp
                              </span>
                            ) : (
                              <span
                                onClick={() => {
                                  setEditFollowUpDate(
                                    note.introducer_followup[0]?.date_time
                                  )
                                  setEditPurpose(
                                    note.introducer_followup[0]?.description
                                  )
                                  setFollowId(note.introducer_followup[0]?.id)
                                  setViewFollowUp(true)
                                  const idArray =
                                    note?.introducer_followup[0]?.assign_user
                                      .split(",")
                                      .map(Number)
                                  setEditAssign(
                                    assign_option.filter(obj =>
                                      idArray.includes(obj.value)
                                    )
                                  )
                                }}
                                className="me-2 cursor-pointer badge-soft-success badge"
                              >
                                FollowUp
                              </span>
                            )}

                            <button
                              onClick={e => {
                                e.preventDefault()
                                setEditDescription(note.description)
                                setEditDate(note.date)
                                setNoteId(note.id)
                                setEditNotesSidebar(true)
                              }}
                              className="btn btn-sm edit-delete-btn text-success me-2"
                            >
                              <i className="fas fa-edit" title="Edit"></i>
                            </button>
                            <button
                              className="btn btn-sm edit-delete-btn text-danger"
                              onClick={e => deleteNote(e, note.id)}
                            >
                              <i
                                className="fas fa-trash-alt"
                                title="Delete"
                              ></i>
                            </button>
                          </div>
                        </div>
                        <div
                          className="Features"
                          dangerouslySetInnerHTML={{
                            __html: note.description,
                          }}
                        />
                        <div>
                          {note.link != null ? (
                            <div>
                              {" "}
                              Attachment :{" "}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                className="activity-log-btn text-info"
                                href={note.link}
                              >
                                <i className="fas fa-eye pr-10"></i>View
                              </a>{" "}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>

            {/* Edit Notes Sidebar */}
            <div
              className={`notes-sidebar ${
                editNotesSidebar ? "show-notes-sidebar" : ""
              }`}
            >
              <Form onSubmit={EditNotes}>
                <Row className="align-items-center justify-content-between">
                  <div className="col-12 d-flex justify-content-between">
                    <h5>Edit Note</h5>
                    <div className="text-end">
                      <button className="btn btn-purple  me-2">
                        <i className="fas fa-edit me-2"></i>Edit Note
                      </button>
                      <a
                        onClick={() => setEditNotesSidebar(false)}
                        className="btn btn-red text-uppercase "
                      >
                        <i className="dripicons-cross me-1 icon-center"></i>
                        Close
                      </a>
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="">Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={edit_description || ""}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setEditDescription(data)
                      }}
                    />
                  </div>
                  <div className="col-md-4 mt-3 notes">
                    <label htmlFor="">Got in touch with this lead</label>
                    <input
                      type="date"
                      className="form-control "
                      value={edit_date}
                      onChange={e => setEditDate(e.target.value)}
                    />
                  </div>
                  {/* <div className="col-md-3 mt-3">
                    <label htmlFor="">Attachment</label>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles2(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          className="dz-message needsclick p-0"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="d-flex px-2 custom-file-upload align-items-center">
                            <div className="">
                              <i className="display-6 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h5 className="mb-0 ms-2">Upload Files</h5>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div> */}
                  {/* file display here */}
                  {/* <div
                    className={`col-md-3 mt-3 dropzone-previews ${
                      selectedFiles2.length > 0 ? "d-block" : "d-none"
                    }`}
                    id="file-previews"
                  >
                    <label className="mb-1">Uploaded Files</label>
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-1">
                        <Row className="align-items-center justify-content-evenly">
                          {selectedFiles2.map((f, i) => {
                            return (
                              <Col key={i} className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                            )
                          })}
                        </Row>
                      </div>
                    </Card>
                  </div> */}
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* Follow Up Modal */}
      <Modal isOpen={addFollowUp} size="sm" className="bd-custom-md-3" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Follow Up</h5>
          <button
            type="button"
            onClick={() => {
              setAddFollowUp(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row" onSubmit={onAddFollowUp}>
            <div className="mb-3 col-12">
              <label htmlFor="formrow-firstname-Input">Date</label>
              <input
                type="date"
                className="form-control "
                onChange={e => setFollowUpDate(e.target.value)}
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Purpose</label>
              <input
                type="Purpose"
                className="form-control "
                placeholder="Enter Purpose"
                onChange={e => setPurpose(e.target.value)}
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Assigned</label>
              <Select
                placeholder="Select Assigned"
                className="basic-multi-select"
                classNamePrefix=" select"
                styles={colourStyles}
                options={assign_option}
                isMulti
                onChange={e => setAssign(e)}
              />
            </div>
            <div className="mt-2 text-end">
              <button className=" btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}

      {/* View Follow Up Modal */}
      <Modal
        isOpen={viewFollowUp}
        size="sm"
        className="bd-custom-md-3"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">View Follow Up</h5>
          <button
            type="button"
            onClick={() => {
              setViewFollowUp(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row" onSubmit={onEditFollowUp}>
            <div className="mb-3 col-12">
              <label htmlFor="formrow-firstname-Input">Date <span className="text-danger">*</span></label>
              <input
                type="date"
                required
                className="form-control "
                value={edit_follow_date}
                onChange={e => setEditFollowUpDate(e.target.value)}
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Purpose</label>
              <input
                type="Purpose"
                className="form-control "
                placeholder="Enter Purpose"
                value={edit_purpose}
                onChange={e => setEditPurpose(e.target.value)}
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Assigned</label>
              <Select
                placeholder="Select Assigned"
                className="basic-multi-select"
                classNamePrefix=" select"
                styles={colourStyles}
                options={assign_option}
                isMulti
                defaultValue={edit_assign}
                onChange={e => setEditAssign(e)}
              />
            </div>
            <div className="mt-2 text-end">
              <button className=" btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}
    </React.Fragment>
  )
}

export default Notes
