import React, { useEffect, useMemo, useState } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  Label,
  Input,
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import ReactSelect from "react-select";
import "../../../assets/css/Sales/Sales.css";
import "../../../assets/css/Sales/Proposal.css";

import InfoGif from "../../../assets/images/GIF/question-mark.gif";
import axios from "axios";
import moment from "moment";
import Loader from "pages/Separate/Loader";
import configure from "configure";
import AccessDeniedPopup from "routes/AccessDeniedPopup";
import "../../../assets/css/Work/generalTask.css";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import AddTime from "../../../assets/images/AddTime.jpg";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { useSelector } from "react-redux";
import {
  GeneralTaskWork,
  priorityOption,
  RelatedToOption,
  TimeOption,
} from "utils/common-options";
import { fetchData, FetchPostData } from "utils/common-fetchData";
import EditTaskTab from "./EditTaskTab";
import {
  checkPermissionsByModule,
  filterData,
  transformGeneralData,
} from "utils/common-helpers";
import { generaltaskColumns } from "utils/columns/work/workColumns";
import AddTaskTab from "./AddTaskTab";
import { useNavigate } from "react-router-dom";
import Pagination from "components/Common/Pagination";
import NewPagination from "components/Common/Newpagination";

function TableContainer({
  columns,
  datas,
  allData,
  locationOptions,
  alldependency,
  toggleLoading,
}) {
  const [fixedContent, setFixedContent] = useState(false);
  const [addTask, setAddTask] = useState(false);
  const [data, setData] = useState([]);
  const [alldata, setAllData] = useState([]);
  const [gTaskFilter, setGTaskFilter] = useState(false);
  useEffect(() => {
    setData(datas);
    setAllData(datas);
  }, [datas]);
  // Api integration,
  const [title, settitle] = useState("");
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [selectedRelated, setSelectedRelated] = useState(RelatedToOption[0]);
  const navigate = useNavigate();

  document.addEventListener("scroll", () => {
    const scrollThreshold = window.innerHeight * 0.3;
    if (window.scrollY > scrollThreshold) {
      setFixedContent(true);
    } else {
      setFixedContent(false);
    }
  });

  document.addEventListener("keydown", (e) => {
    if (e.key == "Escape") {
      if (addTask) {
        setAddTask(false);
      }
    }
  });

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "enddate",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize, globalFilter } = state;
  useEffect(() => setPageSize(50), []);
  const [name_modal, setNameModal] = useState(false);

  const info = [
    {
      title: "Assigned",
      mean: " <p> <strong>Meaning :</strong> In the Mars System's Lead Module, the <strong>Assigned </strong> column designates the specific team member or individual responsible for overseeing and taking action on a lead or potential business opportunity. </p>",
      purpose:
        " <p> <strong>Purpose :</strong> The purpose of the <strong>Assigned</strong> column in the Lead Module is to assign clear accountability for each lead. By identifying who is responsible for nurturing and converting leads into customers, it streamlines lead management, ensures follow-up, and enhances the chances of successful lead conversion. This fosters a more efficient and organized approach to lead handling within the Mars System.</p>",
    },
    {
      title: "Name",
      mean: "<p><strong>Meaning :</strong> In the Mars System's Proposal Module, the <strong>Name</strong> column represents the name of the customer or organization for whom the proposal is being prepared.</p>",
      purpose:
        "<p> <strong>Purpose :</strong> The purpose of the <strong>Name</strong>  column is to provide specific identification of the recipient of the proposal. It ensures that each proposal is customized and addressed to the correct customer or organization, creating a personalized and professional impression. This column is crucial for accurate proposal documentation and effective communication within the Mars System, helping to build strong customer relationships and tailor proposals to individual needs.</p>",
    },
    {
      title: "Type",
      mean: "<p><strong>Meaning :</strong> In the Mars System's Proposal Module, the <strong>Type</strong>  column categorizes proposals into distinct types, such as <strong>General</strong>, <strong>Website</strong>, <strong>SEO </strong>, <strong>Digital Marketing</strong>, or <strong>Dedicated Resource.</strong> </p>",
      purpose:
        "</strong> <p> <strong>Purpose :</strong> The purpose of the <strong>Type</strong> column is to provide specific information about the person linked to the lead. It allows for personalized communication and relationship-building with leads. By knowing the names of potential clients or contacts, the Mars System's users can establish a more personal and targeted approach to lead engagement, which can significantly improve lead conversion rates and customer satisfaction</p>",
    },
    {
      title: "Currency",
      mean: "<p><strong>Meaning :</strong> In the Mars System's Proposal Module, the  <strong>Currency</strong> column specifies the currency in which the proposal's pricing and financial details are presented.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Currency</strong> column is to provide clarity and transparency regarding the currency in which the proposal's costs and pricing are expressed. This ensures that both your team and the client understand the financial terms of the proposal. By clearly indicating the currency, you facilitate accurate pricing and financial communication, especially in international or multi-currency transactions within the Mars System.</p>",
    },
    {
      title: "Price",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Proposal Module, the <strong>Price</strong> column denotes the total cost or pricing details associated with the proposal, which may include itemized costs for services, products, or any other expenses.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Price</strong>  column is to provide a clear and itemized breakdown of the costs associated with the proposal. It allows both your team and the client to understand the financial aspects of the proposal, making it easier to review and negotiate terms. This column ensures transparency in pricing and supports informed decision-making within the Mars System, helping to reach agreements that align with both parties' expectations.</p>",
    },
    {
      title: "Created At",
      mean: " <p><strong>Meaning :</strong> In the Mars System's Proposal Module, the <strong>Created At</strong> column records the date and time when the proposal was initially created or entered into the system.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Created At</strong> column is to track and document the proposal's creation timeline. It provides valuable information about the proposal's age and origin, allowing users to gauge its recency and relevance. This column aids in organizing proposals chronologically, assessing the efficiency of the proposal creation process, and ensuring that proposals are up-to-date and timely managed within the Mars System.</p>",
    },
    {
      title: "Open Till",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Proposal Module, the <strong> Open Till</strong>column signifies the date until which the proposal remains valid and open for consideration by the client.</p>",
      purpose:
        "<p><strong>Purpose :</strong>  The purpose of the  <strong> Open Till</strong> column is to establish a clear expiration date for the proposal's validity. It ensures that both your team and the client are aware of the timeframe within which the proposal can be accepted or negotiated. This column helps manage proposal timelines, avoid misunderstandings, and maintain accurate records of proposal status within the Mars System.</p>",
    },
    {
      title: "Status",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Proposal Module, the <strong>Status</strong> column categorizes proposals into different stages, including <strong>In Progress </strong>, <strong>On Hold </strong>, <strong>Lost </strong>, or <strong>Won </strong>.</p>",
      purpose:
        "<p><strong>Purpose :</strong>  The purpose of the  <strong>Status</strong> column is to provide a quick overview of the current state of each proposal. It helps users track and manage the progression of proposals, from creation to closure. By categorizing proposals into these stages, the Mars System facilitates efficient proposal management, allowing teams to focus their efforts on active proposals, prioritize follow-ups, and accurately report on the status of each proposal. This column aids in effective decision-making and resource allocation.</p>",
    },
  ];
  const [viewData, setViewData] = useState(false);
  const onCloseModal = () => {
    setAddTask(false);
  };
  const updateData = (data) => {
    setData(data);
  };
  const [status, setStatus] = useState("all");
  const [filter_location, setFilterLocation] = useState([]);
  const [filter_assigne, setFilterAssignee] = useState([]);
  const [filter_related, setFilterRelated] = useState([]);
  const [filter_status, setFilterStatus] = useState([]);

  return (
    <div>
      <div id="support-header" className="mb-4 align-items-center row">
        <div className="pr-0 support-status-part general-status-part col-md-9">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start w-100 text-center align-items-center gap-3">
              <a
                className={`text-green pt-2 px-0 rounded work-status general-task-status ${
                  status == "all" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("all");
                  setData(allData);
                }}
              >
                <h4>{allData?.length}</h4>
                <h4>All</h4>
              </a>
              <a
                className={`text-green pt-2 px-0 rounded work-status general-task-status ${
                  status == "not-started" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("not-started");
                  setData(
                    allData?.filter((obj) => obj.status == "not-started")
                  );
                }}
              >
                <h4>
                  {
                    allData?.filter((obj) => obj?.status == "not-started")
                      ?.length
                  }
                </h4>
                <h4>Not Started</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "in-progress" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("in-progress");
                  setData(
                    allData?.filter((obj) => obj.status == "in-progress")
                  );
                }}
              >
                <h4>
                  {
                    allData?.filter((obj) => obj?.status == "in-progress")
                      ?.length
                  }
                </h4>
                <h4>In Progress</h4>
              </a>
              <a
                className={`text-green pt-2 px-2 rounded work-status general-task-status ${
                  status == "complete" ? "activeProjectTab" : ""
                }`}
                onClick={() => {
                  setStatus("complete");
                  setData(allData?.filter((obj) => obj.status == "complete"));
                }}
              >
                <h4>
                  {allData?.filter((obj) => obj?.status == "complete")?.length}
                </h4>
                <h4>Complete</h4>
              </a>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end pl-0 support-action-part col-md-3">
          <div className="text-md-end">
            <a
              className="s btn text-white me-2 btn-save btn-label"
              onClick={() => setAddTask(true)}
            >
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Task
            </a>
            <button
              className="s btn btn-red btn-label"
              onClick={() => navigate(-1)}
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </div>
      </div>
      <Row>
        <div className="col-md-12 my-4 d-flex">
          <div className="col-md-6 d-flex justify-content-start align-items-center">
            <div className="col-md-2 d-flex justify-content-start align-items-center">
              <select
                className="s me-4 form-select"
                style={{ width: "100px" }}
                value={pageSize}
                onChange={(e) => setPageSize(e.target.value)}
              >
                {[10, 25, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="mb-2">
              <button
                onClick={() => setGTaskFilter(!gTaskFilter)}
                className="btn btn-outline-secondary  me-3"
              >
                <i className="fas fa-filter"></i>
              </button>
            </div>
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative" id="generalTask">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${
              fixedContent && page.length >= 5
                ? "fixed-general-task-header"
                : "fix-table-header"
            } `}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i + "head"} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className;
                  return (
                    <th
                      key={index}
                      className={` col-t-${
                        index + 1
                      } ${classNames} customShortIcon custom-text`}
                      // {...column.getHeaderProps()}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}

                      {column.isInfoIcon ? (
                        <a
                          onClick={() => {
                            // if(column)
                            if (column.Header == "General Task") {
                              setViewModal(true);
                            } else if (column.Header == "Action") {
                              setActionModal(true);
                            }
                          }}
                        >
                          <i
                            title="info"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </a>
                      ) : null}

                      {column.isShortIcon && (
                        <span
                          onClick={() =>
                            column.getSortByToggleProps().onClick()
                          }
                          className="ms-2 cursor-pointer customIcon"
                        >
                          <i
                            title="Ascending"
                            className={`bi bi-arrow-up info-icon ${
                              column.isSorted && !column.isSortedDesc
                                ? "text-dark"
                                : ""
                            }`}
                          ></i>
                          <i
                            title="Descending"
                            className={`bi bi-arrow-down info-icon ${
                              column.isSorted && column.isSortedDesc
                                ? "text-dark"
                                : ""
                            }`}
                          ></i>
                        </span>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody className="fix-support-table-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              const priority = row.original.priority_col;
              const created_date = new Date(row.original.duedate);
              const current_date = Date("05-10-2024");
              const hasMissedDeadline = current_date > created_date;
              return (
                <tr {...row.getRowProps()} key={i + "row"}>
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      className={`t-col-${index + 1} ${cell.column.className} 
                       ${priority == "High" ? "highPriority" : ""}
                      ${current_date > created_date ? "missedDeadline" : ""}
                      `}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
          nextPage={nextPage}
        />
      </Row>

      <Modal
        isOpen={name_modal}
        toggle={() => {
          tog_center();
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            {title}
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                {info.map((item, i) => {
                  if (item.title == title) {
                    return (
                      <div key={i + "info"}>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{ __html: item.mean }}
                          ></span>
                        </span>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.purpose }}
                        ></div>
                      </div>
                    );
                  }
                })}
                <div></div>
                <div className="text-center mt-70">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setNameModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal size="md" centered isOpen={viewData} className="activity-log">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Log Details</h5>
          <button
            type="button"
            onClick={() => {
              setViewData(false);
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <div className="table-responsive">
            <table className="table mb-0 table">
              <thead className="table-light">
                <tr>
                  <th>Currency</th>
                  <th>All</th>
                  <th>Sent</th>
                  <th>Lost</th>
                  <th>Won</th>
                </tr>
              </thead>
              <tbody>
                {/* {viewData.map((items, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{items}</th>
                      <td>
                        <div
                          className="no-padding-desc"
                          dangerouslySetInnerHTML={{
                            __html: oldValue[index],
                          }}
                        />
                      </td>
                      <td>

                      </td>
                    </tr>
                  )
                })} */}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>

      <Modal size="xl" isOpen={addTask} className="modal-fullscreen">
        <div className="modal-header mb-0">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "100%" }}
          >
            <h5 className="col-6 modal-title mt-0">Add Task</h5>
          </div>
        </div>
        <div className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0">
          <Row className="gx-4 justify-content-between">
            <div className="side-tab h-100 py-3 bd-fixed-sidetab custom-Edit-Side">
              <Nav pills className="flex-column">
                {RelatedToOption?.map((obj, i) => {
                  return (
                    <NavItem key={i}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab == obj.id,
                        })}
                        onClick={() => {
                          setverticalActiveTab(obj.id?.toString());
                          setSelectedRelated("Lead");
                        }}
                      >
                        {obj.label}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
            </div>
            <div className="bd-right-side-tab custom-right-side pt-4">
              <div className="px-0 h-100 pl-0">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <AddTaskTab
                      verticalActiveTab={verticalActiveTab}
                      locationOptions={locationOptions}
                      alldependency={alldependency}
                      onCloseModal={onCloseModal}
                      toggleLoading={toggleLoading}
                      updateData={updateData}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <AddTaskTab
                      verticalActiveTab={verticalActiveTab}
                      locationOptions={locationOptions}
                      alldependency={alldependency}
                      onCloseModal={onCloseModal}
                      toggleLoading={toggleLoading}
                      updateData={updateData}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <AddTaskTab
                      verticalActiveTab={verticalActiveTab}
                      locationOptions={locationOptions}
                      alldependency={alldependency}
                      onCloseModal={onCloseModal}
                      toggleLoading={toggleLoading}
                      updateData={updateData}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <AddTaskTab
                      verticalActiveTab={verticalActiveTab}
                      locationOptions={locationOptions}
                      alldependency={alldependency}
                      onCloseModal={onCloseModal}
                      toggleLoading={toggleLoading}
                      updateData={updateData}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    <AddTaskTab
                      verticalActiveTab={verticalActiveTab}
                      locationOptions={locationOptions}
                      alldependency={alldependency}
                      onCloseModal={onCloseModal}
                      toggleLoading={toggleLoading}
                      updateData={updateData}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </Row>
        </div>
      </Modal>

      {gTaskFilter ? (
        <Draggable>
          <ResizableBox
            style={{
              position: "fixed",
              top: "24%",
              right: "6%",
              zIndex: "1055",
              backgroundColor: "#fff",
              minWidth: "90%",
            }}
          >
            <div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  setData(
                    filterData(allData, {
                      location: filter_location?.value,
                      assigned: filter_assigne?.map((obj) =>
                        obj?.value?.toString()
                      ),
                      status: filter_status?.map((obj) => obj?.value),
                      related: filter_related?.map((obj) => obj?.value),
                    })
                  );
                }}
              >
                <Row className="align-items-end">
                  <Row className="d-flex flex-wrap pe-0 ps-0 me-0">
                    <div className="col-1 mt-2 sf-location-box">
                      <label htmlFor="">Location</label>
                      <Select
                        placeholder="Select"
                        className="basic-select"
                        classNamePrefix=" select"
                        options={locationOptions}
                        closeMenuOnSelect={true}
                        value={filter_location}
                        onChange={(e) => setFilterLocation(e)}
                      />
                    </div>
                    <div className="col-3 mt-2">
                      <label htmlFor="">Assignee</label>
                      <ReactSelect
                        placeholder="Select Assignee"
                        className="basic-multi-select "
                        classNamePrefix="select"
                        options={alldependency?.users}
                        closeMenuOnSelect={false}
                        isMulti="true"
                        value={filter_assigne}
                        onChange={(e) => setFilterAssignee(e)}
                      />
                    </div>
                    <div className="col-2 mt-2">
                      <label htmlFor="">Related</label>
                      <Select
                        placeholder="Select Related"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={RelatedToOption}
                        closeMenuOnSelect={false}
                        isMulti="true"
                        value={filter_related}
                        onChange={(e) => setFilterRelated(e)}
                      />
                    </div>
                    <div className="col-2 mt-2">
                      <label htmlFor="">Status</label>
                      <Select
                        placeholder="Select Status"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={GeneralTaskWork}
                        closeMenuOnSelect={false}
                        isMulti="true"
                        value={filter_status}
                        onChange={(e) => setFilterStatus(e)}
                      />
                    </div>
                    <div className="col-md-4 d-flex justify-content-end align-items-end mb-2 sf-actions">
                      <a
                        className="btn  btn-danger me-1"
                        style={{
                          fontSize: "14px",
                          backgroundColor: "#fed36d",
                          borderColor: "#fed36d",
                          color: "black",
                        }}
                        onClick={() => {
                          setFilterLocation(null);
                          setFilterAssignee([]);
                          setFilterStatus([]);
                          setFilterRelated([]);
                          setData(allData);
                        }}
                      >
                        Clear Filters
                      </a>
                      <button className="btn  btn-save btn-label me-1">
                        <i className="fas fa-filter search-icon label-icon"></i>{" "}
                        Filter
                      </button>
                      <a
                        className="btn btn-red btn-label me-1"
                        onClick={() => setGTaskFilter(false)}
                      >
                        <i className="dripicons-cross label-icon"></i> Close
                      </a>
                    </div>
                  </Row>
                </Row>
              </Form>
            </div>
          </ResizableBox>
        </Draggable>
      ) : null}
    </div>
  );
}

const GeneralTask = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("");
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [editTask, setEditTask] = useState(false);
  const [addHours, setAddHours] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [modal_Access, setAccessDenied] = useState(false);
  const [projectType, setProjectType] = useState("2");
  const [allData, setAllData] = useState([]);
  const [taskId, setTaskId] = useState("");
  const [timeToken, setTimeToken] = useState(null);
  const [timesData, setTimesData] = useState([]);
  const [data, setData] = useState([]);
  const roles = useSelector((state) => state?.Roles?.apiData);
  const getLocations = useSelector((state) => state?.location?.locations);

  // Edit
  const [boxWidth, setBoxWidth] = useState(0);
  const [alldependency, setAllDependency] = useState({});
  const [editData, setEditData] = useState({});
  const [relatedData, setRelatedData] = useState({});
  const [task , setTask] = useState([])

  const toggleLoading = (loading) => {
    setLoading(loading);
  };

  const fetchAllData = async () => {
    setLoading(true);
    try {
      const authToken = secureLocalStorage.getItem("auth_token");
      const datas = await fetchData(toggleLoading, "generalData", authToken);
      setAllDependency(transformGeneralData(datas?.data));
      setTask(
        datas?.data?.subtasks?.map(obj => ({
          id: obj.task,
          related: obj.related,
          subtasks: obj.subtasks,
          value: obj.task,
          label: obj.task,
        }))
      )
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllData();
    setProjectType("3");
  }, []);

  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth;
      setBoxWidth(viewportWidth * 0.65);
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  document.addEventListener("keydown", (e) => {
    if (e.key == "Escape") {
      if (addHours) {
        setAddHours(false);
      }
      if (editTask) {
        setEditTask(false);
      }
    }
  });

  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape")
      setmodal_fullscreen(false),
        setEditTask(false),
        setAddTask(false),
        setAddHours(false);
  });

  //meta title
  document.title = "General Task | Zithas Technologies";

  const handleChangeStatus = async (e, id) => {
    const formDatas = {
      user_id: localStorage.getItem("auth_id"),
      role_id: secureLocalStorage.getItem("auth_role"),
      general_id: id,
      status: e,
      type:1
    };
    const response = await FetchPostData(
      toggleLoading,
      "changeGeneralTaskStatus",
      secureLocalStorage?.getItem("auth_token"),
      formDatas
    );
    if (response?.data.success) {
      Swal.fire({
        icon: "success",
        title: "Status has been changed successfully!",
      }).then((data) => {
        setData(response?.data?.data?.tasks);
        setAllData(response?.data?.data?.tasks);
      });
    }
  };

  const openAccessDenied = (access) => {
    setAccessDenied(access);
  };

  const onDelete = async (id) => {
    const permission = checkPermissionsByModule(roles, 8, 5);
    if (permission) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this task !",
        icon: "warning",
        showCancelButton: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          const formEntities = {
            user_id: localStorage.getItem("auth_id"),
            general_id: id,
            type:1
          };
          const response = await FetchPostData(
            toggleLoading,
            "deleteGeneralTasks",
            secureLocalStorage?.getItem("auth_token"),
            formEntities
          );
          if (response?.data?.success) {
            Swal.fire({
              icon: "success",
              text: "General task deleted successfully.",
            }).then(() => {
              setData(response?.data?.data?.tasks);
              setAllData(response?.data?.data?.tasks);
            });
          }
        }
      });
    } else {
      setAccessDenied(true);
    }
  };

  const onEditPermission = async (obj) => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("auth_id"));
    formData.append("module_id", 8);
    formData.append("type", 1)
    const permission = checkPermissionsByModule(roles, 8, 4);
    if (permission) {
      setEditData(obj);
      setverticalActiveTab(
        RelatedToOption?.find((rel) => rel.value == obj.related)?.id?.toString()
      );
      setEditTask(true);
    } else {
      setAccessDenied(true);
    }
  };

  const findTimes = async (Id) => {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/timeUpdateData/${Id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.success) {
        setLoading(false);
        setTimesData(response?.data.data);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleAddHour = async (id) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("auth_id"));
    formData.append("module_id", 8);
    formData.append("type", 1)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      if (response) {
        setLoading(false);
        if (response.data.data.india[3] == 1 || response.data.data.uk[3] == 1) {
          await findTimes(id);
          setAddHours(true);
          setTaskId(id);
        } else {
          setAccessDenied(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = useMemo(
    () =>
      generaltaskColumns(
        handleAddHour,
        onEditPermission,
        onDelete,
        handleChangeStatus,
        openAccessDenied
      ),
    [projectType]
  );

  const fetchAllTabData = async () => {
    const formDataEntries = {
      role_id: secureLocalStorage.getItem("auth_role"),
      user_id: localStorage.getItem("auth_id"),
      type:1
    };
    const datas = await FetchPostData(
      toggleLoading,
      "/generalTasks",
      "",
      formDataEntries
    );
    if (datas?.data?.success) {
      setData(datas?.data?.data?.tasks);
      setAllData(datas?.data?.data?.tasks);
    }
  };

  useEffect(() => {
    if (data?.length == 0) {
      fetchAllTabData();
    }
  }, []);

  const handleTimeToken = async (e) => {
    e.preventDefault();
    if (timeToken !== null) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("user_id", localStorage.getItem("auth_id"));
        formData.append("task_id", taskId);
        formData.append("time", timeToken?.value);
        const formDatas = {
          user_id: localStorage.getItem("auth_id"),
          task_id: taskId,
          time: timeToken?.value,
          type:1
        };
        const response = await FetchPostData(
          toggleLoading,
          "timeUpdate",
          secureLocalStorage?.getItem("auth_token"),
          formDatas
        );
        if (response?.data?.success) {
          setLoading(false);
          setTimeToken(null);
          setTimesData(response?.data?.data?.times);
          setData(response?.data?.data?.tasks?.tasks);
          setAllData(response?.data?.data?.tasks?.tasks);
          Swal.fire({
            icon: "success",
            text: "Time added successfully.",
          }).then((data) => {});
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select time.",
      });
    }
  };
  const onCloseModal = () => {
    setEditTask(false);
  };

  const updateData = (data) => {
    setData(data);
  };
  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"General Task"} />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      datas={data}
                      allData={allData}
                      locationOptions={getLocations}
                      alldependency={alldependency}
                      toggleLoading={toggleLoading}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={`note-sticky-form ${addHours ? "d-block" : "d-none"}`}>
        <Draggable>
          <ResizableBox
            width={boxWidth}
            style={{
              position: "fixed",
              top: "10%",
              right: "18%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Time Taken</h5>
            </div>

            <div className="modal-body pe-4">
              <Form
                onSubmit={handleTimeToken}
                className="row d-flex justify-content-center align-items-center"
              >
                <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
                  <div className="text-center">
                    <img src={AddTime} alt="Addepe" style={{ width: "100%" }} />
                  </div>
                </div>

                <div className="col-md-7 d-flex flex-column justify-space-between  gap-5 mb-4">
                  <div className="col-12 d-flex justify-space-between align-items-center">
                    <div className="col-4 d-flex flex-column  flex-wrap">
                      <label htmlFor="">
                        Select Time <span className="text-danger">*</span>
                      </label>

                      <ReactSelect
                        placeholder="Select Time"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={TimeOption}
                        value={timeToken}
                        onChange={(selectedOption) =>
                          setTimeToken(selectedOption)
                        }
                      />
                    </div>
                    <div className="text-end mt-4">
                      <button className=" btn btn-save btn-label">
                        <i className="fas fa-save me-2 label-icon"></i>
                        Save
                      </button>
                      <a
                        onClick={() => {
                          setTaskId("");
                          setAddHours(false);
                          setTimeToken(null);
                        }}
                        className=" btn ms-md-2 btn-red btn-label"
                      >
                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                        CANCEL
                      </a>
                    </div>
                  </div>

                  <div className="table-responsive col-md-12 time-taken-table">
                    <Table className="table table-bordered mb-0">
                      <thead
                        className="bg-white"
                        style={{ position: "sticky", top: 0 }}
                      >
                        <tr>
                          <th className="text-center header">Sr. No</th>
                          <th className="header">User</th>
                          <th className="text-center header">Date</th>
                          <th className="text-center header">Time Taken</th>
                        </tr>
                      </thead>
                      <tbody>
                        {timesData
                          ?.map((item, i) => {
                            return (
                              <tr key={item.id}>
                                <th scope="row" className="text-center">
                                  {i + 1}
                                </th>
                                <td>{item.name}</td>
                                <td className="text-center">
                                  {moment(item.created_at).format("DD-MM-YYYY")}
                                </td>
                                <td className="text-center">{item.times}</td>
                              </tr>
                            );
                          })
                          ?.reverse()}
                        {timesData.length == 0 && (
                          <tr>
                            <td className="text-center" colSpan={10}>
                              No Data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Form>
            </div>
          </ResizableBox>
        </Draggable>
      </div>

      <Modal
        size="xl"
        isOpen={editTask}
        toggle={() => {
          tog_fullscreen();
        }}
        className="modal-fullscreen"
      >
        <div className="modal-header mb-0">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "100%" }}
          >
            <h5 className="col-6 modal-title mt-0">Edit Task</h5>
          </div>
        </div>
        <div className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0">
          <Row className="gx-4 justify-content-between">
            <div className="side-tab h-100 py-3 bd-fixed-sidetab custom-Edit-Side">
              <Nav pills className="flex-column">
                {RelatedToOption?.map((obj, i) => {
                  return (
                    <NavItem key={i}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab == obj.id,
                        })}
                        onClick={() => {
                          setverticalActiveTab(obj.id?.toString());
                          setRelatedData(obj);
                        }}
                      >
                        {obj.label}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
            </div>
            <div className="bd-right-side-tab custom-right-side pt-3">
              <div className="px-0 h-100 pl-0">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  {RelatedToOption?.map((obj, i) => {
                    return (
                      <TabPane tabId={obj?.id?.toString()} key={i}>
                        <EditTaskTab
                          verticalActiveTab={verticalActiveTab}
                          editData={editData}
                          getLocations={getLocations}
                          alldependency={alldependency}
                          onCloseModal={onCloseModal}
                          toggleLoading={toggleLoading}
                          updateData={updateData}
                          relatedData={relatedData}
                          task={task}
                        />
                      </TabPane>
                    );
                  })}
                </TabContent>
              </div>
            </div>
          </Row>
        </div>
      </Modal>
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  );
};

export default GeneralTask;
