import React, { useEffect, useState } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { RelatedToOption } from "utils/common-options";
import moment from "moment";
import { Link } from "react-router-dom";
import configure from "configure";
import Swal from "sweetalert2";

export const generalDashboardColumns = (handleEditTask, onDelete, handleChangeStatus, onManagePlan, handleAddHour, followChangeStatus, onAddTask) => [
    {
        Header: "Id",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_id",
        Cell: cellProps => {
            const [status, setStatus] = useState(cellProps?.row?.original?.plan_day);
            useEffect(() => {
                console.log('1')
                setStatus(cellProps?.row?.original?.plan_day)
            }, [cellProps?.row?.original])
            return (
                <div className="d-flex justify-content-center align-items-center gap-1">
                    <span>
                        <a
                            className="btn btn-sm me-1"
                            title="Plan My Day"
                            onClick={() => {
                                onManagePlan(cellProps.row.original?.id, cellProps?.row?.original?.plan_day == 0 ? 1 : 0, cellProps?.row?.original?.type)
                                setStatus(status == 0 ? 1 : 0)
                            }}
                        >
                            <i className={`${status == 1 ? "bi bi-brightness-high-fill color-orange" : "bi bi-brightness-high "} fs-4`}></i>
                        </a>
                    </span>
                    <span>{cellProps.row.original.id}</span>
                </div>
            )
        },
    },
    {
        Header: "Status",
        accessor: "status",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_id text-center",
        Cell: cellProps => {
            const [isChecked, setIsChecked] = useState(cellProps.row.original.checked || false);
            const handleCheckboxChange = async (e, id) => {
                const checked = e.target.checked;
                setIsChecked(checked);
                const userConfirmed = await Swal.fire({
                    title: "Are you sure?",
                    text: "Do you want to change the status?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, change it!",
                    cancelButtonText: "No, cancel",
                });

                if (userConfirmed.isConfirmed) {
                    handleChangeStatus(e, cellProps.row.original.id);
                    setIsChecked(false);
                } else {
                    setIsChecked(false);
                }
            };

            let priorty;
            if (cellProps.row.original?.priorty == "high") {
                priorty = <i title="High priority" className="bx bxs-hot icon-hot"
                ></i>
            } else if (cellProps.row.original?.priorty == "medium") {
                priorty = <i title="Medium priority" className="bx bxs-flame icon-flame"
                ></i>
            } else if (cellProps.row.original?.priorty == "low") {
                priorty = <i title="Low priority" className="bx bxs-sun icon-sun"
                ></i>
            }
            return (
                <div className="d-flex align-items-center gap-3 justify-content-center">
                    {/* <div className="line-height-0"> */}
                    {priorty}
                    {/* </div> */}
                    <div className="text-end" id="customCheckbox" >

                        {
                            cellProps.row.original.type === 1 ? (
                                <input
                                    type="checkbox"
                                    className="form-check-input custom-checkbox mt-2 general-status"
                                    id={`customCheck-${cellProps.row.id}`}
                                    value="complete"
                                    disabled={
                                        cellProps.row.original.added_by != localStorage.getItem("auth_id")
                                    }
                                    checked={isChecked}
                                    onChange={(e) => handleCheckboxChange(e, cellProps.row.original.id)}
                                />
                            ) : (
                                <div className="text-center">
                                    <button
                                        className="btn btn-outline-secondary btn-sm"
                                        onClick={() =>
                                            followChangeStatus({
                                                user_id: localStorage.getItem("auth_id"),
                                                followup_id: cellProps.row.original.id,
                                            })
                                        }
                                    >
                                        <i className="fas fa-check"></i>
                                    </button>
                                </div>
                            )
                        }
                    </div >
                </div>
            )
        }
    },
    {
        Header: "Related Name",
        accessor: "related",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_project_name",
        Cell: cellProps => {
            return (
                <div>{RelatedToOption?.find((obj) => obj?.value == cellProps.row.original.related)?.label}
                    {` ${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to != null ? ` - ${cellProps?.row?.original?.related_name || cellProps?.row?.original?.related_to}` : ""}`}
                </div>
            )
        }
    },
    {
        Header: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_task_name",
    },
    {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_end_date",
        Cell: cellProps => {
            return (
                <div>{cellProps?.row?.original?.enddate != null ? moment(cellProps?.row?.original?.enddate)?.format('DD-MM-YYYY') : "-"}</div>
            )
        }
    },
    {
        Header: "Spent Hours",
        accessor: "spent_hours",
        isInfoIcon: false,
        isShortIcon: true,
        className: "dg_last_update",
        Cell: cellProps => {
            return (
                <div className="">
                    {cellProps?.row?.original?.spent_hours || "-"}
                </div>
            )
        },
    },
    {
        Header: "Action",
        accessor: "action",
        className: "dg-action",
        Cell: cellProps => {
            return (
                <>
                    {cellProps?.row?.original?.type == 1 ? (
                        <div className="d-flex justify-content-center">
                            <a
                                className="btn btn-sm btn-outline-secondary me-1"
                                title="View Activity"
                                onClick={() => handleEditTask(cellProps?.row?.original, "view")}
                            >
                                <i className="fas fa-eye "></i>
                            </a>

                            <a title="Add Hours" className="btn btn-sm btn-outline-secondary me-1" onClick={() => handleAddHour(cellProps.row.original.id)}>
                                <i className="bi bi-alarm-fill"></i>
                            </a>
                            <UncontrolledDropdown
                                className="dropdown"
                                style={{ width: "29px" }}
                            >
                                <DropdownToggle
                                    className="btn btn-sm btn-outline-secondary w-100"
                                    color="white"
                                >
                                    <i className="mdi mdi-dots-horizontal"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <a
                                        className="dropdown-item"
                                        title="Edit Activity"
                                        onClick={() => handleEditTask(cellProps?.row?.original, "edit")}
                                    >
                                        <i className="fas fa-pen pr-10"></i>
                                        Edit Activity
                                    </a>
                                    <a title="Delete Activity" className="dropdown-item" onClick={() => onDelete(cellProps?.row?.original?.id)}>
                                        <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                                        Delete Activity
                                    </a>


                                </DropdownMenu>

                            </UncontrolledDropdown>
                        </div>
                    ) : (
                        <>
                            <Link title="view lead" className="btn btn-sm btn-outline-secondary me-1" to={`${configure.appUrl}sales/edit-lead/${cellProps.row.original?.related_id}`} >
                                <i className="fas fa-arrow-right"></i>
                            </Link>
                            <a title="Add Task" className="btn btn-sm btn-outline-secondary me-1 "
                                onClick={() => onAddTask(cellProps.row.original?.related_id)}>
                                <i className="fas fa-clipboard-list"></i>
                            </a>
                        </>
                    )}
                </>

            )
        },
    },
]

export const todayPlanClolumns = () => [
    {
        Header: "Id",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: 'pl_id',
    },
    {
        Header: "Task Name",
        accessor: "title",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_task_name",
    },
    {
        Header: "Related",
        accessor: "related_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_related_name",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps?.row?.original?.related_name != "-" ? cellProps?.row?.original?.related_name : cellProps?.row?.original?.related_to || cellProps?.row?.original?.related}</div>
            )
        }
    },
    {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_end_date",
        Cell: cellProps => {
            return (
                <div>{moment(cellProps?.row?.original?.enddate)?.format('DD-MM-YYYY')}</div>
            )
        }
    },
    {
        Header: "Priority",
        accessor: "priorty",
        isInfoIcon: false,
        isShortIcon: true,
        className: "pl_priority",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.priorty || "-"}</div>
            )
        }
    },
    {
        Header: "Estimated Time",
        accessor: "estimate_time",
        isInfoIcon: false,
        isShortIcon: true,
        className: "estimate_time",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.estimate_time || "-"}</div>
            )
        }
    },
    {
        Header: "Actual Time",
        accessor: "spent_hours",
        isInfoIcon: false,
        isShortIcon: true,
        className: "estimate_time",
        Cell: cellProps => {
            return (
                <div className="text-capitalize">{cellProps.row?.original?.spent_hours || "-"}</div>
            )
        }
    },
]

export const TaskTabelColumns = () => [
    {
        Header: "Task ID",
        accessor: "sr_no",
        title: "ID",
        isInfoIcon: false,
        className: "text-center task_ids",
    },
    {
        Header: "Task Name",
        title: "Task Name",
        accessor: "task_name",
        isInfoIcon: false,
        // className: "task_name_box",
    },
    {
        Header: "Hours",
        accessor: "hours",
        isInfoIcon: false,
        // className: "status_box text-center",
    },
    {
        Header: "Status",
        accessor: "status_name",
        isInfoIcon: false,
        // className: "status_box text-center",
    },
    {
        Header: "Time Taken",
        title: "Last Updated Days",
        accessor: "timer",
        isInfoIcon: false,
        // className: "days_box text-center",
    },
    {
        Header: "Action",
        accessor: "action",
        // className: "task_action text-center",
        Cell: cellProps => {
            return (
                <div className="d-flex justify-content-center">
                    <a className="btn btn-sm btn-outline-success me-1"
                        title="Start / Stop Timer"
                    >
                        <i className="fa fa-stopwatch"></i>
                    </a>
                    {cellProps?.row?.original?.action ? (
                        <a className="btn btn-sm btn-outline-primary me-1"
                            title="Start / Stop Timer"
                        >
                            <i className="fa fa-plus"></i>
                        </a>
                    ) : null}
                </div>
            )
        },
    },
]