import React, { useEffect, useMemo, useState } from "react"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Modal,
  Form,
} from "reactstrap"
import { Link } from "react-router-dom"
// import { useTable, useGlobalFilter, usePagination } from "react-table"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import { useNavigate } from "react-router-dom"

import Pagination from "components/Common/Pagination"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import configure from "configure"
import Swal from "sweetalert2"

// css
import "../../../assets/css/Settings/Setting.css"
import NewPagination from "components/Common/Newpagination"

function TableContainer({ columns, data, setAddModal }) {
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   page,
  //   state,
  //   previousPage,
  //   nextPage,
  //   canPreviousPage,
  //   canNextPage,
  //   gotoPage,
  //   setPageSize,
  //   pageOptions,
  //   setGlobalFilter,
  //   pageCount,
  //   prepareRow,
  // } = useTable(
  //   {
  //     columns,
  //     data,
  //   },
  //   useGlobalFilter,
  //   usePagination
  // )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  // Render the UI for your table
  return (
    <div>
      <div
        className={`flex-column fixed-buttons ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => setAddModal(true)}
          className="btn btn-purple  fix-btn"
        >
          <i className="fas fa-plus me-2 icon-size"></i>
          <span>Add</span>
        </button>
        <button onClick={() => navigate(-1)} className="btn btn-red  fix-btn">
          <i className="dripicons-cross me-2 icon-center"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className=" form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col md={8} className="d-flex justify-content-end gap-2">
          <div className="me-4">
            <div className="search-box my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <button
              onClick={() => setAddModal(true)}
              className=" btn btn-purple btn-label"
            >
              {" "}
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Other
            </button>
          </div>
          <div>
            <button onClick={() => navigate(-1)} className=" btn btn-red btn-label">
              <i className="dripicons-cross icon-center me-2 label-icon"></i>CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <Table className="table table-bordered mt-4" {...getTableProps()}>
        <thead className={`${(fixedContent && page.length >= 10) ? "fixed-oe-setting-header" : ""}`}>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  // className={`customShortIcon col-md-${index === 1 || index === 2 ? "4" : "2"}`}
                  className={`${column.className} customShortIcon`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  {column.isInfoIcon ? (
                    <i
                      title="info"
                      className="bx bx-info-circle ms-1 info-icon"
                    ></i>
                  ) : null}

                    {column.isShortIcon && (
                      <span
                        onClick={() =>
                          column.getSortByToggleProps().onClick()
                        }
                        className="ms-2 cursor-pointer"
                      >
                        <i
                          title="Ascending"
                          className={`bi bi-arrow-up info-icon ${
                            column.isSorted && !column.isSortedDesc
                              ? "text-dark"
                              : ""
                          }`}
                        ></i>
                        <i
                          title="Descending"
                          className={`bi bi-arrow-down info-icon ${
                            column.isSorted && column.isSortedDesc
                              ? "text-dark"
                              : ""
                          }`}
                        ></i>
                      </span>
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {/* <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="row-hover" {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      className={`col-md-${
                        index === 1 || index === 2 ? "4" : "2"
                      }`}
                      key={index}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody> */}
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                className="row-hover"
                {...row.getRowProps()}
                key={i}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      className={cell.column.className}
                      key={index}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const OtherExpense = () => {
  const [modal_center, setmodal_center] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [editId, setEditId] = useState("")
  const [editName, setEditName] = useState("")
  const [editAmount, setEditAmount] = useState("")
  const [addName, setAddName] = useState("")
  const [addAmount, setAddAmount] = useState("")

  // Center Modal
  function tog_center() {
    setmodal_center(!modal_center)
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isShortIcon: true,
        className: "text-center oe-id",
      },
      {
        Header: "Name",
        accessor: "name",
        isInfoIcon: true,
        isShortIcon: true,
        className: "oe-name",
      },
      {
        Header: "Amount",
        accessor: "amount",
        isInfoIcon: true,
        isShortIcon: true,
        className: "oe-amount",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center oe-action",
        Cell: cellProps => {
          const handleDelete = async () => {
            Swal.fire({
              icon: "warning",
              text: "Are you sure ?",
              showCancelButton: true,
              confirmButtonText: "Yes delete it!",
            }).then(async willDelete => {
              if (willDelete.isConfirmed) {
                try {
                  setLoading(true)
                  const response = await axios({
                    method: "get",
                    url: `${configure.apiUrl}/deleteOtherExpense/${cellProps.row.original.id}`,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  if (response) {
                    setLoading(false)
                    Swal.fire({
                      icon: "success",
                      text: "Other expense deleted successfully.",
                    }).then(() => {
                      setDatas(response.data.data)
                    })
                  }
                } catch (error) {
                  setLoading(false)
                  console.log(error)
                }
              }
            })
          }

          const handleEdit = () => {
            setEditId(cellProps.row.original.id)
            setEditName(cellProps.row.original.name)
            setEditAmount(cellProps.row.original.amount)
            setmodal_center(true)
          }

          return (
            <div>
              <button
                onClick={() => {
                  {
                    handleEdit()
                  }
                }}
                className="btn action-btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </button>
              <Link
                onClick={() => handleDelete()}
                to="#"
                className="btn btn-sm action-btn btn-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/otherExpense`,
        // data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDatas(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const handleEditForm = async e => {
    e.preventDefault()

    try {
      setLoading(true)
      const formData = new FormData()
      //   name,amount,id
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("id", editId)
      formData.append("name", editName)
      formData.append("amount", editAmount)

      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/editOtherExpense`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        setDatas(response.data.data)
        setmodal_center(false)
        Swal.fire({
          icon: "success",
          text: "Other expense edited successfully !",
        }).then(() => {
          setEditId("")
        })
      }
    } catch (error) {
      setLoading(false)

      console.log(error)
    }
  }

  const handleAddForm = async e => {
    e.preventDefault()

    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("name", addName)
      formData.append("amount", addAmount)

      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/addOtherExpense`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        Swal.fire({
          icon: "success",
          text: "Other expense added successfully !",
        }).then(() => {
          setDatas(response.data.data)
          setAddName("")
          setAddAmount("")
          setAddModal(false)
        })
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <>
      <div>
        <Row className="ms-0 pe-0">
          <Col lg={12} className="p-0">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={data}
                  setLoading={setLoading}
                  setDatas={setDatas}
                  setAddModal={setAddModal}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Edit Department Modal */}
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Other Expense</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleEditForm}>
            <Row>
              <div className="col-6">
                <label htmlFor="">
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control "
                  value={editName}
                  onChange={e => setEditName(e.target.value)}
                />
              </div>
              <div className="col-6">
                <label htmlFor="">
                  Amount <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  placeholder="Enter Amount"
                  className="form-control "
                  value={editAmount}
                  onChange={e => setEditAmount(e.target.value)}
                />
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
            <button className="btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Submit
                </button>
            </div>
          </Form>
        </div>
      </Modal>

      {/* Add Other Modal  */}
      <Modal
        isOpen={addModal}
        toggle={() => {
          tog_center()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Other Expense</h5>
          <button
            type="button"
            onClick={() => {
              setAddModal(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleAddForm}>
            <Row>
              <div className="col-6">
                <label htmlFor="">
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  placeholder="Enter Name"
                  className="form-control "
                  value={addName}
                  onChange={e => setAddName(e.target.value)}
                />
              </div>
              <div className="col-6">
                <label htmlFor="">
                  Amount <span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="number"
                  placeholder="Enter Amount"
                  className="form-control "
                  value={addAmount}
                  onChange={e => setAddAmount(e.target.value)}
                />
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
            <button className="btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Submit
                </button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* ------------ */}
    </>
  )
}

export default OtherExpense
