import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Row, Form, Modal, Card, Col } from "reactstrap"
import Select from "react-select"
// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// assets
import Loader from "pages/Separate/Loader"
import "../../../../assets/css/Sales/Sales.css"
import userImg from "../../../../assets/images/users/avatar-1.jpg"
import axios from "axios"
import swal from "sweetalert"
import moment from "moment"
import configure from "configure"
import Swal from "sweetalert2"

const FollowUp = ({ followData, assigned }) => {
  const [fixButtons, setFixButtons] = useState(false)
  const [notesSidebar, setNotesSidebar] = useState(false)
  const [edit_status, setEditStatus] = useState(false)
  const [editNotesSidebar, setEditNotesSidebar] = useState(false)

  const [loading, setLoading] = useState(false)

  const [data, setDatas] = useState([])
  const navigate = useNavigate()
  const params = useParams()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })
  const [assign_option, setAssignOption] = useState([])
  useEffect(() => {
    setDatas(followData)
    setAssignOption(
      assigned?.map(obj => ({
        label: obj.name,
        value: obj.id,
      }))
    )
  }, [followData])

  const [assign, setAssigned] = useState([])
  const [notedate, setNotedate] = useState("")
  const [description, setDescription] = useState("")

  const addNotes = async e => {
    e.preventDefault()
    setLoading(true)
    const result = assign?.map(obj => obj.value).join(",")
    const formData = new FormData()
    formData.append("lead_id", params.id)
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("note_id", 0)
    formData.append("assign_user", result)
    formData.append("date", notedate)
    formData.append("description", description)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/add_followup`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDatas(response.data.data)
        setNotesSidebar(false)
        setLoading(false)
        setDescription("")
        setNotedate("")
        setNotedate("")
        setAssigned([])
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const deleteNote = async (e, note_id) => {
    swal({
      title: "Are you sure?",
      text: "You want to Delete this Follow Up !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async willdelete => {
      if (willdelete) {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        formData.append("lead_id", params.id)
        formData.append("user_id", localStorage.getItem("auth_id"))
        formData.append("followup_id", note_id)
        try {
          await axios({
            method: "post",
            url: `${configure.apiUrl}/delete_followup`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(res => {
            setLoading(false)
            console.log("delete_followup", res)
            Swal.fire({
              icon: "success",
              text: "Follow Up has been deleted successfully !",
            }).then(data => {
              setDatas(res.data.data)
            })
          })
        } catch (error) {
          console.log(error)
          setLoading(false)
        }
      }
    })
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
    // menu: (provided, state) => ({
    //   ...provided,
    //   top: "auto", // Reset the top position
    //   bottom: "100%", // Position the menu above the select input
    // }),
  }

  const [edit_description, setEditDescription] = useState("")
  const [edit_assign, setEditAssign] = useState([])
  const [edit_date, setEditDate] = useState("")
  const [follow_id, setFollowId] = useState("")

  const editNotes = async e => {
    e.preventDefault()
    setLoading(true)
    const result = edit_assign?.map(obj => obj.value).join(",")
    const formData = new FormData()
    formData.append("lead_id", params.id)
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("note_id", 0)
    formData.append("followup_id", follow_id)
    formData.append("assign_user", result)
    formData.append("date", edit_date)
    formData.append("description", edit_description)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/edit_followup`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        // swal-button swal-button--confirm
        swal({
          icon: "success",
          text: "Follow Up Edited Successfully",
        }).then(data => {
          setEditNotesSidebar(false)
          setDatas(response.data.data)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="mb-3">
        <div className="px-3 py-4 bg-white">
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <a
              onClick={() => setNotesSidebar(true)}
              className="btn btn-purple  fix-btn"
            >
              <i className="fas fa-plus me-2"></i>
              <span>Note</span>
            </a>
            <a onClick={() => navigate(-1)} className="btn btn-red  fix-btn">
              <i className="dripicons-cross me-2 icon-center"></i>
              <span>Cancel</span>
            </a>
          </div>
          <div className="d-flex justify-content-between">
            <h5 className="text-dark">Add Follow Up</h5>
            <div className="text-end">
              <a
                onClick={() => setNotesSidebar(true)}
                className=" btn btn-save btn-label "
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
                FollowUp
              </a>
              <a
                onClick={() => navigate(-1)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>
          </div>
          {/* Add Notes Sidebar */}
          <div
            className={`notes-sidebar ${
              notesSidebar ? "show-notes-sidebar" : ""
            }`}
          >
            <Form onSubmit={addNotes}>
              <Row className="align-items-center ">
                <div className="col-12 d-flex justify-content-between">
                  <h5>Add New FollowUp</h5>
                  <div className="text-end">
                    <button className="btn btn-purple  me-2">
                      <i className="fas fa-plus me-2"></i>Add FollowUp
                    </button>
                    <a
                      onClick={() => setNotesSidebar(false)}
                      className="btn btn-red text-uppercase "
                    >
                      <i className="dripicons-cross me-1 icon-center"></i>Close
                    </a>
                  </div>
                </div>
                <div className="col-md-4 mt-3 notes">
                  <label htmlFor="touch">
                    FollowUp Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    required
                    value={notedate}
                    onChange={e => setNotedate(e.target.value)}
                    className="form-control "
                  />
                </div>
                <div className="col-md-4 mt-4">
                  <label htmlFor="">Assigned</label>
                  <Select
                    onChange={e => setAssigned(e)}
                    placeholder="Select"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    value={assign}
                    styles={colourStyles}
                    isMulti
                    options={assigned?.map(obj => ({
                      label: obj.name,
                      value: obj.id,
                    }))}
                  />
                </div>

                <div className="col-12 mt-4">
                  <label htmlFor="">Purpose</label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Purpose"
                    onChange={e => setDescription(e.target.value)}
                    style={{ height: "65px" }}
                  ></textarea>
                </div>
              </Row>
            </Form>
          </div>

          <div className="mt-4">
            <ul className="verti-timeline list-unstyled notes-box">
              {data?.map((note, key) => {
                const idArrays = note?.assign_user?.split(",").map(Number)
                const filteredArray = assign_option.filter(item =>
                  idArrays?.includes(item.value)
                )
                const resultString = filteredArray
                  .map(item => item.label)
                  .join(", ")
                return (
                  <li key={key} className="event-list position-relative">
                    <div className="event-timeline-dot">
                      <img
                        src={`https://mars.zithas.com/application/public/${note.user_avatar}`}
                        className="userImg me-3 "
                        alt=""
                      />
                    </div>
                    <div className="d-flex">
                      <div className="flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10">
                        <div className=" d-flex justify-content-between ">
                          <div>
                            <p className="text-muted ">
                              <h6 className="d-inline me-2">
                                By {note.added_by}
                              </h6>{" "}
                              {moment(note.created_at).format(
                                "DD-MM-YYYY hh:mm:ss"
                              )}
                              <span className="pl-15">
                                {" "}
                                Follow Up at{" "}
                                {moment(note.date_time).format("DD-MM-YYYY")}
                              </span>
                            </p>
                          </div>
                          <div className="me-4">
                            <a
                              onClick={() => {
                                setEditNotesSidebar(true)
                                setFollowId(note.id)
                                setEditDescription(note.description)
                                const idArray = note.assign_user
                                  .split(",")
                                  .map(Number)
                                setEditAssign(
                                  assign_option.filter(obj =>
                                    idArray.includes(obj.value)
                                  )
                                )

                                setEditDate(note.date_time)
                              }}
                              className="btn btn-sm edit-delete-btn text-success me-2"
                            >
                              <i className="fas fa-edit" title="Edit"></i>
                            </a>
                            <button
                              onClick={e => {
                                deleteNote(e, note.id)
                              }}
                              className="btn btn-sm edit-delete-btn text-danger"
                            >
                              <i
                                className="fas fa-trash-alt"
                                title="Delete"
                              ></i>
                            </button>
                          </div>
                        </div>
                        <i className="bi bi-triangle-fill note-icon"></i>

                        <div
                          className="Features"
                          dangerouslySetInnerHTML={{
                            __html: note.description,
                          }}
                        />
                        <p className="mb-1">Assigned user : {resultString}</p>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
            {data.length == 0 ? <p>FollowUp is Not Addded</p> : null}
          </div>

          {/* Edit Notes Sidebar */}
          <div
            className={`notes-sidebar ${
              editNotesSidebar ? "show-notes-sidebar" : ""
            }`}
          >
            <form onSubmit={editNotes}>
              <Row className="align-items-center justify-content-between">
                <div className="col-12 d-flex justify-content-between">
                  <h5>Edit Follow Up</h5>
                  <div className="text-end">
                    <button className="btn btn-purple  me-2">
                      <i className="fas fa-edit me-2"></i>Edit FollowUp
                    </button>
                    <a
                      onClick={() => setEditNotesSidebar(false)}
                      className="btn btn-red text-uppercase "
                    >
                      <i className="dripicons-cross me-1 icon-center"></i>
                      Close
                    </a>
                  </div>
                </div>
                <div className="col-md-4 mt-3 notes">
                  <label htmlFor="touch">
                    FollowUp Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    required
                    onChange={e => setEditDate(e.target.value)}
                    value={edit_date}
                    className="form-control "
                  />
                </div>
                <div className="col-md-4 mt-3">
                  <label htmlFor="">Assigned</label>
                  <Select
                    onChange={e => setEditAssign(e)}
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    isMulti
                    styles={colourStyles}
                    options={assign_option}
                    value={edit_assign}
                  />
                </div>
                <div className="col-md-4 mt-3 text-end"></div>
                <div className="col-12 mt-4">
                  <label htmlFor="">Purpose</label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Purpose"
                    value={edit_description}
                    onChange={e => setEditDescription(e.target.value)}
                    style={{ height: "65px" }}
                  ></textarea>
                </div>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FollowUp
