import React, { useEffect, useState } from "react"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Form,
  Input,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import India from "../../../assets/images/flags/india.png"
import Uk from "../../../assets/images/flags/uk.png"
import Select from "react-select"
import "../../../assets/css/Finance/Style/ExpenseSection/budgetExpense.css"
import addexpense from "../../../assets/images/Expenses/addExpense.jpg"
import editexpense from "../../../assets/images/Expenses/editExpense.jpg"
import viewexpense from "../../../assets/images/Expenses/viewExpense.jpg"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
} from "reactstrap"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import swal from "sweetalert"
import configure from "configure"
import Swal from "sweetalert2"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import moment from "moment"
import NewPagination from "components/Common/Newpagination"

function TableContainer({
  columns,
  setLoading,
  setAccessDenied,
  data,
  allData,
  setData,
  setAllData,
  colourStyles,
  location,
  Categories,
  payments,
  Currency,
}) {
  const [isConvertedAmount, setIsConvertedAmount] = useState(false)
  const [convertInrAmount, setConvertInrAmount] = useState("")
  const [fixedContent, setFixedContent] = useState(false)
  const [dateValue, setDateValue] = useState("")
  const [titleValue, setTitleValue] = useState("")
  const [amountValue, setAmountValue] = useState("")
  const [locationValue, setLocationValue] = useState(null)
  const [paymentViaValue, setPaymentViaValue] = useState(null)
  const [categoryValue, setCategoryValue] = useState(null)
  const [currencyValue, setCurrencyValue] = useState(null)
  const [recurring, setRecurring] = useState(0)
  const [recurringPeriod, setRecurringPeriod] = useState(null)
  const [recurringEndDate, setRecurringEndDate] = useState("")
  const [recurringOptions, setRecurringOptions] = useState([
    { value: 1, label: "1 Month" },
    { value: 2, label: "2 Month" },
    { value: 12, label: "1 Year" },
  ])
  const navigate = useNavigate()

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setAddExpense(false)
  })

  document.addEventListener("scroll", () => {
    if (window.scrollY > 180) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const handleAdd = async e => {
    e.preventDefault()
    const isRecurring = recurring == 1 ? (recurringPeriod ? true : false) : true;
    if (
      locationValue !== null &&
      paymentViaValue !== null &&
      categoryValue !== null &&
      currencyValue !== null &&
      isRecurring
    ) {
      setLoading(true)
      const formData = new FormData()
      formData.append("type", 1)
      formData.append("date", dateValue)
      formData.append("location", locationValue?.value)
      formData.append("payment_via", paymentViaValue?.value)
      formData.append("category", categoryValue?.value)
      formData.append("title", titleValue)
      formData.append("amount", amountValue)
      formData.append("currency", currencyValue?.value)
      if (isConvertedAmount) {
        formData.append("currency_rate", convertInrAmount ? convertInrAmount : 1)
      } else {
        formData.append("currency_rate", 1)
      }
      if (recurring == 1) {
        formData.append("recurrence", recurring)
        formData.append("recurrence_period", recurringPeriod?.value)
        formData.append("recurrence_date", recurringEndDate)
      }
      try {
        const response = await axios.post(
          `${configure.apiUrl}/addBudgetExpense`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (response.data.success) {
          setLoading(false)
          Swal.fire({
            icon: "success",
            text: "Budget expense added",
          }).then(() => {
            setDateValue("")
            setIsConvertedAmount(false)
            setConvertInrAmount("")
            setLocationValue([])
            setPaymentViaValue([])
            setCategoryValue([])
            setTitleValue("")
            setAmountValue("")
            setCurrencyValue([])
            setAddExpense(false)
            setAllData(response.data.data)
            setData(response.data.data?.expense)
            setRecurring(0)
            setRecurringPeriod(null)
            setRecurringEndDate("")
          })
        } else {
          setLoading(false)
          Swal.fire({
            icon: "warning",
            text: `${response.data.data}`,
          })
        }
      } catch (error) {
        console.log("Error", error)
        setLoading(false)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please fill all fields",
      })
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const [addExpense, setAddExpense] = useState(false)

  useEffect(() => {
    if (dateValue && currencyValue && amountValue) {
      if (currencyValue.label !== "INR") {
        const fetchCurrencyRate = async () => {
          setLoading(true)
          try {
            const response = await axios.get(
              `https://api.apilayer.com/fixer/${dateValue}?symbols=INR&base=${currencyValue.label}`,
              {
                headers: {
                  apikey: "M5CQtQbJko1A2CDN4tjHvF9qFTkgQIoG",
                },
              }
            )
            if (response) {
              console.log("rees", response)
              setLoading(false)
              const rate = response.data.rates.INR
              const formattedRate = rate.toFixed(2)
              setConvertInrAmount(formattedRate) // Calculate the converted amount
              console.log("Converted amount in INR:", rate * amountValue)
            } else {
              setLoading(false)
            }
          } catch (error) {
            setLoading(false)
            Swal.fire({
              icon: "warning",
              text: error.response?.data?.message || "Error fetching currency rate",
            });

            console.error("Error fetching currency rate:", error)
          }
        }
        fetchCurrencyRate()
        setIsConvertedAmount(true)
      } else {
        setIsConvertedAmount(false)
      }
    }
  }, [dateValue, currencyValue, amountValue])

  const handleAddB = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 54)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          setAddExpense(true)
        } else {
          setAccessDenied(true)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <Link
          onClick={() => handleAddB()}
          className="btn btn-save fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 icon-size label-icon"></i>
          <span>Budget</span>
        </Link>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red  fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>

      {/* <Row className="mb-4">
      </Row> */}
      <Row className="d-flex mt-4">
        <div className="d-flex col-md-8 justify-flex-start align-items-center gap-2">
          <div className="col-md-2 budget_status text-success pt-2 px-2 me-5 rounded proposal-status text-center">
            <h6>Total Budget Expense</h6>
            <h3>₹ {allData?.budgetExpense ? Number(allData?.budgetExpense)?.toFixed(2) : 0}</h3>
          </div>
          <div className="col-md-2 budget_status text-primary pt-2 px-1 rounded proposal-status text-center">
            <h6>Total Bank</h6>
            <h3>₹ {allData?.bank ? Number(allData?.bank)?.toFixed(2) : 0}</h3>
          </div>
          <div className="col-md-2 budget_status text-primary pt-2 px-2 rounded proposal-status text-center">
            <h6>Total Credit Card</h6>
            <h3>₹ {allData?.credit_card ? Number(allData?.credit_card)?.toFixed(2) : 0}</h3>
          </div>
          <div className="col-md-2 budget_status text-success pt-2 px-2 rounded proposal-status text-center">
            <h6>Other</h6>
            <h3>₹ {allData?.others ? Number(allData?.others)?.toFixed(2) : 0}</h3>
          </div>
        </div>
        <div className="col-md-4 text-end">
          <div className="">
            <button
              className=" btn btn-save btn-label"
              onClick={() => handleAddB()}
            >
              <i className="fas fa-plus me-2 label-icon"></i>
              Budget Expense
            </button>
          </div>
        </div>
      </Row>
      <Row className="m-0 d-flex justify-space-between align-items-center">
        <div className="p-0 col-md-2">
          <select
            className=" form-select mt-4"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="p-0 col-md-2">
          <div className="search-box my-xxl-0 d-inline-block ">
            <div className="position-relative  mt-4">
              <label htmlFor="search-bar-0" className="search-label">
                <span id="search-bar-0-label" className="sr-only">
                  Search this table
                </span>
                <input
                  onChange={e => setGlobalFilter(e.target.value)}
                  id="search-bar-0"
                  type="text"
                  className=" form-control rounded h-100 "
                  placeholder={` Search...`}
                  value={globalFilter || ""}
                />
              </label>
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered mt-4" {...getTableProps()}>
          <thead className={`${(fixedContent && page.length >= 10) ? "fix-header" : ""} `}>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="d-flex align-items-center">
                      <span className="flex-grow-1 text-center header-text">
                        {column.render("Header")}
                      </span>
                      {column.isShortIcon && (
                        <span
                          onClick={() =>
                            column.getSortByToggleProps().onClick()
                          }
                          className="d-flex align-items-center ms-2"
                        >
                          <i
                            title="Ascending"
                            className={`bi bi-arrow-up info-icon ${
                              column.isSorted && !column.isSortedDesc
                                ? "text-dark"
                                : ""
                            }`}
                          ></i>
                          <i
                            title="Descending"
                            className={`bi bi-arrow-down info-icon ${
                              column.isSorted && column.isSortedDesc
                                ? "text-dark"
                                : ""
                            }`}
                          ></i>
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-introducer-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr>
                <td className="text-center" colSpan={10}>
                  No Data Found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>

      {/* Add Expense Modal */}

      <Modal isOpen={addExpense} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Add Budget Expense</h5>
          <button
            type="button"
            onClick={() => {
              setAddExpense(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form
            onSubmit={handleAdd}
            className="row d-flex justify-content-center align-items-center"
          >
            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img src={addexpense} alt="Addepe" style={{ width: "90%" }} />
              </div>
            </div>

            <div className="col-md-7 d-flex flex-column ">
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-5  ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Date <span className="text-danger">*</span>
                  </Label>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      required
                      type="date"
                      className="form-control"
                      value={dateValue}
                      onChange={e => setDateValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Location <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Select Location"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={location}
                    value={locationValue}
                    onChange={selectedOption =>
                      setLocationValue(selectedOption)
                    }
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Payment Via <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Payment Via"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={payments}
                    value={paymentViaValue}
                    onChange={selectedOption =>
                      setPaymentViaValue(selectedOption)
                    }
                  />
                </div>
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Category <span className="text-danger">*</span>
                  </Label>
                  <Select
                    // value={lead_belong}
                    placeholder="Payment Category"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    // styles={colourStyles}
                    options={Categories}
                    value={categoryValue}
                    onChange={selectedOption =>
                      setCategoryValue(selectedOption)
                    }
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex flex-column justify-content-start align-items-start">
                <div className="col-md-12 mb-4 ps-2 row">
                  <div className="col-md-10">
                    <Label htmlFor="formrow-firstname-Input">
                      Title <span className="text-danger">*</span>
                    </Label>

                    <div className="d-flex justify-content-start align-items-center gap-2">
                      <Input
                        required
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                        value={titleValue}
                        onChange={e => setTitleValue(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Recurring</Label>
                    <div className="form-switch form-switch-md ">
                      <input
                        type="checkbox"
                        className="form-check-input mt-2"
                        id="customSwitchsizemd"
                        defaultChecked={recurring == 1 ? true : false}
                        onChange={e => setRecurring(e.target.checked ? 1 : 0)}
                      />
                    </div>
                  </div>
                </div>
                {recurring == 1 && (
                  <div className="col-md-12 d-flex mb-4 ps-2 justify-content-start align-items-end gap-2">
                    <>
                      {" "}
                      <div className="col-md-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Recurring Period{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Select
                          placeholder="Select recurring"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={recurringOptions}
                          value={recurringPeriod}
                          onChange={selectedOption =>
                            setRecurringPeriod(selectedOption)
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Recurring End Date{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="date"
                          className="form-control"
                          placeholder="Enter Amount"
                          value={recurringEndDate}
                          onChange={e => setRecurringEndDate(e.target.value)}
                          required
                          min={dateValue ? new Date(dateValue).toISOString().split("T")[0] : ""}
                        />
                      </div>
                    </>
                  </div>
                )}
                <div className="col-md-12 mb-4 ps-2 d-flex gap-2">
                  <div className="col-md-4">
                    <Label htmlFor="formrow-firstname-Input">
                      Currency <span className="text-danger">*</span>
                    </Label>

                    <div className="d-flex justify-content-start align-items-center gap-2">
                      <Select
                        placeholder="Select Currency"
                        className="basic-multi-select w-100"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={Currency}
                        value={currencyValue}
                        onChange={selectedOption =>
                          setCurrencyValue(selectedOption)
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <Label htmlFor="formrow-firstname-Input">
                      Amount <span className="text-danger">*</span>
                    </Label>

                    <div className="d-flex justify-content-start align-items-center gap-2">
                      <Input
                        required
                        type="text"
                        className="form-control"
                        placeholder="Enter Amount"
                        style={{ width: "100%" }}
                        value={amountValue}
                        onChange={e => setAmountValue(e.target.value)}
                      />
                    </div>
                  </div>

                  {isConvertedAmount && (
                    <div className="col-md-3">
                      <Label htmlFor="formrow-firstname-Input">
                        Exchange Rate
                      </Label>

                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          type="text"
                          className="form-control"
                          // placeholder="Enter GST"
                          readOnly
                          style={{ width: "100%" }}
                          value={convertInrAmount}
                          // onChange={e => setConvertInrAmount(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="my-3 text-center pe-5 col-md-12">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>
                <a
                  onClick={() => setAddExpense(false)}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

const BudgetExpense = () => {

  const [isSelectDisabled, setIsSelectDisabled] = useState(true)
  const [isConvertedAmount, setIsConvertedAmount] = useState(false)
  const [convertInrAmount, setConvertInrAmount] = useState("")

  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: state.isDisabled ? "gray" : "white",
    }),
  }

  //meta title
  document.title = "Budget Expense | Zithas Crm"

  const [data, setData] = useState([])
  const [allData, setAllData] = useState(null)

  const [isLoading, setLoading] = useState(false)

  const [modal_Access, setAccessDenied] = useState(false)

  const [editExpense, setEditExpense] = useState(false)
  const [viewExpense, setViewExpense] = useState(false)
  const [viewBudgetData, setViewBudgetData] = useState(null)

  const [editId, setEditId] = useState("")
  const [updatedDate, setUpdatedDate] = useState(null)
  const [updatedLocation, setUpdatedLocation] = useState(null)
  const [updatedPayemntVia, setUpdatedPayemntVia] = useState(null)
  const [updatedCategory, setUpdatedCategory] = useState(null)
  const [updatedTitle, setUpdatedTitle] = useState(null)
  const [updatedAmount, setUpdatedAmount] = useState(null)
  const [updatedCurrency, setUpdatedCurrency] = useState(null)

  const [viewData, setViewtData] = useState([])
  const [location, setLocation] = useState([])
  const [Categories, setCategories] = useState([])
  const [Currency, setCurrency] = useState([])
  const [updateRecurring, setupdateRecurring] = useState(0)
  const [updateRecurringPeriod, setUpdateRecurringPeriod] = useState(null)
  const [updateRecurringEndDate, setUpdateRecurringEndDate] = useState("")
  const [recurringOptions, setRecurringOptions] = useState([
    { value: 1, label: "1 Month" },
    { value: 2, label: "2 Month" },
    { value: 12, label: "1 Year" },
  ])
  const [viewRecurring, setViewRecurring] = useState(0)
  const [viewRecurringPeriod, setViewRecurringPeriod] = useState(null)
  const [viewRecurringEndDate, setViewRecurringEndDate] = useState("")

  const payments = [
    {
      label: "Bank Account",
      value: "1",
    },
    {
      label: "Credit Card",
      value: "2",
    },
    {
      label: "Others",
      value: "3",
    },
  ]

  document.addEventListener("keydown", e => {
    if (e.key == "Escape")
        setEditExpense(false),
        setViewExpense(false)
  })
  // =========== Fetch Listing View Data ===========
  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/budgetExpense/1`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        console.log("Budget Data", response)
        setData(response.data.data?.expense)
        setAllData(response?.data?.data)
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const fetchOptions = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/expenseData`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        const modifyLocation = response?.data?.data?.location?.map(items => {
          return {
            label: items.location,
            value: items.id,
          }
        })
        const modifyCategories = response?.data?.data?.expense_category?.map(
          items => {
            return {
              label: items.categoryname,
              value: items.id,
            }
          }
        )
        const modifyModes = response?.data?.data?.modes?.map(items => {
          const labelD = items.payment_via == 1 ? "Bank Account" : items.payment_via == 2 ? "Credit Card" : items.payment_via == 3 ? "Others" : ""
          return {
            label: `${labelD} - ${items.payment_mode}`	,
            value: items.id,
          }
        })
        // const listModes = [...modifyModes, { label: "Other", value: "0" }]
        const modifyCurrency = response?.data?.data?.currency?.map(items => {
          return {
            label: items.currency,
            value: items.id,
          }
        })

        setCurrency(modifyCurrency)
        // setPayments(modifyModes)
        setLocation(modifyLocation)
        setCategories(modifyCategories)
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchOptions()
  }, [])
  useEffect(() => {
    fetchData()
  }, [])
  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
        isShortIcon: true,
      },
      {
        Header: () => {
          return <div style={{ textAlign: "center" }}>Location</div>
        },
        accessor: "location",
        grow: false,
        isShortIcon: true,
        className: "location-box",
        Cell: cellProps => {
          return (
            <div className="text-center d-flex justify-content-center align-items-center">
              {cellProps.row.original.location == "1" ? (
                <img
                  src={India}
                  className=" mx-1 location-img text-center"
                  alt="flag"
                  height="20"
                  width="30"
                />
              ) : (
                <img
                  src={Uk}
                  className=" mx-1 location-img"
                  alt="flag"
                  height="20"
                  width="30"
                />
              )}
            </div>
          )
        },
        isInfoIcon: true,
      },
      {
        Header: () => {
          return <div style={{ textAlign: "center" }}>Date</div>
        },
        accessor: "date",
        isInfoIcon: true,
        isShortIcon: true,
        Cell: row => (
          <div style={{ textAlign: "center" }}>
            {moment(row.value).format("DD-MM-YYYY")}
          </div>
        ),
      },

      {
        Header: "Title",
        accessor: "title",
        isInfoIcon: true,
        isShortIcon: true,
      },

      {
        Header: "Category",
        accessor: "category_name",
        isShortIcon: true,
        isInfoIcon: true,
        Cell: row => <div>{row.value}</div>,
      },
      {
        Header: "Amount",
        accessor: "amount",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center",
        Cell : cellProps => {
          const currencyIdToCode = {
            1: "GBP",
            2: "INR",
            3: "CAD",
            4: "USD",
            5: "AUD",
            6: "AED",
            7: "SGD",
          }
          const currencySymbols = {
            GBP: "£",
            INR: "₹",
            CAD: "CA$",
            USD: "$",
            AUD: "A$",
            AED: "د.إ",
            SGD: "S$",
          }
          const { currency } = cellProps.row.original
          const currencyCode = currencyIdToCode[currency]
          const symbol = currencySymbols[currencyCode] || ""
          return (
            <>
            {symbol} {Number(cellProps.row.original.amount).toFixed(2)}
            </>
          )
        }
      },
      {
        Header: () => {
          return <div style={{ textAlign: "center" }}>Action</div>
        },
        accessor: "action",
        classNames: "text-center",
        
        Cell: cellProps => {
          const isDelete = async () => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 54)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                setLoading(false)
                if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You want to delete this expense !",
                    icon: "warning",
                    showCancelButton: true,
                  }).then(async willDelete => {
                    if (willDelete.isConfirmed) {
                      setLoading(true)
                      try {
                        const response = await axios({
                          method: "get",
                          url: `${configure.apiUrl}/deleteBudgetExpense/${cellProps.row.original.id}/1`,
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                          },
                        }).then(response => {
                          Swal.fire({
                            icon: "success",
                            text: "Expense deleted successfully",
                          }).then(data => {
                            setData(response.data.data?.expense)
                            setAllData(response.data.data)
                          })
                          setLoading(false)
                        })
                      } catch (err) {
                        swal({
                          icon: "error",
                          text: "Something went wrong",
                        })
                        setLoading(false)
                      }
                    }
                  })
                } else {
                  setLoading(false)
                  setAccessDenied(true)
                }
              })
            } catch (error) {
              console.log(error)
            }
          }

          const handleSubmitEdit = async viewData => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 54)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                setLoading(false)
                if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
                  const locationFind = location?.find(
                    obj => obj.value == viewData.location
                  )
                  const paymentmethod = payments?.find(
                    obj => obj.value == viewData.payment_method
                  )
                  const categoryname = Categories?.find(
                    obj => obj.value == viewData.category_id
                  )
                  const currencyEdit = Currency?.find(
                    obj => obj.value == cellProps.row.original.currency
                  )
                  const recurrenceOp = recurringOptions?.find(
                    obj => obj.value == cellProps.row.original.recurrence_period
                  )
                  setEditId(cellProps.row.original.id)
                  setUpdatedDate(viewData.date)
                  setUpdatedLocation(locationFind)
                  setUpdatedPayemntVia(paymentmethod)
                  setUpdatedCategory(categoryname)
                  setUpdatedTitle(cellProps.row.original.title)
                  setUpdatedAmount(cellProps.row.original.amount)
                  setUpdatedCurrency(currencyEdit)
                  setupdateRecurring(cellProps.row.original.recurrence)
                  setUpdateRecurringPeriod(recurrenceOp)
                  setUpdateRecurringEndDate(
                    cellProps.row.original.recurrence_date
                  )
                  setEditExpense(true)
                }
              })
            } catch (error) {
              console.log(error)
            }
          }

          const handleView = viewData => {
            console.log("viewData01", viewData)
            const payment_method = payments?.find(
              obj => obj.value == viewData.payment_method
            )
            const category_name = Categories?.find(
              obj => obj.value == viewData.category_id
            )
            const currency = Currency?.find(
              obj => obj.value == cellProps.row.original.currency
            )
            const recurrenceOp = recurringOptions?.find(
              obj => obj.value == cellProps.row.original.recurrence_period
            )
            const modifyArr = {
              date: viewData.date,
              location: location?.find(obj => obj.value == viewData.location),
              amount: cellProps.row.original.amount,
              paymentMethod: payment_method,
              categoryName: category_name,
              currency: currency,
              title: cellProps.row.original.title,
              currency_rate: cellProps.row.original.currency_rate,
            }
            setViewtData(modifyArr)
            setViewBudgetData(modifyArr)
            setViewRecurring(cellProps.row.original.recurrence)
            setViewRecurringPeriod(recurrenceOp)
            setViewRecurringEndDate(cellProps.row.original.recurrence_date)
            setViewExpense(true)
          }

          const handleConvert = async id => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 54)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                setLoading(false)
                if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
                  Swal.fire({
                    icon: "warning",
                    title: "Are you sure ?",
                    text: "You want to convert this budget expense to an expense.",
                    showCancelButton: true,
                  }).then(async willOkay => {
                    if (willOkay.isConfirmed) {
                      setLoading(true)
                      const formData = new FormData()
                      formData.append("type", 1)
                      formData.append("expense_id", id)
                      try {
                        const resp = await axios({
                          method: "post",
                          url: `${configure.apiUrl}/convertToExpense`,
                          data: formData,
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                          },
                        })

                        if (resp) {
                          setLoading(false)
                          Swal.fire({
                            icon: "success",
                            text: "This budget expense converted to expense !",
                          }).then(() => {
                            setData(resp.data.data?.expense)
                            setAllData(resp.data.data)
                          })
                        } else {
                          setLoading(false)
                          Swal.fire({
                            icon: "warning",
                            text: `${resp.data.data}`,
                          })
                        }
                      } catch (error) {
                        setLoading(false)
                        console.log("Error", error)
                      }
                    }
                  })
                } else {
                  setAccessDenied(true)
                }
              })
            } catch (error) {
              setLoading(false)
              console.log(error)
            }
          }

          return (
            <div className="d-flex justify-content-center">
              <button
                onClick={() => {
                  handleView(cellProps.row.original)
                }}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-eye"></i>
              </button>
              <button
                onClick={() => handleSubmitEdit(cellProps.row.original)}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fa fa-pencil-alt"></i>
              </button>

              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  {cellProps.row.original.status == 0 && (
                    <Link
                      onClick={() => handleConvert(cellProps.row.original.id)}
                      title="edit lead"
                      className="dropdown-item"
                    >
                      <i className="bi bi-currency-dollar pr-10 fill-green"></i>{" "}
                      Convert to Expense
                    </Link>
                  )}

                  <a
                    onClick={() => isDelete()}
                    className="dropdown-item"
                    title="delete"
                  >
                    <i className="fas fa-trash-alt pr-10 text-danger"></i>{" "}
                    Delete Expense
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [Categories, Currency]
  )

  const handelEdit = async e => {
    e.preventDefault()
    const isRecurring = updateRecurring == 1 ? (updateRecurringPeriod ? true : false) : true;
    if(isRecurring) {
      setLoading(true)
      const formData = new FormData()
      formData.append("type", 1)
      formData.append("date", updatedDate)
      formData.append("location", updatedLocation?.value)
      formData.append("payment_via", updatedPayemntVia?.value)
      formData.append("category", updatedCategory?.value)
      formData.append("title", updatedTitle)
      formData.append("amount", updatedAmount)
      formData.append("currency", updatedCurrency?.value)
      formData.append("expense_id", editId)
      if (isConvertedAmount) {
        formData.append("currency_rate", convertInrAmount ? convertInrAmount  : 1)
      } else {
        formData.append("currency_rate", 1)
      }
      if (updateRecurring == 1) {
        formData.append("recurrence", updateRecurring)
        formData.append("recurrence_period", updateRecurringPeriod?.value)
        formData.append("recurrence_date", updateRecurringEndDate)
      }  else {
        formData.append("recurrence", 0)
        formData.append("recurrence_period", "")
        formData.append("recurrence_date", "")
      }
      try {
        const resp = await axios({
          method: "post",
          url: `${configure.apiUrl}/editBudgetExpense`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (resp) {
          setLoading(false)
          Swal.fire({
            icon: "success",
            text: "Expense edited successfully",
          }).then(() => {
            const respData = resp.data.data?.expense
            setData(respData)
            setAllData(resp.data.data)
            setEditExpense(false)
          })
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please fill all fields",
      })
    }
  }

  useEffect(() => {
    if (updatedDate && updatedCurrency && updatedAmount) {
      if (updatedCurrency.label !== "INR") {
        const fetchCurrencyRate = async () => {
          setLoading(true)
          try {
            const response = await axios.get(
              `https://api.apilayer.com/fixer/${updatedDate}?symbols=INR&base=${updatedCurrency.label}`,
              {
                headers: {
                  apikey: "M5CQtQbJko1A2CDN4tjHvF9qFTkgQIoG",
                },
              }
            )
            if (response) {
              setLoading(false)
              const rate = response.data.rates.INR
              const formattedRate = rate.toFixed(2)
              setConvertInrAmount(formattedRate) // Calculate the converted amount
              console.log("Converted amount in INR:", rate * updatedAmount)
            } else {
              setLoading(false)
            }
          } catch (error) {
            setLoading(false)
            Swal.fire({
              icon: "warning",
              text: error.response?.data?.message || "Error fetching currency rate",
            });
            console.error("Error fetching currency rate:", error)
          }
        }
        fetchCurrencyRate()
        setIsConvertedAmount(true)
      } else {
        setIsConvertedAmount(false)
      }
    }
  }, [updatedDate, updatedCurrency, updatedAmount])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content" id="budget-expense">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Budget Expense"} />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setData={setData}
                      allData={allData}
                      setAllData={setAllData}
                      setAccessDenied={setAccessDenied}
                      setLoading={setLoading}
                      colourStyles={colourStyles}
                      location={location}
                      Categories={Categories}
                      payments={payments}
                      Currency={Currency}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Edit MOdal  */}
      <Modal isOpen={editExpense} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Edit Budget Expense</h5>
          <button
            type="button"
            onClick={() => {
              setEditExpense(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form
            onSubmit={handelEdit}
            className="row d-flex justify-content-center align-items-center"
          >
            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img
                  src={editexpense}
                  alt="Edit Budget Expense"
                  style={{ width: "90%" }}
                />
              </div>
            </div>

            <div className="col-md-6 d-flex flex-column ">
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-5  ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Date <span className="text-danger">*</span>
                  </Label>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      type="date"
                      className="form-control"
                      defaultValue={updatedDate}
                      onChange={e => setUpdatedDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Location <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Select Location"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={location}
                    value={updatedLocation}
                    onChange={selectedOption =>
                      setUpdatedLocation(selectedOption)
                    }
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Payment Via <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Payment Via"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={payments}
                    value={updatedPayemntVia}
                    onChange={selectedOption =>
                      setUpdatedPayemntVia(selectedOption)
                    }
                  />
                </div>
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Category <span className="text-danger">*</span>
                  </Label>
                  <Select
                    value={updatedCategory}
                    placeholder="Payment Category"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={Categories}
                    onChange={selectedOption =>
                      setUpdatedCategory(selectedOption)
                    }
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex flex-column justify-content-start align-items-start">
                <div className="col-md-12 mb-4 ps-2 row">
                  <div className="col-md-10">
                    <Label htmlFor="formrow-firstname-Input">
                      Title <span className="text-danger">*</span>
                    </Label>

                    <div className="d-flex justify-content-start align-items-center gap-2">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                        value={updatedTitle}
                        onChange={e => setUpdatedTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Recurring</Label>
                    <div className="form-switch form-switch-md ">
                      <input
                        type="checkbox"
                        className="form-check-input mt-2"
                        id="customSwitchsizemd"
                        defaultChecked={updateRecurring == 1 ? true : false}
                        onChange={e =>
                          setupdateRecurring(e.target.checked ? 1 : 0)
                        }
                      />
                    </div>
                  </div>
                </div>
                {updateRecurring == 1 && (
                  <div className="col-md-12 d-flex ps-2 mb-4 justify-content-start align-items-end gap-2">
                    <>
                      {" "}
                      <div className="col-md-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Recurring Period{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Select
                          placeholder="Select recurring"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={recurringOptions}
                          value={updateRecurringPeriod}
                          onChange={selectedOption => {
                            Swal.fire({
                              icon: "warning",
                              title: "Are you sure ?",
                              text: "You want to change the recurring period.",
                              showCancelButton: true
                            }).then((willOkay)=> {
                              if(willOkay.isConfirmed) {
                                setUpdateRecurringPeriod(selectedOption)
                              }
                            })
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Recurring End Date{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                         required
                          type="date"
                          className="form-control"
                          placeholder="Enter Amount"
                          value={updateRecurringEndDate}
                          onChange={e =>
                            setUpdateRecurringEndDate(e.target.value)
                          }
                          min={updatedDate ? new Date(updatedDate).toISOString().split("T")[0] : ""}
                        />
                      </div>
                    </>
                  </div>
                )}
                <div className="col-md-12 mb-4 ps-2 d-flex gap-2">
                  <div className="col-md-4">
                    <Label htmlFor="formrow-firstname-Input">
                      Currency <span className="text-danger">*</span>
                    </Label>

                    <div className="d-flex justify-content-start align-items-center gap-2">
                      <Select
                        placeholder="Select Currency"
                        className="basic-multi-select w-100"
                        classNamePrefix=" select"
                        options={Currency}
                        style={{ width: "100%" }}
                        value={updatedCurrency}
                        // onChange={handleEditChanage}
                        onChange={selectedOption =>
                          setUpdatedCurrency(selectedOption)
                        }

                        // value={currencyValue}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <Label htmlFor="formrow-firstname-Input">
                      Amount <span className="text-danger">*</span>
                    </Label>

                    <div className="d-flex justify-content-start align-items-center gap-2">
                      <Input
                        type="text"
                        className="form-control"
                        // id="formrow-firstname-Input"
                        placeholder="Enter Amount"
                        style={{ width: "100%" }}
                        value={updatedAmount}
                        onChange={e => setUpdatedAmount(e.target.value)}
                      />
                    </div>
                  </div>

                  {isConvertedAmount && (
                    <div className="col-md-3">
                      <Label htmlFor="formrow-firstname-Input">
                        Exchange Rate 
                      </Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          type="text"
                          className="form-control"
                          readOnly
                          style={{ width: "100%" }}
                          value={convertInrAmount}
                          onChange={e => setConvertInrAmount(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="my-4 pe-5 text-center col-md-12">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>
                <a
                  onClick={() => setEditExpense(false)}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      {/* View Expense Modal */}

      <Modal isOpen={viewExpense} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">View Budget Expense</h5>
          <button
            type="button"
            onClick={() => {
              setViewExpense(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form
            // onSubmit={handleViewData}
            className="row d-flex justify-content-center align-items-center"
          >
            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img
                  src={viewexpense}
                  alt="View Budget Expense"
                  style={{ width: "90%" }}
                />
              </div>
            </div>

            <div className="col-md-6 d-flex flex-column ">
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-5  ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Date <span className="text-danger">*</span>
                  </Label>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      type="date"
                      className="form-control text-muted"
                      disabled
                      value={viewBudgetData?.date}
                      // value="2024-07-16"
                    />
                  </div>
                </div>
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Location <span className="text-danger">*</span>
                  </Label>
                  <Select
                    isDisabled={isSelectDisabled}
                    // value={lead_belong}
                    placeholder="Select Location"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={location}
                    value={viewBudgetData?.location}
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Payment Via <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control text-muted"
                    // id="formrow-firstname-Input"
                    placeholder="Payment Via"
                    style={{ width: "100%" }}
                    disabled
                    value={viewBudgetData?.paymentMethod?.label}
                  />
                </div>
                <div className="col-md-5 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Category <span className="text-danger">*</span>
                  </Label>
                  <Select
                    isDisabled={isSelectDisabled}
                    // value={lead_belong}
                    placeholder="Payment Category"
                    className="basic-multi-select text-muted"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={Categories}
                    value={viewBudgetData?.categoryName}
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex flex-column justify-content-start align-items-start">
                <div className="col-md-12 mb-4 ps-2 row">
                  <div className="col-md-10">
                    <Label htmlFor="formrow-firstname-Input">
                      Title <span className="text-danger">*</span>
                    </Label>

                    <div className="d-flex justify-content-start align-items-center gap-2">
                      <Input
                        type="text"
                        className="form-control text-muted"
                        placeholder="Enter Title"
                        disabled
                        value={viewBudgetData?.title}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 mb-2">
                    <Label htmlFor="formrow-firstname-Input">Recurring</Label>
                    <div className="form-switch form-switch-md ">
                      <input
                        type="checkbox"
                        className="form-check-input mt-2"
                        id="customSwitchsizemd"
                        defaultChecked={viewRecurring == 1 ? true : false}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {viewRecurring == 1 && (
                  <div className="col-md-12 d-flex ps-2 mb-4 justify-content-start align-items-end gap-2">
                    <>
                      {" "}
                      <div className="col-md-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Recurring Period{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Select
                          placeholder="Select recurring"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={recurringOptions}
                          value={viewRecurringPeriod}
                          isDisabled={true}
                        />
                      </div>
                      <div className="col-md-4">
                        <Label htmlFor="formrow-firstname-Input">
                          Recurring End Date{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="date"
                          className="form-control"
                          placeholder="Enter Amount"
                          value={viewRecurringEndDate}
                          disabled={true}
                        />
                      </div>
                    </>
                  </div>
                )}
                <div className="col-md-12 mb-4 ps-2 d-flex gap-2">
                  <div className="col-md-4">
                    <Label htmlFor="formrow-firstname-Input">
                      Currency <span className="text-danger">*</span>
                    </Label>

                    <div className="d-flex justify-content-start align-items-center gap-2">
                      <Select
                        isDisabled="true"
                        placeholder="Select Currency"
                        className="basic-multi-select w-100"
                        classNamePrefix=" select"
                        options={Currency}
                        styles={colourStyles}
                        value={viewBudgetData?.currency}

                        // value={currencyValue}
                        // onChange={(selectedOption)=> setCurrencyValue(selectedOption)}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <Label htmlFor="formrow-firstname-Input">
                      Amount <span className="text-danger">*</span>
                    </Label>

                    <div className="d-flex justify-content-start align-items-center gap-2">
                      <Input
                        type="text"
                        className="form-control text-muted"
                        // id="formrow-firstname-Input"
                        placeholder="Enter Amount"
                        style={{ width: "100%" }}
                        disabled
                        value={viewBudgetData?.amount}
                      />
                    </div>
                  </div>

                  {viewBudgetData?.currency_rate && (
                    <div className="col-md-3">
                      <Label htmlFor="formrow-firstname-Input">
                        Exchange Rate
                      </Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          type="text"
                          className="form-control"
                          style={{ width: "100%" }}
                          value={viewBudgetData?.currency_rate}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* </div> */}
          </Form>
        </div>
      </Modal>

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default BudgetExpense
