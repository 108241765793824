const configure = {
  apiUrl: "https://api.zithas.com/api",
  imgUrl: "https://mars.zithas.com/application/public",
  appUrl: "/",
  // type: 1, // 192
  type: 2, // Earth

  // apiUrl: "https://mars.ztpl.net/api",
  //  apiUrl: "http://192.168.0.2/earthbms/admin/api",
}

export default configure
