import React, { useEffect, useState } from "react"
import "../../../../assets/css/Staff/Staff.css"
import "../../../../assets/css/Credential/Credential.css"
import "../../../../assets/css/Scollbar.css"
import "../../../../assets/css/Work/projects.css"
import "../../../../assets/css/Settings/generalSetting.css"
import "../../../../assets/css/Settings/digitalMarketing.css"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Collapse,
} from "reactstrap"
import classnames from "classnames"
import SimpleBar from "simplebar-react"
import GeneralTaskTab from "./GeneralTaskTab"
import { RelatedToOption } from "utils/common-options"
import { fetchData } from "utils/common-fetchData"
import secureLocalStorage from "react-secure-storage"
import Loader from "pages/Separate/Loader"

const GeneralTaskSetting = ({ verticalActiveTabs }) => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [isCollapse, setIsCollapse] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [related, setRelated] = useState("human-resource")
  document.title = "General Task Setting | Zithas Crm"
  const [fixedSideTab, setFixedSideTab] = useState(false)
  const [isIndex, setIsIndex] = useState(false)
  const handleHover = () => {
    setIsIndex(true)
  }
  const handleLeave = () => {
    setIsIndex(false)
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })
  const toggleLoading = (loading) => {
    setLoading(loading)
  }

  useEffect(() => {
    const fetchDatas = async () => {
      const response = await fetchData(toggleLoading, "subtasks", secureLocalStorage?.getItem('auth_token'));
      if (response?.success) {
        setData(response?.data)
      }
    }
    if (verticalActiveTabs == "4") {
      fetchDatas();
    }
  }, [verticalActiveTabs])

  const updateData = (datas) => {
    setData(datas)
  }


  return (
    <React.Fragment>
      {isLoading && (<Loader />)}
      <div className=" mb-4">
        <Container fluid>
          <div id="credentialPage">
            <Row
              className={`justify-content-between di-flex align-items-top `}
              id="cred"
            >
              <div
                className={`px-0 digital_marketing_sidebar ${fixedSideTab && "credtabFix"
                  }`}
                style={isIndex ? { zIndex: "2" } : {}}
              >
                <div className="position-relative">
                  <div>
                    <SimpleBar
                      style={{ height: "70vh" }}
                      className={`${fixedSideTab && "fix-simplebar"}`}
                    >
                      <Nav
                        pills
                        className={`credtabFix-ul gtSidebar ${fixedSideTab && ""
                          }  flex-column px-0 py-0 h-100`}
                      >
                        {RelatedToOption?.map((rel, i) => {
                          if (rel?.value != "other") {
                            return (
                              <NavItem
                                key={i}
                                style={{ height: "45px" }}
                              >
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    "text-center crendential": true,
                                    active: verticalActiveTab == (rel?.id)?.toString(),
                                  })}
                                  onClick={() => {
                                    setverticalActiveTab((rel.id)?.toString())
                                    setRelated(rel?.value)
                                  }}
                                  onMouseEnter={handleHover}
                                  onMouseLeave={handleLeave}
                                >
                                  {rel?.text}
                                  <span className="link_name border-start border-white border-2">
                                    {rel?.label}
                                  </span>
                                </NavLink>
                              </NavItem>
                            )
                          }
                        })}

                      </Nav>
                    </SimpleBar>
                  </div>
                </div>
              </div>
              <Col
                md={isCollapse ? "11" : "10"}
                className={`z-1 pe-0 credential-table ${isCollapse && "width-94 ps-0"
                  }`}
              >
                <div className="card p-0 mb-0 h-100">
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    {RelatedToOption?.map((rel, i) => {
                      return (
                        <TabPane tabId={(rel?.id)?.toString()} key={i}>
                          <GeneralTaskTab toggleLoading={toggleLoading} verticalActiveTab={verticalActiveTab} datas={data} related={related} updateData={updateData} />
                        </TabPane>
                      )
                    })}
                  </TabContent>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GeneralTaskSetting