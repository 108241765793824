import React, { useEffect, useState } from "react"
import {
  Container,
  Form,
  Label,
  Input,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
} from "reactstrap"

// common component
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import Swal from "sweetalert2"
import secureLocalStorage from "react-secure-storage"
import CommonTable from "utils/CommonTable"

import editGsImg from "../../../../assets/images/settingImg/editGs.jpg"
import addGsImg from "../../../../assets/images/settingImg/addGs.jpg"
import { checkPermissionsByModule } from "utils/common-helpers"
import { useSelector } from "react-redux"
import { FetchPostData } from "utils/common-fetchData"
import { RelatedToOption } from "utils/common-options"
import { TaskSettingColumns } from "utils/columns/work/workColumns"
import Pagination from "components/Common/Pagination"
import NewPagination from "components/Common/Newpagination"



function TableContainer({
  columns,
  data,
  setAddTask,
}) {
  const [fixedContent, setFixedContent] = useState(false)
  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])

  return (
    <div>

      {/* fixed buttons */}

      <div className="col-12 d-flex mb-3 position-relative " style={{ zIndex: "999" }}>
        <div className="col-6 d-flex justify-content-start align-items-center gap-4">
          <div className="col-2 d-flex justify-content-start align-items-center">
            <select
              className="s me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>

        </div>

        <div className="col-6 d-flex justify-content-end align-items-center gap-2">
          <div className="col-6 d-flex justify-content-start align-items-center mt-2">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>

          <Link
            onClick={() => setAddTask(true)}
            className="s btn text-white me-0 btn-save btn-label"
          >
            <i className="fas fa-plus me-2 icon-size label-icon"></i>Add Task
          </Link>
        </div>
      </div>

      <div className="position-relative">
        <CommonTable
          getTableProps={getTableProps}
          fixedContent={fixedContent}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          page={page}
          prepareRow={prepareRow}
          ClassName="generalTaskSetting"
        />
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
          nextPage={nextPage}
        />
      </Row>
    </div>
  )
}

const GeneralTaskTab = ({ toggleLoading, verticalActiveTab, datas, related, updateData }) => {
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  const [daysRemaining, setDaysRemaining] = useState(false)
  const [formRows, setFormRows] = useState([{}])
  const [data, setData] = useState([])
  const [modal_Access, setAccessDenied] = useState(false)
  const [addTask, setAddTask] = useState(false)
  const [EditTask, setEditTask] = useState(false)
  const [title, setTile] = useState('');
  const [edit_title, setEditTitle] = useState('');
  const [taskId, setTaskId] = useState('')
  useEffect(() => {
    setData(datas?.filter((obj) => obj.related == related))
  }, [datas, verticalActiveTab])


  const onAddFormRow = () => {
    setFormRows([...formRows, {}])
  }

  const onDeleteFormRow = index => {
    const newInputs = [...formRows]
    newInputs.splice(index, 1)
    setFormRows(newInputs)
  }

  const handleInputChange = (key, event) => {
    const { name, value } = event?.target
    const updatedFormRows = formRows.map((row, index) =>
      index === key ? { ...row, [name]: value } : row
    )
    setFormRows(updatedFormRows)
  }

  document.addEventListener("keydown", e => {
    if (e.key === "Escape")
      setmodal_fullscreen(false), setAddTask(false), setDaysRemaining(false)
  })

  const [boxWidth, setBoxWidth] = useState(0)
  const roles = useSelector(state => state?.Roles?.apiData)
  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.65)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  const onEditTask = (e) => {
    setEditTask(true)
    setEditTitle(e?.task)
    const subtask = e?.subtasks?.split('#@')
    if (subtask !== undefined) {
      setFormRows(subtask?.map((obj) => ({
        task_name: obj
      })))
    }
    setTaskId(e.id)
  }
  // Add Task Api
  const addGeneralTask = async (e) => {
    e.preventDefault();
    // const permission = checkPermissionsByModule(roles, 73, 3);
    // console.log(permission)
    // if (permission) {
    const formData = {
      related: RelatedToOption?.find((rel) => rel.id == verticalActiveTab)?.value,
      task: title,
      subtask: formRows?.map((obj) => obj?.task_name)?.join('#@')
    }
    const response = await FetchPostData(toggleLoading, "addSubTasks", secureLocalStorage?.getItem('auth_token'), formData);
    if (response?.data?.success) {
      Swal.fire({
        icon: "success",
        text: "Task added successfully",
      }).then(data => {
        setAddTask(false);
        setData(response?.data?.data?.filter((obj) => obj.related == related))
        updateData(response?.data?.data)
        setTile('')
        setFormRows([{}])
      })
    }
    // }
  }
  // Edit Task Api
  const editGeneralTask = async (e) => {
    e.preventDefault();
    // const permission = checkPermissionsByModule(roles, 73, 4);
    // if (permission) {
    const formData = {
      related: RelatedToOption?.find((rel) => rel.id == verticalActiveTab)?.value,
      task: edit_title,
      subtask: formRows?.map((obj) => obj?.task_name)?.join('#@'),
      task_id: taskId
    }
    const response = await FetchPostData(toggleLoading, "editSubTasks", secureLocalStorage?.getItem('auth_token'), formData);
    if (response?.data?.success) {
      Swal.fire({
        icon: "success",
        text: "Task edited successfully",
      }).then(data => {
        setEditTask(false)
        setData(response?.data?.data?.filter((obj) => obj.related == related))
        updateData(response?.data?.data)
        setEditTitle('')
        setFormRows([{}])
      })
    }
    // }
  }
  // Delete Task Api
  const onDelete = async (id) => {
    // const permission = checkPermissionsByModule(roles, 73, 5);
    // if (permission) {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete this task!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formDatas = {
          task_id: id
        }
        const response = await FetchPostData(toggleLoading, "deleteSubTasks", secureLocalStorage.getItem('auth_token'), formDatas)
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            text: "Task deleted successfully",
          })
          updateData(response?.data?.data)
          setData(response?.data?.data?.filter((obj) => obj.related == related))
        }
      }
    });
    // } else {
    //   setAccessDenied(true)
    // }

  }

  const columns1 = useMemo(
    () =>
      TaskSettingColumns(onDelete, onEditTask),
    []
  )

  return (
    <React.Fragment>
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns1}
                      data={data}
                      setData={setData}
                      setAddTask={setAddTask}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add Task  */}
      <Modal
        isOpen={addTask}
        toggle={() => {
          tog_center()
        }}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Task</h5>
          <button
            type="button"
            onClick={() => {
              setAddTask(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={addGeneralTask}>
            <Row>
              <div className="col-4">
                <img src={addGsImg} className="img-fluid" />
              </div>
              <div className="col-8 d-flex align-items-center justify-content-center flex-column gap-2">
                <h3 className="text-start text-purple mb-3">{RelatedToOption?.find((obj) => obj?.value == related)?.label} Related Task </h3>
                <div className="col-12 d-flex align-items-start justify-content-start gap-2">
                  <div className="mb-3 col-12">
                    <Label htmlFor="formrow-firstname-Input">Task Name <span className="text-danger">*</span></Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Task Name"
                      value={title}
                      onChange={(e) => setTile(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="contact-form d-flex flex-column col-md-12 p-0 outline-secondary setting_package_box">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Subtasks</h5>
                    <div>
                      <button className=" btn btn-purple btn-label me-2"
                        onClick={e => {
                          e.preventDefault()
                          onAddFormRow()
                        }}>
                        <i className="fas fa-plus me-2 icon-size label-icon"></i>
                        Subtask
                      </button>

                    </div>
                  </div>
                  {formRows?.map((person, key) => {
                    return (
                      <div key={key} className="">
                        <div className="col-12 d-flex justify-content-start align-items-center my-2">
                          <div className="contact-column edit-contact-column mb-md-0 col-11">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Subtask Name"
                              name="task_name"
                              value={person.task_name}
                              onChange={e => handleInputChange(key, e)}
                            />
                          </div>

                          <div className="col-1 contact-btns d-flex justify-content-start align-items-end mb-md-0 mt-0">
                            <button
                              onClick={e => {
                                e.preventDefault()
                                onDeleteFormRow(key)
                              }}
                              className={`btn btn-red`}
                            >
                              <i className={`fas fa-trash-alt me-0`}></i>
                            </button>
                          </div>
                        </div>
                        {key !== formRows.length - 1 ? (
                          <div className=""></div>
                        ) : null}
                      </div>
                    )
                  })}

                </div>
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className=" btn btn-purple btn-label"
              >
                <i className="fas fa-save me-2 icon-size label-icon"></i>
                Save
              </button>
              <button
                type="button"
                onClick={() => setAddTask(false)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
          </Form>
        </div>
      </Modal>

      {/* Edit Task  */}
      <Modal
        isOpen={EditTask}
        toggle={() => {
          tog_center()
        }}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Task</h5>
          <button
            type="button"
            onClick={() => {
              setEditTask(false)
              setFormRows([{}])
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={editGeneralTask}>
            <Row>
              <div className="col-4">
                <img src={editGsImg} className="img-fluid" />
              </div>
              <div className="col-8 d-flex align-items-center justify-content-center flex-column gap-2">
                <h3 className="text-start text-purple mb-3">{RelatedToOption?.find((obj) => obj?.value == related)?.label} Related Task </h3>
                <div className="col-12 d-flex align-items-start justify-content-start gap-2">
                  <div className="mb-3 col-12">
                    <Label htmlFor="formrow-firstname-Input">Task Name</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Task Name"
                      value={edit_title}
                      onChange={(e) => setEditTitle(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="contact-form d-flex flex-column col-md-12 p-0 outline-secondary setting_package_box">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Subtasks</h5>
                    <div>
                      <button className=" btn btn-purple btn-label me-2"
                        onClick={e => {
                          e.preventDefault()
                          onAddFormRow()
                        }}>
                        <i className="fas fa-plus me-2 icon-size label-icon"></i>
                        Subtask
                      </button>

                    </div>
                  </div>
                  {formRows?.map((person, key) => {
                    return (
                      <div key={key} className="">
                        <div className="col-12 d-flex justify-content-start align-items-center my-2 ">
                          <div className="contact-column edit-contact-column mb-md-0 col-11">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Subtask Name"
                              name="task_name"
                              value={person.task_name}
                              onChange={e => handleInputChange(key, e)}
                            />
                          </div>

                          <div className="col-1 contact-btns d-flex justify-content-start align-items-end mb-md-0 mt-0">
                            <button
                              onClick={e => {
                                e.preventDefault()
                                onDeleteFormRow(key)
                              }}
                              className={`btn btn-red`}
                            >
                              <i className={`fas fa-trash-alt me-0`}></i>
                            </button>
                          </div>
                        </div>
                        {key !== formRows.length - 1 ? (
                          <div className=""></div>
                        ) : null}
                      </div>
                    )
                  })}

                </div>
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className=" btn btn-purple btn-label"
              >
                <i className="fas fa-save me-2 icon-size label-icon"></i>
                Save
              </button>
              <button
                type="button"
                onClick={() => setEditTask(false)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
          </Form>
        </div>
      </Modal>


      {/* Access Denied */}
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default GeneralTaskTab
