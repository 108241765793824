// redux-persist-config.js

import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web

import rootReducer from "./reducers" // your combined reducers

const persistConfig = {
  key: "root",
  storage,
  // Optionally, you can configure other options here, such as blacklist or transforms
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer
