import moment from "moment";

export const getPermissionsByModule = (data, moduleId) => {
  const result = {};

  data.forEach(item => {
    const permissions = JSON.parse(item.permission);
    // Find the specific permission entry for the given module_id
    const permissionEntry = permissions.find(p => {
      if (typeof p === 'string') {
        const parts = p.split(',');
        return parts[0] === moduleId.toString();
      }
      return false;
    });

    const countryKey = item.country_id == 1 ? 'india' : 'uk';
    if (permissionEntry) {
      const permissionArray = permissionEntry.split(',').map(Number);
      result[countryKey] = permissionArray;
    }
  });

  return result;
};


export const checkPermissionsByModule = (data, moduleId, roleIndex) => {
  const permission = getPermissionsByModule(data, moduleId)
  return (permission?.india !== undefined && permission?.india[roleIndex] == 1) || (permission?.uk !== undefined && permission?.uk[roleIndex] == 1)
};


export const transformGeneralData = (data) => {
  const transformArray = (arr, key, label) => {
    return arr.map(item => ({
      value: item[key],
      label: item[label]
    }));
  };

  return {
    digital_projects: transformArray(data.digital_projects, 'id', 'project_name'),
    introducer: transformArray(data.introducer, 'id', 'company'),
    leads: transformArray(data.leads, 'id', 'company'),
    proposal: transformArray(data.proposal, 'id', 'proposalsubtitle'),
    projects: transformArray(data.projects, 'id', 'project_name'),
    users: transformArray(data.users, 'id', 'name'),
    staff: transformArray(data.users, 'id', 'name')
  };
};

export const sumTimes = (timeArray, key) => {
  const totalMinutes = timeArray?.reduce((total, item) => {
    const timeString = item[key];
    if (!timeString) return total;
    const [hours, minutes] = timeString.split(':').map(Number);
    if (!isNaN(hours) && !isNaN(minutes)) {
      return total + (hours * 60) + minutes;
    }
    return total;
  }, 0) || 0;

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};


export const groupAndMerge = (arrays) => {
  const grouped = {}
  arrays.forEach(item => {
    const { id, related_id, related_name, related_to } = item
    const key = `${related_id}`
    if (!grouped[key]) {
      grouped[key] = {
        id,
        related_id,
        list: [],
        related_name,
        related_to
      }
    }
    grouped[key].list.push(item)
  })

  const output = Object.values(grouped)
  return output
}

export const filterData = (dataArray, filters) => {
  return dataArray.filter(item => {
    const matchesLocation = filters.location ? filters.location == item.location : true;

    const matchesAssignee = filters.assigned?.length
      ? filters.assigned.some(assignee => item.assigned?.split(',').includes(assignee.toString()))
      : true;

    const matchesStatus = filters.status?.length
      ? filters.status.includes(item.status?.trim())
      : true;

    const matchesRelated = filters.related?.length
      ? filters.related.includes(item.related?.trim())
      : true;

    return (
      matchesLocation &&
      matchesAssignee &&
      matchesStatus &&
      matchesRelated
    );
  });
};

export const filterActivityDate = (value, allData) => {
  const currentDate = moment().format("YYYY-MM-DD")
  let tempData;
  if (value == "current") {
    const filterData = allData.filter(item => {
      return item.enddate == currentDate
    })
    tempData = filterData
  } else if (value == "past") {
    const filterData = allData.filter(item => {
      return item.enddate < currentDate
    })
    tempData = filterData
  } else if (value == "future") {
    const filterData = allData.filter(item => {
      return item.enddate > currentDate
    })
    tempData = filterData
  } else if (value == "all") {
    tempData = allData
  }

  return tempData
  // const shortedData = tempData.sort(
  //   (a, b) => new Date(a.date_time) - new Date(b.date_time)
  // )
  // setFollowUp(shortedData)
}

export const customGlobalFilter = (rows, columnIds, filterValue) => {
  return rows.filter(row => {
    const rowData = row.original;
    // Iterate over all fields in row.original to check for a match
    for (const key in rowData) {
      if (
        rowData[key] &&
        rowData[key]
          .toString()
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      ) {
        return true; // If any field matches, keep this row
      }
    }
    return false; // Otherwise, filter it out
  });
};