import React, { useState } from "react"

const NewPagination = ({
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageOptions,
  pageSize,
  page,
  data,
  nextPage,
  previousPage,
}) => {
  const currentEntries = pageIndex * pageSize + 1
  const endEntry = pageIndex * pageSize + page.length
  const totalEntries = data.length

  const [currentPageGroup, setCurrentPageGroup] = useState(0)
  const pagesToShow = 5
  const totalPageGroups = Math.ceil(pageOptions.length / pagesToShow)

  const startPageIndex = currentPageGroup * pagesToShow
  const endPageIndex = Math.min(
    startPageIndex + pagesToShow,
    pageOptions.length
  )

  return (
    <>
      <div className="mt-3">
        <div
          className="d-flex justify-content-between align-items-center flex-wrap py-3 py-md-0"
          // style={{ marginTop: "10px" }}
        >
          <p>
            Showing {data?.length == 0 ? 0 : currentEntries} to {endEntry} of {totalEntries} entries
          </p>

          <div className="d-flex align-items-md-center justify-content-md-end flex-column flex-md-row">
            <div>
              <ul className="pagination m-0">
                <li
                  className={`page-item ${canPreviousPage ? "" : "disabled"} `}
                  onClick={previousPage}
                >
                  <a className="page-link">Previous</a>
                </li>

                {Array.from(
                  { length: endPageIndex - startPageIndex },
                  (_, i) => startPageIndex + i
                ).map(pageNumber => (
                  <li
                    key={pageNumber}
                    className={` page-item ${
                      pageNumber === pageIndex ? "bg-purple" : ""
                    }`}
                    onClick={() => gotoPage(pageNumber)}
                  >
                    <a
                      className={`page-link ${
                        pageNumber === pageIndex ? "bg-purple" : ""
                      }`}
                      role="link"
                      aria-current={
                        pageNumber === pageIndex ? "page" : undefined
                      }
                    >
                      {pageNumber + 1}
                    </a>
                  </li>
                ))}

                <li
                  className={`page-item ${canNextPage ? "" : "disabled"} `}
                  onClick={nextPage}
                >
                  <a className="page-link">Next</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewPagination