import axios from "axios";
import configure from "configure";

export const axiosInstance = axios.create({
    baseURL: `${configure?.apiUrl}`,
    headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
    },
});


