import React, { useEffect, useState } from "react"
import { Container, Form, Input, Label } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import India from "../../../assets/images/flags/india.png"
import Uk from "../../../assets/images/flags/uk.png"
import "../../../assets/css/Finance/Style/ExpenseSection/expense.css"
import uploadbill from "../../../assets/images/Expenses/Upload_Bill.jpg"
import viewbill from "../../../assets/images/Expenses/View_Bill.jpg"
import Draggable, { DraggableCore } from "react-draggable"
import { Resizable, ResizableBox } from "react-resizable"
import * as XLSX from "xlsx"

import { Table, Row, Col, Card, CardBody, Modal } from "reactstrap"
import Pagination from "components/Common/Pagination"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import Swal from "sweetalert2"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import indiaFlag from "../../../assets/images/flags/uk.png"
import moment from "moment"
import NewPagination from "components/Common/Newpagination"

function TableContainer({
  columns,
  data,
  setDatas,
  setAllDatas,
  allData,
  setAccessDenied,
  setLoading,
}) {
  const handleExport = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new()

    const modifyArr = data?.map((list, index) => {
      return {
        sr_number: index + 1,
        date: moment(list.date).format("DD-MM-YYYY"),
        location: list.location == 1 ? "India" : "UK",
        type: list.type,
        category: list.category_name,
        title: list.title,
        amount: list.amount,
      }
    })
    console.log("modifyArr", modifyArr)
    // Convert the data into a worksheet
    const worksheet = XLSX.utils.json_to_sheet(modifyArr)

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

    // Generate a download link and click it to trigger download
    XLSX.writeFile(workbook, "exported_data.xlsx")
  }

  const [fixedContent, setFixedContent] = useState(false)
  const [status, setStatus] = useState(false)

  const [uploadBill, setUploadBill] = useState(false)
  const [viewBill, setViewBill] = useState(false)
  const [viewAllBill, setViewAllBill] = useState([])

  const [billName, setBillName] = useState("")
  const [billDate, setBillDate] = useState("")
  const [billFile, setBillFile] = useState("")
  const [formPopup, setFormPopup] = useState(false)
  const [boxWidth, setBoxWidth] = useState(0)

  const [fStartDate, setFStartDate] = useState("")
  const [fendDate, setFendDate] = useState("")

  // popup upate width
  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.52)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  const navigate = useNavigate()

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setViewBill(false), setUploadBill(false)
  })

  document.addEventListener("scroll", () => {
    if (window.scrollY > 90) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const [title, setTitle] = useState("")
  const [name_modal, setNameModal] = useState(false)
  // const [addExpense, setAddExpense] = useState(false)

  const onAddPermission = async id => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 54)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          setUploadBill(true)
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  // Api integration

  const handleBillUpload = async e => {
    e.preventDefault()
    try {
      // name, date,file
      setLoading(true)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("name", billName)
      formData.append("date", billDate)
      formData.append("file", billFile)
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/addCreditCardBill`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        console.log(response)
        setLoading(false)
        Swal.fire({
          icon: "success",
          text: "Credit card bill added successfully !",
        }).then(() => {
          setUploadBill(false)
          setViewAllBill(response.data.data)
          setLoading(false)
          setBillName("")
          setBillDate("")
          setBillFile("")
        })
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/viewCreditCardBill`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        console.log("viewCreditCardBill", response)
        setViewAllBill(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const handleFilter = e => {
    e.preventDefault()

    // Convert string dates to Date objects
    const startDate = new Date(fStartDate)
    const endDate = new Date(fendDate)

    if (startDate > endDate) {
      Swal.fire({
        icon: "warning",
        text: "Start date cannot be after end date.",
      })
      return
    }

    // Filter your data based on the date range
    const newFilteredData = allData?.details?.filter(item => {
      const itemDate = new Date(item.date) // Assuming each item has a 'date' field
      return itemDate >= startDate && itemDate <= endDate
    })

    // Update the state with filtered data
    setDatas(newFilteredData ? newFilteredData : [])
  }

  const handleClear = () => {
    if (fStartDate || fendDate) {
      setFStartDate("")
      setFendDate("")
      setDatas(allData?.details ? allData?.details : [])
      Swal.fire({
        icon: "success",
        text: "Filter cleared successfully.",
      })
    } else {
      setDatas(allData?.details ? allData?.details : [])
    }
  }

  const handleClose = () => {
    if (fStartDate || fendDate) {
      setFormPopup(false)
      setFStartDate("")
      setFendDate("")
      setDatas(allData?.details ? allData?.details : [])
      Swal.fire({
        icon: "success",
        text: "Filter cleared and closed successfully.",
      })
    } else {
      setFormPopup(false)
      setDatas(allData?.details ? allData?.details : [])
    }
  }

  // Render the UI for your table
  return (
    <div>
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 mt-3 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red  fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>

      {/* <Row className="mb-4">
      </Row> */}
      <Row className="d-flex mt-4">
        <div className="d-flex col-md-6 col-lg-7 justify-flex-start align-items-center gap-2">
          <div className="col-md-4 col-lg-3 budget_status text-success pt-2 px-2 me-5 me-lg-2 rounded proposal-status text-center cm-balance">
            <h6>Current Month Balance </h6>
            <h3>
              ₹{" "}
              {allData?.current_month
                ? Number(allData?.current_month)?.toFixed(2)
                : "0"}
            </h3>
          </div>
          <div className="col-md-3 col-lg-3 bu  col-lg-2dget_status text-primary pt-2 px-1 rounded proposal-status text-center lm-balance">
            <h6>Last Month Balance </h6>
            <h3>
              ₹ {allData?.last_month ? Number(allData?.last_month)?.toFixed(2) : "0"}
            </h3>
          </div>
          <div className="col-md-4  col-lg-3 budget_status text-warning pt-2 px-2 rounded proposal-status text-center">
            <h6>Last 12 Month Avg. Balance </h6>
            <h3>₹ {allData?.average ? Number(allData?.average)?.toFixed(2) : "0"}</h3>
          </div>
        </div>
        <div className="col-md-6 col-lg-5 text-end d-flex justify-content-end align-items-center gap-2 btn-container">
          <div className="col-md-4 col-lg-3 export-csv-btn">
            <button
              onClick={handleExport}
              className=" btn btn-label export-btn tex-white credit-card-btn"
            >
              <i className="fas fa-download me-2 label-icon"></i>
              Export CSV
            </button>
          </div>
          <div className="col-md-2 col-lg-3 view-bill-btn">
            <button
              className=" btn btn-save btn-label credit-card-btn view-bill"
              onClick={() => setViewBill(true)}
            >
              <i className="fas fa-eye me-2 label-icon"></i>
              View Bill
            </button>
          </div>
          <div className="col-md-3 col-lg-3 col-sm-3 upload-bill">
            <button
              className=" btn btn-upload btn-label credit-card-btn upload-bill-btn"
              onClick={() => {
                onAddPermission()
              }}
            >
              <i className="fas fa-upload me-2 label-icon"></i>
              Upload Bill
            </button>
          </div>
        </div>
      </Row>
      <Row className="m-0 d-flex justify-space-between align-items-center">
        <div className="p-0 col-md-2">
          <select
            className=" form-select mt-4"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6 d-flex justify-content-end align-items-center">
          <div className="mb-0">
            <button
              onClick={() => setFormPopup(!formPopup)}
              className="btn btn-outline-secondary  me-3"
            >
              <i className="fas fa-filter"></i>
            </button>
          </div>
          <div className="search-box my-xxl-0 d-inline-block ">
            <div className="position-relative  mt-2">
              <label htmlFor="search-bar-0" className="search-label">
                <span id="search-bar-0-label" className="sr-only">
                  Search this table
                </span>
                <input
                  onChange={e => setGlobalFilter(e.target.value)}
                  id="search-bar-0"
                  type="text"
                  className=" form-control rounded h-100 "
                  placeholder={` Search...`}
                  value={globalFilter || ""}
                />
              </label>
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered mt-4" {...getTableProps()}>
          <thead
            className={`${
              fixedContent && page.length >= 10 ? "fix-credit-header" : ""
            } fix-introducer-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <a
                        onClick={() => {
                          setNameModal(true)
                          setTitle(column.Header)
                        }}
                      >
                        {/* <i
                          title="info"
                          className="bx bx-info-circle ms-1 info-icon"
                        ></i> */}
                      </a>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-introducer-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr>
                <td className="text-center" colSpan={10}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>

      {/* Upload Bill Modal */}
      <Modal isOpen={uploadBill} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Upload Credit Card Bill</h5>
          <button
            type="button"
            onClick={() => {
              setUploadBill(false)
              setBillName("")
              setBillDate("")
              setBillFile("")
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form
            className="row d-flex justify-content-center align-items-center"
            onSubmit={handleBillUpload}
          >
            <div className="col-md-5 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img src={uploadbill} alt="Addepe" style={{ width: "80%" }} />
              </div>
            </div>

            <div className="col-md-6 d-flex flex-column ">
              <div className="col-md-12 d-flex  justify-content-start align-items-start mb-4">
                <div className="col-md-12 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Name <span className="text-danger">*</span>
                  </Label>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      required
                      value={billName}
                      onChange={e => setBillName(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 d-flex  justify-content-start align-items-start mb-4">
                <div className="col-md-5 ps-2">
                  <Input
                    type="date"
                    className="form-control"
                    required
                    value={billDate}
                    onChange={e => setBillDate(e.target.value)}
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>
              </div>

              <div className="col-md-12 d-flex  justify-content-start align-items-start">
                <div className="col-md-12 ps-2">
                  <label htmlFor="">
                    Upload File <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control border-radius"
                    required
                    onChange={e => setBillFile(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="mt-4 text-center">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>
                <a
                  onClick={() => {
                    setUploadBill(false)
                    setBillName("")
                    setBillDate("")
                    setBillFile("")
                  }}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>

            {/* </div> */}
          </Form>
        </div>
      </Modal>

      {/* View Bill Modal */}
      <Modal isOpen={viewBill} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">View Credit Card Bill</h5>
          <button
            type="button"
            onClick={() => {
              setViewBill(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4 my-5">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-4 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img src={viewbill} alt="Addepe" style={{ width: "80%" }} />
              </div>
            </div>
            <div className="col-md-8">
              <div
                className="table-responsive view-crdit-card"
                // style={{ height: "300px", overflowY: "auto" }}
              >
                <Table bordered hover size="" striped>
                  <thead>
                    <tr>
                      <th className="text-center credit-bill-col-1">ID</th>
                      <th className="credit-bill-col-2">Name</th>
                      <th className="credit-bill-col-3 text-center">Month</th>
                      <th className="credit-bill-col-4 text-center">Year</th>
                      <th className="text-center credit-bill-col-5">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {viewAllBill?.map(list => {
                      const getMonthName = monthNumber => {
                        return moment()
                          .month(monthNumber - 1)
                          .format("MMMM")
                      }
                      const getYearFromDate = date => {
                        return date
                          ? moment(date, "YYYY/MM/DD").format("YYYY")
                          : ""
                      }
                      return (
                        <tr key={list.id}>
                          <td
                            scope="row"
                            className="text-center credit-bill-col-1"
                          >
                            {list.id}
                          </td>
                          <td className="credit-bill-col-2">
                            {list.title ? list.title : "-"}
                          </td>
                          <td className="credit-bill-col-3 text-center">
                            {list.month
                              ? getMonthName(Number(list.month))
                              : "-"}
                          </td>
                          <td className="credit-bill-col-4 text-center">
                            {list.date ? getYearFromDate(list.date) : "-"}
                          </td>
                          <td className="text-center credit-bill-col-5">
                            <a
                              href={list.link}
                              target="_blank"
                              rel="noreferrer"
                              download
                              className=" btn btn-download bg-purple"
                            >
                              <i className="fas fa-download label-icon"></i>
                            </a>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {formPopup ? (
        <Draggable>
          <ResizableBox
            width={boxWidth}
            style={{
              position: "fixed",
              top: "24%",
              right: "6%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div>
              <Form onSubmit={handleFilter}>
                <Row className="align-items-end">
                  <Row className="d-flex flex-wrap pe-0 me-0 row">
                    <div className="col-md-3 mt-2">
                      <label htmlFor="">
                        From <span className="text-danger">*</span>
                      </label>
                      <Input
                        required
                        value={fStartDate}
                        onChange={e => setFStartDate(e.target.value)}
                        type="date"
                        className="form-control"
                        placeholder="Enter date"
                      />
                    </div>
                    <div className="col-md-3 mt-2">
                      <label htmlFor="">
                        To <span className="text-danger">*</span>
                      </label>
                      <Input
                        required
                        value={fendDate}
                        onChange={e => setFendDate(e.target.value)}
                        type="date"
                        className="form-control"
                        placeholder="Enter date"
                      />
                    </div>

                    <div className="d-flex col-md-6 mb-1 align-items-end justify-content-end">
                      <div className="mr-10">
                        <a
                          className="btn me-1"
                          onClick={handleClear}
                          style={{
                            // fontSize: "11px",
                            backgroundColor: "#fed36d",
                            borderColor: "#fed36d",
                            color: "black",
                          }}
                        >
                          Clear Filters
                        </a>
                      </div>
                      <div className="mr-10">
                        <button className="btn btn-purple">
                          <i className="fas fa-filter search-icon me-1 icon-center"></i>{" "}
                          Filter
                        </button>
                      </div>
                      <div className="">
                        <a className="btn btn-red" onClick={handleClose}>
                          <i className="dripicons-cross me-1 icon-center"></i>{" "}
                          Close
                        </a>
                      </div>
                    </div>
                  </Row>
                </Row>
              </Form>
              {/* <i className="bi bi-triangle-fill pop-icon"></i> */}
            </div>
          </ResizableBox>
        </Draggable>
      ) : null}
    </div>
  )
}

const CrediCardSection = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)

  const [isSelectDisabled, setIsSelectDisabled] = useState(true)

  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: state.isDisabled ? "gray" : "white",
      // other styles
    }),
    // other style properties
  }

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setmodal_fullscreen(false)
  })

  //meta title
  document.title = "Credit Card | Zithas Crm"

  const [data, setDatas] = useState([])
  const [allData, setAllDatas] = useState(null)
  const [view_customer, setVeiwCustomer] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [paymentMode, setPaymentMode] = useState(false)

  const [modal_Access, setAccessDenied] = useState(false)
  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/creditCard`,
        // data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        console.log("creditCard", response)
        setDatas(response?.data?.data?.details)
        setAllDatas(response?.data?.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const navigate = useNavigate()
  // amount: 321312
  // ​​​​
  // category_name: "Phone Bill"
  // ​​​​
  // date: "2024-08-08"
  // ​​​​
  // location: 2
  // ​​​​
  // title: "ewee"
  // ​​​​
  // total_paidamount: 9209
  // ​​​​
  // type: "Expense"

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "Sr no",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center billTable-col-1",
        Cell: ({ row }) => {
          return <>{row.index + 1}</>
        },
      },
      {
        Header: "Date",
        accessor: "date",
        isInfoIcon: false,
        className: "text-center billTable-col-2",
        Cell: cellProps => {
          return <>{moment(cellProps.row.original.date).format("DD-MM-YYYY")}</>
        },
      },
      {
        Header: () => {
          return <div style={{ textAlign: "center" }}>Location</div>
        },
        accessor: "location",
        grow: false,
        className: "location-box billTable-col-3",
        Cell: cellProps => {
          return (
            <div className="text-center d-flex justify-content-center align-items-center">
              {cellProps.row.original.location == 1 ? (
                <img
                  src={India}
                  className=" mx-1 location-img text-center"
                  alt="flag"
                  height="20"
                  width="30"
                />
              ) : (
                <img
                  src={Uk}
                  className=" mx-1 location-img"
                  alt="flag"
                  height="20"
                  width="30"
                />
              )}
            </div>
          )
        },
        isInfoIcon: true,
      },

      {
        Header: "Type",
        accessor: "type",
        isInfoIcon: true,
        className: "text-center type-col billTable-col-4",
      },

      {
        Header: "Category",
        accessor: "category_name",
        isInfoIcon: true,
        className: "text-center Category-col billTable-col-5",
      },
      {
        Header: "Title",
        accessor: "title",
        isInfoIcon: true,
        className: "title-col billTable-col-6",
      },
      {
        Header: "Amount",
        accessor: "amount",
        isInfoIcon: true,
        className: "text-center billTable-col-7",
      },
    ],
    []
  )

  const location = [
    { label: "India", value: "1" },
    { label: "UK", value: "2" },
  ]

  const payments = [
    { label: "Bank Account", value: "1" },
    { label: "Credit Card", value: "2" },
    { label: "Other", value: "3" },
  ]

  const projects = [
    { label: "Earth Development", value: "1" },
    { label: "Mars Development", value: "2" },
    { label: "Jen Therapy", value: "3" },
  ]

  const Categories = [
    { label: "Vendor", value: "1" },
    { label: "Freelancer", value: "2" },
    { label: "Introducer", value: "3" },
    { label: "Other", value: "4" },
  ]

  const data2 = [
    {
      id: 1,
      location: <img src={indiaFlag}></img>,
      date: "29-06-2024",
      type: "Expense",
      category: "Other",
      title: "IndusInd Bank Credit Card Bill of Month Jun 2024",
      amount: 10000,
    },
    {
      id: 2,
      location: <img src={indiaFlag}></img>,
      date: "29/06/2024",
      type: "Expense",
      category: "Other",
      title: "IndusInd Bank Credit Card Bill of Month Jun 2024",
      amount: 10000,
    },
    {
      id: 3,
      location: <img src={indiaFlag}></img>,
      date: "29/06/2024",
      type: "Expense",
      category: "Other",
      title: "ICICI Bank Credit Card Bill of Month Jun 2024",
      amount: 10000,
    },
    {
      id: 4,
      location: <img src={indiaFlag}></img>,
      date: "29/06/2024",
      type: "Expense",
      category: "Other",
      title: "HDFC Bank Credit Card Bill of Month Jun 2024",
      amount: 10000,
    },
    {
      id: 5,
      location: <img src={indiaFlag}></img>,
      date: "29/06/2024",
      type: "Expense",
      category: "Other",
      title: "Bank Of Baroda Credit Card Bill of Month Jun 2024",
      amount: 10000,
    },
    {
      id: 6,
      location: <img src={indiaFlag}></img>,
      date: "29/06/2024",
      type: "Expense",
      category: "Other",
      title: "IndusInd Bank Credit Card Bill of Month Jun 2024",
      amount: 10000,
    },
    {
      id: 7,
      location: <img src={indiaFlag}></img>,
      date: "29/06/2024",
      type: "Expense",
      category: "Other",
      title: "HDFC Bank Credit Card Bill of Month May 2024",
      amount: 10000,
    },
    {
      id: 8,
      location: <img src={indiaFlag}></img>,
      date: "29/06/2024",
      type: "Expense",
      category: "Other",
      title: "IndusInd Bank Credit Card Bill of Month May 2024",
      amount: 10000,
    },
    {
      id: 9,
      location: <img src={indiaFlag}></img>,
      date: "29/06/2024",
      type: "Expense",
      category: "Other",
      title: "Bank Of Baroda Credit Card Bill of Month May 2024",
      amount: 10000,
    },
  ]
  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Credit Card"} />

          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setDatas={setDatas}
                      setAllDatas={setAllDatas}
                      allData={allData}
                      setAccessDenied={setAccessDenied}
                      setLoading={setLoading}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default CrediCardSection
