import React, { useEffect, useMemo, useState } from "react"
import { Table, Row, Col, Card, CardBody, Modal, Form } from "reactstrap"
import { Link } from "react-router-dom"
// import { useTable, useGlobalFilter, usePagination } from "react-table"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import { useNavigate } from "react-router-dom"

// css
import "../../../assets/css/Settings/Setting.css"

// commom
import Pagination from "components/Common/Pagination"
import configure from "configure"
import axios from "axios"
import Swal from "sweetalert2"
import Loader from "pages/Separate/Loader"
import NewPagination from "components/Common/Newpagination"
import addCatImg from "../../../assets/images/Expenses/addCatImg.jpg"
import editCatImg from "../../../assets/images/Expenses/editCatImg.jpg"

function TableContainer({ columns, data, setLoading, setDatas }) {
  const [modal_center, setmodal_center] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)
  const [addCateName, setaAddCateName] = useState("")
  const [addType, setAddType] = useState("")
  const [addCalculate, setAddCalculate] = useState("0")
  const [addDividedBy, setAddDividedBy] = useState("")

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 90) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Center Modal
  function tog_center() {
    setmodal_center(!modal_center)
  }

  // Use the state and functions returned from useTable to build your UI
  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   page,
  //   state,
  //   previousPage,
  //   nextPage,
  //   canPreviousPage,
  //   canNextPage,
  //   gotoPage,
  //   setPageSize,
  //   pageOptions,
  //   setGlobalFilter,
  //   pageCount,
  //   prepareRow,
  // } = useTable(
  //   {
  //     columns,
  //     data,
  //   },
  //   useGlobalFilter,
  //   usePagination
  // )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id",
            desc: true,
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const handleAddForm = async e => {
    e.preventDefault()

    try {
      setLoading(true)
      const formData = new FormData()
      //   name,type,calculate,divided_by
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("name", addCateName)
      formData.append("type", addType)
      formData.append("calculate", addCalculate)
      formData.append("divided_by", addCalculate == "1" ? addDividedBy : "1")
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/addFinanceCategory`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        Swal.fire({
          icon: "success",
          text: "Category added successfully !",
        }).then(() => {
          setDatas(response.data.data)
          setaAddCateName("")
          setAddType("")
          setAddCalculate("0")
          setAddDividedBy("")
          setmodal_center(false)
        })
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  // Render the UI for your table
  return (
    <div>
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => {
            tog_center()
          }}
          className="btn btn-purple  fix-btn"
        >
          <i className="fas fa-plus me-2 icon-size"></i>
          <span>Category</span>
        </button>
        <button onClick={() => navigate(-1)} className="btn btn-red  fix-btn">
          <i className="dripicons-cross me-2 icon-center"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className=" form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col md={8} className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="">
            <button
              onClick={() => {
                tog_center()
              }}
              className=" btn text-uppercase btn-purple btn-label"
            >
              {" "}
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Category
            </button>
            <button
              onClick={() => navigate(-1)}
              className=" btn ms-md-2 btn-red  btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <Table className="table table-bordered mt-4" {...getTableProps()}>
        <thead className={`${(fixedContent && page.length >= 10) ? "fixed-category-setting-header" : ""}`}>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  // className={`customShortIcon col-md-${index === 1 || index === 2 ? "4" : "1"}`}
                  className={`${column.className} customShortIcon`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  {column.isInfoIcon ? (
                    <i
                      title="info"
                      className="bx bx-info-circle ms-1 info-icon"
                    ></i>
                  ) : null}

                  {column.isShortIcon && (
                      <span
                        onClick={() =>
                          column.getSortByToggleProps().onClick()
                        }
                        className="ms-2 cursor-pointer"
                      >
                        <i
                          title="Ascending"
                          className={`bi bi-arrow-up info-icon ${
                            column.isSorted && !column.isSortedDesc
                              ? "text-dark"
                              : ""
                          }`}
                        ></i>
                        <i
                          title="Descending"
                          className={`bi bi-arrow-down info-icon ${
                            column.isSorted && column.isSortedDesc
                              ? "text-dark"
                              : ""
                          }`}
                        ></i>
                      </span>
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {/* <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="row-hover" {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                    className="row-hover"
                    {...row.getRowProps()}
                   
                      key={index}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody> */}
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                className="row-hover"
                {...row.getRowProps()}
                key={i}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      className={cell.column.className}
                      key={index}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>

      {/* Add Category Modal */}
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Category</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleAddForm}>
            <Row>
              <div className="col-6">
                <label htmlFor="">Category Name </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control "
                  value={addCateName}
                  onChange={e => setaAddCateName(e.target.value)}
                />
              </div>
              <div className="col-6">
                <label htmlFor="">Type </label>
                <select
                  className="form-select "
                  value={addType}
                  onChange={e => setAddType(e.target.value)}
                >
                  <option value="Nothing Selected">Nothing Selected</option>
                  <option value="Expense">Expense</option>
                  <option value="Payment">Payment</option>
                </select>
              </div>
             
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className="btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Submit
                </button>

                {/* <button
                onClick={() => setmodal_center(false)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button> */}
            </div>
          </Form>
        </div>
      </Modal>

      {/* <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Category</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleAddForm}>
            <Row>
              <div className="col-5">
                <img src={addCatImg} className="img-fluid" />
              </div>
              <div className="col-6 d-flex flex-column align-items-start justify-content-center gap-4">
              
              <div className="col-10">
                <label htmlFor="">Category Name <span className="text-danger">*</span></label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control "
                  required
                  value={addCateName}
                  onChange={e => setaAddCateName(e.target.value)}
                />
              </div>
              <div className="col-10">
                <label htmlFor="">Type <span className="text-danger">*</span></label>
                <select
                  className="form-select "
                  value={addType}
                  onChange={e => setAddType(e.target.value)}
                >
                  <option value="Nothing Selected">Nothing Selected</option>
                  <option value="Expense">Expense</option>
                  <option value="Payment">Payment</option>
                </select>
              </div>
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className=" btn btn-purple btn-label">
                <i className="fas fa-save me-2 icon-size label-icon"></i>
                Save
              </button>
              <button
                onClick={() => setAddModal(false)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
          </Form>
        </div>
      </Modal> */}
      {/* ------------ */}
    </div>
  )
}

const Category = () => {
  const [modal_center, setmodal_center] = useState(false)
  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [editId, setEditId] = useState("")
  const [editType, setEditType] = useState("")
  const [editCateName, setEditCateName] = useState("")
  const [editCalculate, setEditCalculate] = useState("0")
  const [editDividedBy, setEditDividedBy] = useState("")

  // Center Modal
  function tog_center() {
    setmodal_center(!modal_center)
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isShortIcon: true,
        className:"text-center categ-id"
      },
      {
        Header: "Category Name",
        accessor: "name",
        isInfoIcon: true,
        isShortIcon: true,
        className:"categ-name"
      },
      {
        Header: "Type",
        accessor: "type",
        isInfoIcon: true,
        isShortIcon: true,
        className:"categ-type"
      },
      {
        Header: "Action",
        accessor: "action",
        className:"text-center categ-action",
        Cell: cellProps => {
          const onEditPermission = async id => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 43)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
                  tog_center()
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          const handleDelete = async () => {
            Swal.fire({
              icon: "warning",
              text: "Are you sure ?",
              showCancelButton: true,
              confirmButtonText: "Yes delete it!",
            }).then(async willDelete => {
              if (willDelete.isConfirmed) {
                try {
                  setLoading(true)
                  const response = await axios({
                    method: "get",
                    url: `${configure.apiUrl}/deleteFinanceCategory/${cellProps.row.original.id}`,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  if (response) {
                    setLoading(false)
                    Swal.fire({
                      icon: "success",
                      text: "Category deleted successfully.",
                    }).then(() => {
                      setDatas(response.data.data)
                    })
                  }
                } catch (error) {
                  setLoading(false)
                  console.log(error)
                }
              }
            })
          }

          const handleEdit = () => {
            setEditType(cellProps.row.original.type)
            setEditCateName(cellProps.row.original.name)
            setEditCalculate(cellProps.row.original.calculate)
            setEditDividedBy(cellProps.row.original.divided_by)
            setEditId(cellProps.row.original.id)
            setmodal_center(true)
          }
          return (
            <div>
              <button
                onClick={() => {
                  {
                    handleEdit()
                  }
                }}
                className="btn action-btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </button>
              <Link
                to="#"
                onClick={() => handleDelete()}
                className="btn btn-sm action-btn btn-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/financeCategory`,
        // data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDatas(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const handleEditForm = async e => {
    e.preventDefault()

    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("category_id", editId)
      formData.append("name", editCateName)
      formData.append("type", editType)
      formData.append("calculate", editCalculate)
      formData.append("divided_by", editCalculate == "1" ? editDividedBy : "")
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/editFinanceCategory`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        Swal.fire({
          icon: "success",
          text: "Category edited successfully !",
        }).then(() => {
          setDatas(response.data.data)
          setEditId("")
          setmodal_center(false)
        })
      }
    } catch (error) {
      setLoading(false)

      console.log(error)
    }
  }

  return (
    <>
      <div>
        {isLoading && <Loader />}
        <Row className="ms-0 pe-0">
          <Col lg={12} className="p-0">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={data}
                  setDatas={setDatas}
                  setLoading={setLoading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Edit Category Modal */}
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Category</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleEditForm}>
            <Row>
              <div className="col-6">
                <label htmlFor="">Category Name </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control "
                  value={editCateName}
                  onChange={e => setEditCateName(e.target.value)}
                />
              </div>
              <div className="col-6">
                <label htmlFor="">Type </label>
                <select
                  className="form-select "
                  value={editType}
                  onChange={e => setEditType(e.target.value)}
                >
                  <option value="Nothing Selected">Nothing Selected</option>
                  <option value="Expense">Expense</option>
                  <option value="Payment">Payment</option>
                </select>
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
              <div className="text-end">
                <button className="btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Submit
                </button>
            </div>
          </Form>
        </div>
      </Modal>

        {/* <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Category</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleEditForm}>
            <Row>
              <div className="col-6 text-center">
                <img src={addCatImg} className="img-fluid" style={{width:"90%"}}/>
              </div>
              <div className="col-6 d-flex flex-column align-items-center justify-content-center gap-4">
              
                <div className="col-10">
                  <label htmlFor="">Category Name <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    className="form-control "
                    value={editCateName}
                    onChange={e => setEditCateName(e.target.value)}
                  />
                </div>
                <div className="col-10">
                  <label htmlFor="">Type <span className="text-danger">*</span></label>
                  <select
                    className="form-select "
                    value={editType}
                    onChange={e => setEditType(e.target.value)}
                  >
                    <option value="Nothing Selected">Nothing Selected</option>
                    <option value="Expense">Expense</option>
                    <option value="Payment">Payment</option>
                  </select>
                </div>
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className=" btn btn-purple btn-label">
                <i className="fas fa-save me-2 icon-size label-icon"></i>
                Save
              </button>
              <button
                onClick={() => setAddModal(false)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
          </Form>
        </div>
      </Modal> */}
      {/* ------------ */}
    </>
  )
}

export default Category
