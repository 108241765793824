import React from 'react';
import { useTable } from 'react-table';
import { Table } from 'reactstrap';

const CommonTable = ({ getTableProps, fixedContent, headerGroups, getTableBodyProps, page, prepareRow, ClassName }) => {
    return (
        <Table className="table table-bordered" {...getTableProps()}>
            <thead
                className={`${fixedContent ? (ClassName ? ClassName : "fix-header") : ""
                    } fix-proposal-header`}
            >
                {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                            <th
                                key={index}
                                className={`t-col-${index + 1} ${column.className}`}
                                {...column.getHeaderProps()}
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                            >
                                {column.render("Header")}
                                {column.isShortIcon ? (
                                    <span
                                        onClick={() =>
                                            column.getSortByToggleProps().onClick()
                                        }
                                    >
                                        <i  
                                            title="Ascending"
                                            className={`bi bi-arrow-up ms-1 info-icon ${column.isSorted && !column.isSortedDesc
                                                ? "text-dark"
                                                : ""
                                                }`}
                                        ></i>
                                        <i
                                            title="Descending"
                                            className={`bi bi-arrow-down info-icon ${column.isSorted && column.isSortedDesc
                                                ? "text-dark"
                                                : ""
                                                }`}
                                        ></i>
                                    </span>
                                ) : null}
                                {column.isInfoIcon ? (
                                    <a
                                        onClick={() => {
                                            setNameModal(true)
                                            setTitle(column.Header)
                                        }}
                                    >
                                        <i
                                            title="info"
                                            className="bx bx-info-circle ms-1 info-icon"
                                        ></i>
                                    </a>
                                ) : null}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>

            <tbody className="fix-proposal-header" {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    let rowColor
                    switch (row.original.status_name) {
                        case "Won":
                            rowColor = "bg-green"
                            break
                        case "Draft":
                            rowColor = "bg-gray"
                            break
                        case "Lost":
                            rowColor = "bg-red"
                            break
                        case "Sent":
                            rowColor = "bg-blue"
                            break
                    }
                    return (
                        <tr
                            className={`row-hover ${rowColor}`}
                            {...row.getRowProps()}
                            key={i}
                        >
                            {row.cells.map((cell, index) => (
                                <td
                                    key={index}
                                    className={`t-col-${index + 1} ${cell.column.className}`}
                                    {...cell.getCellProps()}
                                >
                                    {cell.render("Cell")}
                                </td>
                            ))}
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    );
};

export default CommonTable;
