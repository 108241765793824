import React, { useCallback, useEffect, useState } from "react"
import { Table, Row, Col, Input, Modal, Form, Label, Tooltip } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
// import ReactTooltip from "react-tooltip";

// common
import Pagination from "components/Common/Pagination"
import configure from "configure"
import classNames from "classnames"
import Select from "react-select"
import Swal from "sweetalert2"
import ReactSelect from "react-select"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import axios from "axios"
import { error } from "toastr"
import "../../../assets/css/Credential/Credential.css"
import NewPagination from "components/Common/Newpagination"
import Draggable, { DraggableCore } from "react-draggable"
import { Resizable, ResizableBox } from "react-resizable"
import moment from "moment"

const copyToClipBoard = async ({ text, setCopy, id }) => {
  try {
    await navigator.clipboard.writeText(text)
    setCopy({ type: id })
    setTimeout(() => setCopy({ value: "", type: null }), 2000) // Reset after 2 secondsnull
  } catch (err) {
    console.error("Failed to copy: ", err)
    fallbackCopyText(text, setCopy, id) // Use fallback if clipboard API fails
  }
}
const fallbackCopyText = (text, setCopy, id) => {
  const textarea = document.createElement("textarea")
  textarea.value = text
  textarea.style.position = "fixed"
  textarea.style.top = "-9999px"
  document.body.appendChild(textarea)
  textarea.select()
  document.execCommand("copy")
  document.body.removeChild(textarea)
  setCopy({ type: id })
  setTimeout(() => setCopy({ value: "", type: null }), 2000) // Reset after 2 seconds
}

// const copyToClipBoard = ({ text, setCopy, id }) => {
//   if (text) {
//     if (navigator.clipboard != undefined) {
//       navigator.clipboard.writeText(text)
//       setCopy({ type: id })
//     }

//     setTimeout(() => {
//       setCopy({ value: "", type: null })
//     }, 1000)
//   }
// }

function TableContainer({
  columns,
  dataall,
  data,
  setData,
  copy,
  setAccessDenied,
  modal_Access,
  setAddCredentials,
  setLoading,
  verticalActiveTab,
  credId,
}) {
  const [copy2, setCopy2] = useState({
    type: null,
  })
  const [status, setStatus] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  const [shortType, setShortType] = useState("2")
  // const handleDark = () => {
  //   setShortType(prevType => (prevType === "1" ? "2" : "1"))
  //   if(shortType == "1") {
  //     setData(prevData=>[...prevData])
  //   } else {
  //     setData(prevData=>[...prevData]?.reverse)
  //   }
  // }

  const handleDark = () => {
    setShortType(prevType => (prevType === "1" ? "2" : "1"))
  }

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id", // Set the ID of the first column for default sorting
            desc: true, // `true` for descending, `false` for ascending
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  const navigate = useNavigate()
  // Render the UI for your table

  const addCredPer = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 61)
    try {
      const permissionResponse = await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (permissionResponse) {
        setLoading(false)
        if (
          permissionResponse.data.data.india[3] == 1 ||
          permissionResponse.data.data.uk[3] == 1
        ) {
          if (credId) {
            setAddCredentials(true)
          } else {
            Swal.fire({
              icon: "warning",
              text: "The credential tab is empty. so you cannot add credentials.",
            })
          }
        } else {
          setAccessDenied(true)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => setPageSize(50), [])
  console.log("copy", copy2)
  return (
    <div className="px-2">
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => {
            addCredPer()
          }}
          className="btn btn-save fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 label-icon"></i>
          <span>Add</span>
        </button>
        <a
          onClick={() => navigate(-1)}
          className="btn btn-red fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </a>
      </div>
      <Row className="mb-2">
        <Col lg={2}>
          <select
            className=" form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-2">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <button
              type="button"
              onClick={() => {
                addCredPer()
              }}
              className="btn btn-save btn-label"
            >
              <i className="fas fa-plus me-2 label-icon"></i>
              <span>Add Credential</span>
            </button>
            <a
              className=" btn text-white ms-md-2 btn-red btn-label"
              onClick={() => navigate(-1)}
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              Cancel
            </a>
          </div>
        </Col>
      </Row>
      {/* {copy2.type && (
        <div
          className="position-absolute text-light copy-popup"
          style={{ paddingTop: "-10px" }}
        >
          Copied
        </div>
      )} */}
      <div className="">
        <Table
          className="table table-bordered mt-4"
          {...getTableProps()}
          id="credential-table"
        >
          <thead
            className={`${
              fixedContent ? `fixed-setting-header-credential ` : ""
            }`}
            // className="fixed-setting-header-cred"
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`special-class-${
                      headerGroup.headers.length
                    }-length-head-t-${index + 1} col-t-${index + 1} ${
                      column.classNames
                    } width-auto-cred`}
                    // {...column.getHeaderProps()}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div
                      className={`position-relative d-flex ${
                        column.isShortIcon
                          ? "justify-content-between"
                          : "justify-content-center"
                      }`}
                    >
                      {index === 0 && <span></span>}
                      <span
                        style={{
                          position: index === 0 ? "absolute" : "",
                          left: index === 0 ? "45%" : "",
                        }}
                      >
                        {column.render("Header")}
                      </span>
                      {column.isShortIcon ? (
                        <span
                          onClick={() =>
                            column.getSortByToggleProps().onClick()
                          }
                        >
                          <i
                            title="Ascending"
                            className={`bi bi-arrow-up ms-1 info-icon ${
                              column.isSorted && !column.isSortedDesc
                                ? "text-dark"
                                : ""
                            }`}
                          ></i>
                          <i
                            title="Descending"
                            className={`bi bi-arrow-down info-icon ${
                              column.isSorted && column.isSortedDesc
                                ? "text-dark"
                                : ""
                            }`}
                          ></i>
                        </span>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    const column = row.cells[index].column
                    const isLink =
                      cell.value &&
                      typeof cell.value === "string" &&
                      (cell.value.includes("https://") ||
                        cell.value.includes("http://"))
                    // ||
                    // cell.value.includes("ztpl.net/"))
                    const cellId = `${row.id}-${cell.column.id}`
                    return (
                      <>
                        <td
                          className={`special-class-${
                            row.cells.length
                          }-length-t-${index + 1} col-hover col-t-${
                            index + 1
                          } ${cell.classNames}`}
                          key={index}
                          {...cell.getCellProps()}
                          style={{ wordWrap: "anywhere" }}
                        >
                          {/* {copy2 === cellId && ( */}
                          {/* <div className="position-relative">
                            <span className="copy-popup">Copied!</span>
                          </div> */}
                          {/* )} */}
                          {copy2.type == cellId && (
                            <div className="position-absolute copyMsg">
                              Copied
                            </div>
                          )}
                          {isLink ? (
                            <>
                              <div className="d-flex justify-content-between">
                                <a
                                  href={`${cell.value}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="p-0 d-inline-block text-body"
                                  title="Click to open a new tab"
                                  style={{ wordWrap: "anywhere" }}
                                >
                                  {cell.render("Cell")}
                                </a>
                                <span>
                                  <i
                                    className={`ps-3 bi ${
                                      copy2.type == cellId
                                        ? "bi-clipboard-check"
                                        : "bi-clipboard label-icon"
                                    } label-icon fa-lg`}
                                    style={{
                                      cursor: "pointer",
                                      color: "#a8a6a6",
                                    }}
                                    onClick={e =>
                                      copyToClipBoard({
                                        text: cell.value,
                                        setCopy: setCopy2,
                                        id: cellId,
                                        title: "Click to Copy",
                                      })
                                    }
                                    title="Click to Copy"
                                  ></i>
                                </span>
                              </div>
                            </>
                          ) : column.isCopyIcon === true && isLink === false ? (
                            <div className="d-flex justify-content-between">
                              {cell.render("Cell")}
                              {cell.value && (
                                <span>
                                  <i
                                    className={`ps-3 bi ${
                                      copy2.type == cellId
                                        ? "bi-clipboard-check"
                                        : "bi-clipboard label-icon"
                                    } label-icon fa-lg`}
                                    style={{
                                      cursor: "pointer",
                                      color: "#a8a6a6",
                                    }}
                                    onClick={e =>
                                      copyToClipBoard({
                                        text: cell.value,
                                        setCopy: setCopy2,
                                        id: cellId,
                                        title: "Click to Copy",
                                      })
                                    }
                                    title="Click to Copy"
                                  ></i>
                                </span>
                              )}
                            </div>
                          ) : (
                            cell.render("Cell")
                          )}
                          {/* {cell.render("Cell")} */}
                        </td>
                      </>
                    )
                  })}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr className="border border-1">
                <td colSpan={8} className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const HostingPanelLive = ({
  verticalActiveTab,
  tableData,
  credId,
  setLoading,
  setTableData,
  setNavItemData,
  projectOption,
  setverticalActiveTab,
  navItemData,
  allData,
  setAllData,
}) => {
  const [editCredentials, setEditCredentials] = useState(false)
  const [addCredentials, setAddCredentials] = useState(false)
  const [viewCredentials, setViewCredentials] = useState(false)
  const [shareCredentials, setShareCredentials] = useState(false)
  const [data, setData] = useState([])
  const [shareCredList, setShareCredList] = useState([])
  const [modal_Access, setAccessDenied] = useState(false)
  const [formData, setFormData] = useState({})
  const [formDataEdit, setFormDataEdit] = useState({})
  const [editFormFields, setEditFormFields] = useState([])
  const [viewFormFields, setViewFormFields] = useState([])
  const [editCredId, setEditCredId] = useState("")
  const [shareCredId, setShareCredId] = useState("")
  const [isAccessShare, setIsAccessShare] = useState(null)
  const [shareStaff, setShareStaff] = useState([])
  const [allUser, setAllUser] = useState(null)
  const [filterShareUser, setFilterShareUser] = useState(null)
  const [selectedProject, setSelectedProject] = useState([])
  const [shareExpireDate, setShareExpireDate] = useState("")
  const [showShareList, setShowShareList] = useState([])
  const [showShareList2, setShowShareList2] = useState([])
  const [allActiveFields, setAllActiveFields] = useState([])
  const [allFields, setAllFields] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [copy, setCopy] = useState({
    type: null,
  })
  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  // const handleChangeProject = selecteOp => {
  //   if (selectedProject) {
  //     Swal.fire({
  //       icon: "warning",
  //       title: "Are you sure ?",
  //       text: "Changing the project will remove all current staff credentials and update them for the new project.",
  //       showCancelButton: true,
  //     }).then(result => {
  //       if (result.isConfirmed) {
  //         setSelectedProject(selecteOp)
  //       }
  //     })
  //   } else {
  //     setSelectedProject(selecteOp)
  //   }
  // }
  const activeColumn = allActiveFields
    ?.filter(obj => obj.show_field == 1)
    ?.map((fileldItem, index) => {
      let isCopyIcon = index !== 0 ? true : false
      // const findTxt = "http://";
      // const findLink = fileldItem.field_name?.includes(findTxt)
      // console.log("findLink",findLink)
      return {
        Header: fileldItem.field_label,
        accessor: fileldItem.field_name,
        // isCopyIcon: findLink == false ? true : false,
        // isLink: findLink == true ? true : false,
        isShortIcon: true,
        isCopyIcon: true,
        // Cell: (cellProps, index) => {
        //   console.log(
        //     "cellProps",
        //     `${cellProps.row.original}.${fileldItem.field_name}`
        //   )
        //   console.log("index", index)
        //   console.log("field_name", fileldItem.field_name)
        //   if (index == 0) {
        //     return <a target="_blank">{cellProps.row.original.field_name}</a>
        //   } else {
        //     return <span>{cellProps.row.original.field_name}</span>
        //   }
        // },
      }
    })
  const isAccessSh = useMemo(() => {
    const findTab = tableData?.project_access
    return findTab === 1 ? true : false
  }, [credId])
  useEffect(() => {
    const filterData = allData?.filter(item => {
      return item.id == verticalActiveTab
    })
    if (tableData?.active_fields !== undefined) {
      setAllActiveFields(tableData?.active_fields)
      setAllFields(tableData?.tab_fields)
    } else {
      setAllActiveFields([])
    }
    setData(
      filterData[0]?.credentials ? filterData[0]?.credentials?.reverse() : []
    )
  }, [tableData])

  const getViewData = data => {
    setViewCredentials(true)
    // Assuming tableData, credentials, and active_fields are properly defined
    // const fieldObjects = allActiveFields.length !== 0 ? allActiveFields : []
    const fieldObjects = allFields.length !== 0 ? allFields : []
    const originalObject = tableData?.credentials?.find(
      crd => crd.id === data.id
    )

    if (originalObject) {
      const personalObjects = fieldObjects?.map(field => ({
        field_name: field.field_name,
        field_label: field.field_label,
        value: originalObject[field.field_name] || "",
        isrequired: field.show_field == 1 ? true : false,
      }))
      setViewFormFields(personalObjects)
    } else {
      console.error(`No credentials found for id ${data.id}`)
    }
  }
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        id: "id",
        classNames: "col-md-1 text-center",
        isCopyIcon: false,
        isLink: false,
        isShortIcon: true,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      ...(activeColumn !== undefined && activeColumn.length !== 0
        ? activeColumn
        : [
            {
              Header: "",
              accessor: "hh",
              classNames: "col-md-10",
              isCopyIcon: true,
            },
          ]),
      {
        Header: "Action",
        accessor: "action",
        classNames: "text-center col-md-1",
        isCopyIcon: false,
        isLink: false,
        Cell: cellProps => {
          const isShare = async () => {
            try {
              setLoading(true)
              const formData = new FormData()
              formData.append("user_id", localStorage.getItem("auth_id"))
              formData.append("module_id", 61)
              const permissionResponse = await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              })
              if (permissionResponse) {
                setLoading(false)
                console.log("projectOption", projectOption)
                console.log("cellProps", cellProps.row.original)
                const findProject = projectOption?.find(
                  list => list.value == cellProps.row.original.project_id
                )
                setSelectedProject(findProject)
                const modiFySharedList = cellProps.row.original.sharedUsers
                // ?.map((item)=> {
                //   return {

                //   }
                // })
                setShowShareList2(modiFySharedList)
                if (
                  permissionResponse.data.data.india[4] == 1 ||
                  permissionResponse.data.data.uk[4] == 1
                ) {
                  setShareCredId(cellProps.row.original.id)
                  setShareCredentials(true)
                  const list = cellProps.row.original.assign?.split(",")
                  const showList = list
                    ? allUser?.filter(listItem =>
                        list.includes(listItem.value.toString())
                      )
                    : []
                  const filterUser = allUser?.filter(item => {
                    if (list) {
                      return !list.includes(item.value.toString())
                    } else {
                      return item
                    }
                  })
                  setFilterShareUser(filterUser)
                  setShowShareList(showList)
                } else {
                  setAccessDenied(true)
                }
              }
            } catch (error) {
              console.log(error)
            }
          }
          const deleteCred = async id => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 61)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
                  Swal.fire({
                    icon: "warning",
                    text: "Are You Sure ?",
                    showCancelButton: true,
                    confirmButtonText: "Yes delete it!",
                  }).then(async willOkay => {
                    if (willOkay.isConfirmed) {
                      setLoading(true)
                      try {
                        // role_id,user_id,data_id
                        const formData = new FormData()
                        formData.append(
                          "user_id",
                          localStorage.getItem("auth_id")
                        )
                        formData.append(
                          "role_id",
                          localStorage.getItem("auth_role")
                        )
                        formData.append("data_id", id)
                        const response = await axios({
                          method: "post",
                          url: `${configure.apiUrl}/credentials/delete`,
                          data: formData,
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                          },
                        })
                        if (response) {
                          const userFilterData = response?.data?.data?.find(
                            itemss => {
                              const userId = localStorage.getItem("auth_id")
                              const users = itemss.role_id?.split(",")
                              return (
                                users?.some(user =>
                                  userId.includes(user.trim())
                                ) && itemss.id == verticalActiveTab
                              )
                            }
                          )
                          const filteruserPerData =
                            response?.data?.data?.filter(itemss => {
                              const userId = localStorage.getItem("auth_id")
                              const users = itemss.role_id?.split(",")
                              return users?.some(user =>
                                userId?.includes(user.trim())
                              )
                            })
                          try {
                            setLoading(true)
                            const formData = new FormData()
                            formData.append(
                              "user_id",
                              localStorage.getItem("auth_id")
                            )
                            formData.append("module_id", 61)
                            const permissionResponse = await axios({
                              method: "post",
                              url: `${configure.apiUrl}/user_permission`,
                              data: formData,
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "multipart/form-data",
                              },
                            })
                            setLoading(false)
                            if (permissionResponse) {
                              if (
                                permissionResponse.data.data.india[1] == 1 ||
                                permissionResponse.data.data.uk[1] == 1
                              ) {
                                let userFilte = response?.data?.data?.filter(
                                  items => items.id == verticalActiveTab
                                )
                                if (userFilte.length > 0) {
                                  setTableData(userFilte[0])
                                  setAllData(response?.data?.data)
                                }
                              } else {
                                setTableData(userFilterData)
                                setAllData(filteruserPerData)
                              }
                            }
                            Swal.fire({
                              icon: "success",
                              text: "Credential deleted successfully.",
                            })
                          } catch (error) {
                            console.log(error)
                          }
                        }
                      } catch (error) {
                        setLoading(false)
                        console.log(error)
                      }
                    }
                  })
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          return (
            <div className="d-flex justify-content-center">
              <button
                onClick={() => {
                  getViewData(cellProps.row.original)
                }}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-eye"></i>
              </button>
              <button
                onClick={() => {
                  isShare()
                }}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fa fa-share-alt"></i>
              </button>

              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <button
                    type="button"
                    onClick={() => {
                      handleEdit(cellProps.row.original)
                    }}
                    // to={`${configure.appUrl}sales/edit-lead/${cellProps.cell?.row?.original.id}`}
                    title="edit lead"
                    className="dropdown-item"
                  >
                    <i className="fas fa-pencil-alt pr-10"></i> Edit Credentials
                  </button>

                  <a
                    onClick={() => {
                      deleteCred(cellProps.row.original.id)
                    }}
                    className="dropdown-item"
                    title="delete"
                  >
                    <i className="fas fa-trash-alt pr-10 text-danger"></i>{" "}
                    Delete Credentials
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [activeColumn]
  )

  const handleAddCred = async e => {
    e.preventDefault()
    setLoading(true)
    // role_id,user_id,all other fields
    const formDataa = new FormData()
    const roleId = localStorage.getItem("auth_role")
    const authId = localStorage.getItem("auth_id")
    formDataa.append("role_id", roleId)
    formDataa.append("user_id", authId)
    formDataa.append("tab_id", credId)
    Object.keys(formData).forEach(key => {
      formDataa.append(`${key}`, formData[key])
    })
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/credentials/add`,
        data: formDataa,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        const userFilterData = response?.data?.data?.find(itemss => {
          const userId = localStorage.getItem("auth_id")
          const users = itemss.role_id?.split(",")
          return (
            users?.some(user => userId.includes(user.trim())) &&
            itemss.id == verticalActiveTab
          )
        })
        const filteruserPerData = response?.data?.data?.filter(itemss => {
          const userId = localStorage.getItem("auth_id")
          const users = itemss.role_id?.split(",")
          return users?.some(user => userId?.includes(user.trim()))
        })
        try {
          setLoading(true)
          const formData = new FormData()
          formData.append("user_id", localStorage.getItem("auth_id"))
          formData.append("module_id", 61)
          const permissionResponse = await axios({
            method: "post",
            url: `${configure.apiUrl}/user_permission`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          setLoading(false)
          if (permissionResponse) {
            if (
              permissionResponse.data.data.india[1] == 1 ||
              permissionResponse.data.data.uk[1] == 1
            ) {
              let userFilte = response?.data?.data?.filter(
                items => items.id == verticalActiveTab
              )
              if (userFilte.length > 0) {
                setTableData(userFilte[0])
                setAllData(response?.data?.data)
              }
            } else {
              setTableData(userFilterData)
              setAllData(filteruserPerData)
            }
          }
          Swal.fire({
            icon: "success",
            text: "Credential added successfully.",
          }).then(() => {
            setAddCredentials(false)
            setFormData({})
          })
        } catch (error) {
          console.log(error)
        }
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const handleInputChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  const handleEdit = async data => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("module_id", 61)
      const permissionResponse = await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (permissionResponse) {
        setLoading(false)
        if (
          permissionResponse.data.data.india[4] == 1 ||
          permissionResponse.data.data.uk[4] == 1
        ) {
          setEditCredId(data.id)
          setEditCredentials(true)

          // Assuming tableData, credentials, and active_fields are properly defined
          // const fieldObjects =
          //   allFields.length !== 0 ? allActiveFields : []
          const fieldObjects = allFields.length !== 0 ? allFields : []
          const originalObject = tableData?.credentials?.find(
            crd => crd.id === data.id
          )

          if (originalObject) {
            const personalObjects = fieldObjects?.map(field => ({
              field_name: field.field_name,
              field_label: field.field_label,
              value: originalObject[field.field_name] || "",
              isrequired: field.show_field == 1 ? true : false,
            }))

            setEditFormFields(personalObjects)
            const formDataUpdated = {}
            personalObjects.forEach(field => {
              formDataUpdated[field.field_name] = field.value
            })
            setFormDataEdit(formDataUpdated)
          } else {
            console.error(`No credentials found for id ${data.id}`)
          }
        } else {
          setAccessDenied(true)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const memoizedEditFormFields = useMemo(() => editFormFields, [editFormFields])
  const memoizedViewFormFields = useMemo(() => viewFormFields, [viewFormFields])
  const handleInputChangeEdit = (fieldName, value) => {
    setFormDataEdit(prevFormData => ({
      ...prevFormData,
      [fieldName]: value,
    }))
  }
  const handleEditCred = async e => {
    e.preventDefault()
    setLoading(true)
    // role_id,user_id,all other fields
    const formDataa = new FormData()
    const roleId = localStorage.getItem("auth_role")
    const authId = localStorage.getItem("auth_id")
    formDataa.append("role_id", roleId)
    formDataa.append("user_id", authId)
    formDataa.append("tab_id", credId)
    formDataa.append("data_id", editCredId)

    Object.keys(formDataEdit).forEach(key => {
      formDataa.append(`${key}`, formDataEdit[key])
    })
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/credentials/edit`,
        data: formDataa,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        const userFilterData = response?.data?.data?.find(itemss => {
          const userId = localStorage.getItem("auth_id")
          const users = itemss.role_id?.split(",")
          return (
            users?.some(user => userId.includes(user.trim())) &&
            itemss.id == verticalActiveTab
          )
        })
        const filteruserPerData = response?.data?.data?.filter(itemss => {
          const userId = localStorage.getItem("auth_id")
          const users = itemss.role_id?.split(",")
          return users?.some(user => userId?.includes(user.trim()))
        })
        try {
          setLoading(true)
          const formData = new FormData()
          formData.append("user_id", localStorage.getItem("auth_id"))
          formData.append("module_id", 61)
          const permissionResponse = await axios({
            method: "post",
            url: `${configure.apiUrl}/user_permission`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          setLoading(false)
          if (permissionResponse) {
            if (
              permissionResponse.data.data.india[1] == 1 ||
              permissionResponse.data.data.uk[1] == 1
            ) {
              let userFilte = response?.data?.data?.filter(
                items => items.id == verticalActiveTab
              )
              if (userFilte.length > 0) {
                setTableData(userFilte[0])
                setAllData(response?.data?.data)
              }
            } else {
              setTableData(userFilterData)
              setAllData(filteruserPerData)
            }
          }
          Swal.fire({
            icon: "success",
            text: "Credential edited successfully.",
          }).then(() => {
            setEditCredentials(false)
            setFormData({})
          })
        } catch (error) {
          console.log(error)
        }
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const fetchAllStaff = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staff`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const optionUser = response?.data?.data?.map(item => ({
          value: item.id,
          label: item.name,
        }))
        setAllUser(optionUser)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAllStaff()
  }, [])

  const handleShare = async e => {
    e.preventDefault()
    const isPro = isAccessSh
      ? shareStaff.length !== 0 && selectedProject?.length !== 0
      : shareStaff.length !== 0
    if (isPro) {
      setLoading(true)
      try {
        const formData = new FormData()
        const roleId = localStorage.getItem("auth_role")
        const authId = localStorage.getItem("auth_id")
        formData.append("role_id", roleId)
        formData.append("user_id", authId)
        formData.append("tab_id", credId)
        formData.append("data_id", shareCredId)
        formData.append("exp_date", shareExpireDate ? shareExpireDate : 0)
        formData.append(
          "project_id",
          selectedProject?.value ? selectedProject?.value : 0
        )
        formData.append(
          "assign",
          shareStaff?.map(item => item.value)?.toString()
        )

        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/credentials/share`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response) {
          const userFilterData = response?.data?.data?.find(itemss => {
            const userId = localStorage.getItem("auth_id")
            const users = itemss.role_id?.split(",")
            return (
              users?.some(user => userId.includes(user.trim())) &&
              itemss.id == verticalActiveTab
            )
          })
          const filteruserPerData = response?.data?.data?.filter(itemss => {
            const userId = localStorage.getItem("auth_id")
            const users = itemss.role_id?.split(",")
            return users?.some(user => userId?.includes(user.trim()))
          })
          try {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 61)
            const permissionResponse = await axios({
              method: "post",
              url: `${configure.apiUrl}/user_permission`,
              data: formData,
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            setLoading(false)
            if (permissionResponse) {
              if (
                permissionResponse.data.data.india[1] == 1 ||
                permissionResponse.data.data.uk[1] == 1
              ) {
                let userFilte = response?.data?.data?.filter(
                  items => items.id == verticalActiveTab
                )
                if (userFilte.length > 0) {
                  setTableData(userFilte[0])
                  setAllData(response?.data?.data)
                }
              } else {
                setTableData(userFilterData)
                setAllData(filteruserPerData)
              }
            }
            const filterData = response?.data?.data?.find(
              item => item.id === credId
            )
            const filterCredData = filterData?.credentials?.find(
              obj => obj.id === shareCredId
            )
            const perStaffList = filterCredData?.assign.split(",")
            const showList = allUser?.filter(listItem =>
              perStaffList?.includes(listItem.value.toString())
            )
            const filterUser = allUser?.filter(item => {
              return !perStaffList.includes(item.value.toString())
            })
            const modiFySharedList = filterCredData?.sharedUsers
            setShowShareList2(modiFySharedList)
            setFilterShareUser(filterUser)
            setShowShareList(showList)
            setLoading(false)
            Swal.fire({
              icon: "success",
              text: "Credentials shared successfully.",
            }).then(() => {
              setShareCredentials(false)
              setShareStaff([])
              setSelectedProject([])
              setShareExpireDate("")
            })
          } catch (error) {
            console.log(error)
          }
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory field.",
      })
    }
  }

  const handlereject = async staffId => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 61)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
          Swal.fire({
            icon: "warning",
            text: "Are You Sure ?",
            showCancelButton: true,
            confirmButtonText: "Yes delete it!",
          }).then(async willOkay => {
            if (willOkay.isConfirmed) {
              setLoading(true)
              try {
                const formData = new FormData()
                formData.append("user_id", localStorage.getItem("auth_id"))
                formData.append("role_id", localStorage.getItem("auth_role"))
                formData.append("tab_id", credId)
                formData.append("data_id", shareCredId)
                formData.append("assign", staffId)
                const response = await axios({
                  method: "post",
                  url: `${configure.apiUrl}/credentials/remove`,
                  data: formData,
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                  },
                })
                if (response) {
                  const userFilterData = response?.data?.data?.find(itemss => {
                    const userId = localStorage.getItem("auth_id")
                    const users = itemss.role_id?.split(",")
                    return (
                      users?.some(user => userId.includes(user.trim())) &&
                      itemss.id == verticalActiveTab
                    )
                  })
                  const filteruserPerData = response?.data?.data?.filter(
                    itemss => {
                      const userId = localStorage.getItem("auth_id")
                      const users = itemss.role_id?.split(",")
                      return users?.some(user => userId?.includes(user.trim()))
                    }
                  )

                  try {
                    setLoading(true)
                    const formData = new FormData()
                    formData.append("user_id", localStorage.getItem("auth_id"))
                    formData.append("module_id", 61)
                    const permissionResponse = await axios({
                      method: "post",
                      url: `${configure.apiUrl}/user_permission`,
                      data: formData,
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                      },
                    })
                    setLoading(false)
                    if (permissionResponse) {
                      if (
                        permissionResponse.data.data.india[1] == 1 ||
                        permissionResponse.data.data.uk[1] == 1
                      ) {
                        let userFilte = response?.data?.data?.filter(
                          items => items.id == verticalActiveTab
                        )
                        if (userFilte.length > 0) {
                          setTableData(userFilte[0])
                          setAllData(response?.data?.data)
                        }
                      } else {
                        setTableData(userFilterData)
                        setAllData(filteruserPerData)
                      }
                    }
                    const findAssign = response?.data?.data?.find(itms => {
                      return itms.id == credId
                    })
                    const findTabCred = findAssign?.credentials?.find(obj => {
                      return obj.id == shareCredId
                    })
                    const perStaffList = findTabCred?.assign.split(",")
                    const showList = allUser?.filter(listItem =>
                      perStaffList?.includes(listItem.value.toString())
                    )
                    const filterUser = allUser?.filter(item => {
                      return !perStaffList.includes(item.value.toString())
                    })
                    const modiFySharedList = findTabCred?.sharedUsers
                    setShowShareList2(modiFySharedList)
                    setFilterShareUser(filterUser)
                    setShowShareList(showList)
                    Swal.fire({
                      icon: "success",
                      text: "Credentials deletd successfully.",
                    }).then(() => {})
                  } catch (error) {
                    console.log(error)
                  }
                }
              } catch (error) {
                setLoading(false)
                console.log(error)
              }
            }
          })
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const gotoPage = pageIndex => {
    setCurrentPage(pageIndex)
  }
  console.log("copy", copy)
  return (
    <div>
      <Row>
        <Col lg={12} className="p-0">
          <div className="responsive-table">
            <TableContainer
              columns={columns}
              dataall={data}
              data={data}
              setData={setData}
              copy={copy}
              setAccessDenied={setAccessDenied}
              modal_Access={modal_Access}
              setAddCredentials={setAddCredentials}
              setLoading={setLoading}
              verticalActiveTab={verticalActiveTab}
              credId={credId}
              gotoPage={gotoPage}
            />
          </div>
        </Col>

        {/* Add Credentials model */}
        {addCredentials && (
          <Draggable>
            <ResizableBox
              width={1000}
              style={{
                position: "fixed",
                top: "35%",
                right: "5%",
                zIndex: "1055",
                backgroundColor: "#fff",
              }}
              id="movable-popup"
            >
              <div>
                <div className="popup-header p-3 pt-4 border-bottom border-1">
                  <h5 className="popup-title mt-0">Add Credentials</h5>
                  <button
                    type="button"
                    onClick={() => {
                      setAddCredentials(false)
                    }}
                    className="btn-modal-close"
                  >
                    <span aria-hidden="true" className="btn-modal-span">
                      &times;
                    </span>
                  </button>
                </div>
                <div className="popup-content">
                  <form className="row" onSubmit={e => handleAddCred(e)}>
                    {(allFields.length !== 0 ? allFields : [])?.map(
                      (fields, index) => {
                        return (
                          <div className="mb-4 col-md-4 py-2" key={fields.id}>
                            <Label
                              htmlFor={`formrow-${fields.field_name}-Input`}
                            >
                              {fields.field_label}{" "}
                              {/* <span className="text-danger">
                          {fields.show_field == 1 ? "*" : ""}
                        </span> */}
                            </Label>
                            {copy.type === index && (
                              <div className="position-absolute copyMsg2">
                                Copied
                              </div>
                            )}
                            <div className="d-flex justify-content-start align-items-center gap-2">
                              <Input
                                // required={fields.show_field == 1 ? true : false}
                                type="text"
                                className="form-control"
                                id={`formrow-${fields.field_name}-Input`}
                                placeholder={`Enter ${fields.field_label}`}
                                value={formData[fields.field_name] || ""}
                                onChange={e =>
                                  handleInputChange(
                                    fields.field_name,
                                    e.target.value
                                  )
                                }
                                style={{ width: "80%" }}
                              />
                            </div>
                          </div>
                        )
                      }
                    )}
                    {allFields.length === 0 && (
                      <tr className="d-flex justify-content-center">
                        <td colSpan={8} className="text-center">
                          No Fields Found
                        </td>
                      </tr>
                    )}
                    {allFields.length !== 0 && (
                      <div className="mt-20 text-end">
                        <button className=" btn btn-save btn-label">
                          <i className="fas fa-save me-2 label-icon"></i>
                          Save
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </ResizableBox>
          </Draggable>
        )}

        <Modal
          // isOpen={addCredentials}
          size="xl"
          className=""
          style={{ maxWidth: "80vw", width: "100%" }}
          centered
        >
          <div className="modal-header pe-4">
            <h5 className="modal-title mt-0">Add Credentials</h5>
            <button
              type="button"
              onClick={() => {
                setAddCredentials(false)
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>

          <div
            className="modal-body pe-4"
            style={{ paddingTop: "40px", paddingBottom: "40px" }}
          >
            <form className="row" onSubmit={e => handleAddCred(e)}>
              {(allFields.length !== 0 ? allFields : [])?.map(
                (fields, index) => {
                  return (
                    <div className="mb-4 col-md-4 py-2" key={fields.id}>
                      <Label htmlFor={`formrow-${fields.field_name}-Input`}>
                        {fields.field_label}{" "}
                        {/* <span className="text-danger">
                          {fields.show_field == 1 ? "*" : ""}
                        </span> */}
                      </Label>
                      {copy.type === index && (
                        <div className="position-absolute copyMsg2">Copied</div>
                      )}
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          // required={fields.show_field == 1 ? true : false}
                          type="text"
                          className="form-control"
                          id={`formrow-${fields.field_name}-Input`}
                          placeholder={`Enter ${fields.field_name}`}
                          value={formData[fields.field_name] || ""}
                          onChange={e =>
                            handleInputChange(fields.field_name, e.target.value)
                          }
                          style={{ width: "80%" }}
                        />
                      </div>
                    </div>
                  )
                }
              )}
              {allActiveFields.length === 0 && (
                <tr className="d-flex justify-content-center">
                  <td colSpan={8} className="text-center">
                    No Fields Found
                  </td>
                </tr>
              )}
              {allActiveFields.length !== 0 && (
                <div className="mt-20 text-end">
                  <button className=" btn btn-save btn-label">
                    <i className="fas fa-save me-2 label-icon"></i>
                    Save
                  </button>
                </div>
              )}
            </form>
          </div>
        </Modal>
        {/* Edit Credentials model */}
        <Modal
          isOpen={editCredentials}
          size="xl"
          className=""
          style={{ maxWidth: "80vw", width: "100%" }}
          centered
        >
          <div className="modal-header pe-4">
            <h5 className="modal-title mt-0">Edit Credentials</h5>
            <button
              type="button"
              onClick={() => {
                setEditCredentials(false)
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>

          <div
            className="modal-body pe-4"
            style={{ paddingTop: "40px", paddingBottom: "40px" }}
          >
            <form className="row" onSubmit={e => handleEditCred(e)}>
              {memoizedEditFormFields.map((fields, index) => (
                <div className="mb-4 col-md-4 py-2" key={index + 1}>
                  {/* Assuming fields.field_name and fields.field_label are available */}
                  <Label htmlFor={`formrow-${fields.field_name}-Input`}>
                    {fields.field_label}
                    {/* <span className="text-danger">
                      {" "}
                      {fields.isrequired ? "*" : ""}
                    </span> */}
                  </Label>
                  {copy.type === index && (
                    <div className="position-absolute copyMsg2">Copied</div>
                  )}
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      type="text"
                      className="form-control"
                      id={`formrow-${fields.field_name}-Input`}
                      placeholder={`Enter ${fields.field_label}`}
                      defaultValue={fields.value}
                      onChange={e =>
                        handleInputChangeEdit(fields.field_name, e.target.value)
                      }
                      style={{ width: "80%" }}
                      // required={fields.isrequired == true ? true : false}
                    />
                    {fields.value && (
                      <span>
                        <i
                          className={`bi ${
                            copy.type === index
                              ? "bi-clipboard-check"
                              : "bi-clipboard label-icon"
                          } label-icon fa-lg`}
                          style={{ cursor: "pointer", color: "#a8a6a6" }}
                          onClick={e =>
                            copyToClipBoard({
                              text: `${fields.value}`,
                              setCopy: setCopy,
                              id: index,
                              title: "Click to Copy",
                            })
                          }
                          title="Click to Copy"
                        ></i>
                      </span>
                    )}
                  </div>
                </div>
              ))}
              {memoizedEditFormFields.length == 0 && (
                <tr className="d-flex justify-content-center">
                  <td colSpan={8} className="text-center">
                    No Fields Found
                  </td>
                </tr>
              )}
              {memoizedEditFormFields.length > 0 && (
                <div className="mt-20 text-end">
                  <button className=" btn btn-save btn-label">
                    <i className="fas fa-save me-2 label-icon"></i>
                    Save
                  </button>
                </div>
              )}
            </form>
          </div>
        </Modal>

        {/* Share Credentials model */}
        <Modal
          isOpen={shareCredentials}
          size="xl"
          className=""
          style={{ maxWidth: "80vw", width: "100%" }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Share Credentials</h5>
            <button
              type="button"
              onClick={() => {
                setShareCredentials(false)
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div
            className="modal-body"
            style={{ paddingTop: "40px", paddingBottom: "40px" }}
          >
            <form onSubmit={handleShare}>
              <div className="d-flex justify-space-between">
                {/* Form  */}
                <Col className="col-md-6">
                  <div>
                    <div className="">
                      <div className="mb-3 d-flex justify-space-between flex-column gap-4">
                        <div className="row d-flex align-items-end">
                          <div className="col-md-12">
                            <Label htmlFor="formrow-firstname-Input">
                              Select Staff{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <ReactSelect
                              placeholder="Select Staff"
                              className="basic-multi-select "
                              classNamePrefix="select"
                              styles={colourStyles}
                              options={filterShareUser}
                              // options={allUser}
                              isMulti="true"
                              value={shareStaff}
                              onChange={selectedOption =>
                                setShareStaff(selectedOption)
                              }
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          {isAccessSh && (
                            <div className="col-md-8">
                              <Label htmlFor="formrow-firstname-Input">
                                Assign Project{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <ReactSelect
                                placeholder="Assign Project"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                styles={colourStyles}
                                options={projectOption}
                                // isMulti="true"
                                value={selectedProject}
                                onChange={selecteOp => {
                                  setSelectedProject(selecteOp)
                                }}
                              />
                            </div>
                          )}
                          <div className="col-md-4">
                            <Label htmlFor="formrow-firstname-Input">
                              {/* Access Expiration date {"(optional)"}{" "} */}
                              Access Expiration date{" "}
                              {/* <span className="text-danger">*</span> */}
                            </Label>{" "}
                            <Input
                              value={shareExpireDate}
                              onChange={e => setShareExpireDate(e.target.value)}
                              type="date"
                              className="form-control"
                              id="formrow-firstname-Input"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                {/* tabel */}
                <Col className="col-md-5">
                  <div className="position-relative mt-4 mb-3 overflowy-scroll">
                    <table className="table table-bordered ">
                      <thead>
                        <tr>
                          <th className="text-center">ID</th>
                          <th className="">Staff Name</th>
                          <th className="text-center">Expiration date</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {/* {(showShareList || []).map(staffItem => {
                          return (
                            <tr key={staffItem.value}>
                              <td
                                className="text-center"
                                style={{ width: "10%" }}
                              >
                                {staffItem.value}
                              </td>
                              <td className="" style={{ width: "50%" }}>
                                {staffItem.label}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "10%" }}
                              >
                                <button
                                  type="button"
                                  onClick={() => handlereject(staffItem.value)}
                                  className="btn btn-sm  btn-outline-danger"
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                        {showShareList.length == 0 && (
                          <tr>
                            <td colSpan={8} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )} */}
                        {showShareList2?.map(list => {
                          return (
                            <tr key={list.id}>
                              <td
                                className="text-center"
                                style={{ width: "10%" }}
                              >
                                {list.user_id}
                              </td>
                              <td className="" style={{ width: "50%" }}>
                                {list.name}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "25%" }}
                              >
                                {(list.exp_date != "0" && list.exp_date) ? (
                                  moment(list.exp_date).format("DD-MM-YYYY")
                                ) : (
                                  <i className="bi bi-infinity fs-5"></i>
                                )}
                              </td>
                              <td
                                className="text-center"
                                style={{ width: "15%" }}
                              >
                                <button
                                  type="button"
                                  onClick={() => handlereject(list.user_id)}
                                  className="btn btn-sm  btn-outline-danger"
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                        {showShareList2.length == 0 ? (
                          <tr className="text-center">
                            <td colSpan={10}>No data found.</td>
                          </tr>
                        ) : ""}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </div>

              {/* Save Button  */}
              <Col className="col-md-12 text-end my-3">
                <div>
                  <button className=" btn btn-save btn-label">
                    <i className="fas fa-save me-2 label-icon"></i>
                    Save
                  </button>
                </div>
              </Col>
            </form>
          </div>
        </Modal>

        {/* view model */}
        <Modal
          isOpen={viewCredentials}
          size="xl"
          className=""
          style={{ maxWidth: "80vw", width: "100%" }}
          centered
        >
          <div className="modal-header pe-4">
            <h5 className="modal-title mt-0">View Credentials</h5>
            <button
              type="button"
              onClick={() => {
                setViewCredentials(false)
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>

          <div
            className="modal-body"
            style={{ paddingTop: "40px", paddingBottom: "40px" }}
          >
            <div className="row">
              {memoizedViewFormFields.map((fields, index) => (
                <div className="mb-4 col-md-4 py-2" key={index + 1}>
                  {/* Assuming fields.field_name and fields.field_label are available */}
                  <Label htmlFor={`formrow-${fields.field_name}-Input`}>
                    {fields.field_label}
                    {/* <span className="text-danger">
                      {" "}
                      {fields.isrequired ? "*" : ""}
                    </span> */}
                  </Label>
                  {copy.type === index && (
                    <div className="position-absolute copyMsg2">Copied</div>
                  )}
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      type="text"
                      className="form-control"
                      id={`formrow-${fields.field_name}-Input`}
                      placeholder={`Enter ${fields.field_name}`}
                      value={fields.value}
                      disabled
                      // required={fields.isrequired == true ? true : false}
                      // onChange={e =>
                      //   handleInputChangeEdit(fields.field_name, e.target.value)
                      // }
                      style={{ width: "80%" }}
                    />
                    {fields.value && (
                      <span>
                        <i
                          className={`bi ${
                            copy.type === index
                              ? "bi-clipboard-check"
                              : "bi-clipboard label-icon"
                          } label-icon fa-lg`}
                          style={{ cursor: "pointer", color: "#a8a6a6" }}
                          onClick={e =>
                            copyToClipBoard({
                              text: `${fields.value}`,
                              setCopy: setCopy,
                              id: index,
                              title: "Click to Copy",
                            })
                          }
                          title="Click to Copy"
                        ></i>
                      </span>
                    )}
                  </div>
                </div>
              ))}
              {memoizedViewFormFields.length == 0 && (
                <tr className="d-flex justify-content-center">
                  <td colSpan={8} className="text-center">
                    No Fields Found
                  </td>
                </tr>
              )}
            </div>
          </div>
        </Modal>

        {/* Access Denied */}

        <AccessDeniedPopup
          modal_Access={modal_Access}
          setAccessDenied={setAccessDenied}
        />
      </Row>
    </div>
  )
}

export default HostingPanelLive
